import React, { useState, useContext } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { context } from '../../../context'

const Ava = `${process.env.PUBLIC_URL}/assets/ava2.png`;

const TopbarProfile = () => {
  const { state, actions } = useContext(context)
  const [collapse, setCollapse] = useState(false)
  const toggle = () => {
    setCollapse(!collapse)
  };

  return (
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={toggle}>
        <img className="topbar__avatar-img" src={Ava} alt="avatar" />
        {<p className="topbar__avatar-name">{state.student.name + " " + state.student.lastName}</p>}
        <DownIcon className="topbar__icon" />
      </button>
      {collapse && <button type="button" className="topbar__back" onClick={toggle} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink title="Mi Perfíl" icon="user" path={`edit-profile`} />
          <div className="topbar__link" onClick={() => actions.closeSession()}>
            <span className={`topbar__link-icon lnr lnr-${"exit"}`} />
            <p className="topbar__link-title">{"Cerrar Sesión"}</p>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default TopbarProfile
