import React, { useState } from 'react';
import { Container, Col } from 'reactstrap';
import QuizFace from './QuizFace';
// import QuizResults from './quizResultsMobile/index';
import QuizResults from './quizResults';

function Quiz({ setShowQuiz }) {
  const [showResultsQuiz, setShowResultsQuiz] = useState(false);

  const showAnsweredQuiz = () => {
    setShowResultsQuiz(true)
  }

  return (
    <Container>
      <Col>
        {(showResultsQuiz) ?
          <QuizResults onClickBack={() => setShowResultsQuiz(false)} />
          :
          <QuizFace
            setShowQuiz={setShowQuiz}
            showAnsweredQuiz={showAnsweredQuiz} />
        }
      </Col>
    </Container>
  )

}
export default Quiz