import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Topbar from './topbar';
import Sidebar from './sidebar';

const initState = {
    sidebarShow: false,
    sidebarCollapse: false,
    sidebarcomplementShow: false,
    sidebarcomplementCollapse: true,
}

const Layout = (props) => {

    const [state, setState] = useState(initState)

    useEffect(() => {
    }, [])

    const goBack=()=>{
        props.history.goBack()
    }

    const changeMobileSidebarVisibility = () => {
        setState({
            ...state,
            sidebarShow: !state.sidebarShow
        })
    };
    return (
        <div className={state.sidebarCollapse?"layout--collapse":""}>

            <Topbar
                changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                sidebarCollapse={state.sidebarCollapse}
                sidebarCollapseComplement={state.sidebarcomplementCollapse}
            />
            <Sidebar
                goBack={goBack}
            />
        </div>
    )
}

export default withRouter(Layout);
