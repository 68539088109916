import fetcher from '../utils/fetcher';

const actions = (state, dispatch) => ({
  /**
   * action: login
   * description: login with email and password
   */
  login: ({ email = '', password = '', keepSession }) => {
    return fetcher('/api/st/login', {
      body: JSON.stringify({ email, password }),
      method: 'POST'
    }).then((response) => {
      dispatch({ type: 'LOGIN', payload: { ...response, keepSession } });
      return response;
    });
  },
  /**
   * action: loginFacebook
   * description: Authenticate with facebook login
   */
  loginFacebook: ({ accessToken, userID, email }) => {
    return fetch(`https://graph.facebook.com/${userID}/picture?type=large`).then(
      (fbImageResponse) => {
        return fetcher('/api/st/login/fb', {
          method: 'POST',
          body: JSON.stringify({
            fbAccessToken: accessToken,
            fbId: userID,
            email
          })
        }).then((response) => {
          dispatch({
            type: 'FACEBOOK_LOGIN',
            payload: {
              ...response,
              success: true,
              picture: fbImageResponse.url || ''
            }
          });
        });
      }
    );
  },
  /**
   * action: getCourses
   * description: Get all courses
   */
  updatePassword: ({ email, password, newPassword }) => {
    return fetcher('/api/st/login', {
      method: 'POST',
      body: JSON.stringify({ password, email })
    }).then((loginResponse) => {
      return (
        loginResponse.success &&
        fetcher(`/api/students/${loginResponse.student._id}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${loginResponse.token}`
          },
          body: JSON.stringify({ password: newPassword })
        }).then((studentResponse) => {
          dispatch({
            type: 'UPDATE_PASSWORD',
            payload: {
              success: true,
              loginData: loginResponse,
              studentData: studentResponse
            }
          });
          return studentResponse.success && loginResponse.success;
        })
      );
    });
  },
  /**
   * action: closeSession
   * description: close session
   */
  closeSession: () => {
    dispatch({ type: 'CLOSE_SESSION', payload: { success: true } });
  },

  registerFreeLesson: (data) => {
    return fetcher('/api/unpr/freeLesson/student', {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.success) {
        const email = data.email;
        const password = data.password;
        return fetcher('/api/st/login', {
          body: JSON.stringify({ email, password }),
          method: 'POST'
        }).then((responseLogin) => {
          dispatch({ type: 'LOGIN', payload: { ...responseLogin } });
          return responseLogin;
        });
      } else {
        return response;
      }
    });
  },

  registerFreeExam: (data) => {
    return fetcher('/api/unpr/freeTest/student', {
      method: 'POST',
      body: JSON.stringify(data)
    }).then((response) => {
      if (response.success) {
        const email = data.email;
        const password = data.password;
        return fetcher('/api/st/login', {
          body: JSON.stringify({ email, password }),
          method: 'POST'
        }).then((responseLogin) => {
          dispatch({ type: 'LOGIN', payload: { ...responseLogin } });
          return responseLogin;
        });
      } else {
        return response;
      }
    });
  },
  verifyInternalStudent: () => {
    return new Promise((resolve, reject) => {
      resolve({
        success: true
      });
    });
  },

  /**
   * action: getCourses
   * description: Get all courses
   */
  getCourses: () => {
    return fetcher(`/api/st/course`).then((payload) => {
      dispatch({ type: 'COURSES', payload });
      if (payload.success && payload.courses.length) {
        fetcher(`/api/st/courses/${payload.courses[0]._id}`).then((payload) => {
          dispatch({ type: 'COURSE', payload });
        });
      }
      return payload;
    });
  },
  /**
   * action: getCourse
   * description: get course by id
   */
  setCourse: (id) => {
    let loading = true;
    dispatch({ type: 'LOADING', payload: { success: true, loading } });
    const course = state.courses.find((course) => course._id === id);
    if (!course.playlists.length) {
      return fetcher(`/api/st/courses/${id}`).then((payload) => {
        dispatch({ type: 'COURSE', payload });
      });
    }
    return dispatch({ type: 'COURSE', payload: { success: true, course } });
  },
  /**
   * action: getStudent
   * description: Get all detail for student by id
   */
  getStudent: () => {
    return fetcher(`/api/st`).then((payload) => {
      // return fetcher(`/api/st/${localStorage['student']}`).then((payload) => {
      dispatch({ type: 'STUDENT', payload });
      return payload;
    });
  },
  /**
   * action: getSchoolData
   * description: Get school data by student
   */
  getSchoolData: () => {
    return fetcher(`/api/st/schoolData`).then((payload) => {
      dispatch({ type: 'SCHOOL_DATA', payload });
      return payload;
    });
  },
  /**
   * action: getAttendance
   * description: Get all attendance by student
   */
  getAttendance: () => {
    fetcher(`/api/st/${localStorage['student']}/attendance`).then((payload) =>
      dispatch({ type: 'ATTENDANCE', payload })
    );
  },
  /**
   * action: getLevels
   * description: Get all levels
   */
  getLevels: () => {
    fetcher(`/api/st/course/getCourseLevels`).then((payload) => {
      dispatch({ type: 'LEVELS', payload });
    });
  },
  /**
   * action: getCourseByTypeId
   * description: Get all levels
   */
  getCourseByTypeId: (typeId) => {
    fetcher(`/api/course/types/${typeId}/courses`).then((payload) => {
      dispatch({ type: 'WORKSHOPS', payload });
    });
  },
  /**
   * action: getCourseByLevel
   * description: Get course by level
   */
  getCoursesByLevelId: (levelId) => {
    fetcher(`/api/st/course/levels/${levelId}`).then((payload) => {
      dispatch({
        type: 'AVAILABLE_COURSES',
        payload: { success: true, courses: payload.courses || [] }
      });
    });
  },
  /**
   * action: getGroupsByCourseId
   * description: Get all courses
   */
  getGroupsByCourseId: (courseId) => {
    fetcher(`/api/st/course/${courseId}`)
      .then((courseResponse) => {
        dispatch({
          type: 'AVAILABLE_GROUPS',
          payload: {
            selectedCourse: courseResponse.course,
            availableGroups: courseResponse.groups,
            success: courseResponse.success
          }
        });
      })
      .catch(() => {
        dispatch({
          type: 'AVAILABLE_GROUPS',
          payload: {
            success: true,
            groups: [],
            selectedCourse: {}
          }
        });
      });
  },
  /**
   * action: getVideoById
   * description: Get video id
   */
  getVideoById: (id = '') => {
    if (!id.length) return false;
    return fetcher(`/api/st/videos/${id}`).then((payload) => dispatch({ type: 'VIDEO', payload }));
  },
  /**
   * action: getQuizById
   * description: Get quiz by id
   */
  getQuizById: (id = '') => {
    if (!id.length) return false;
    return fetcher(`/api/quiz/${id}`).then((payload) => {
      dispatch({ type: 'QUIZ', payload });
      return payload;
    });
  },
  /**
   * action: getPlaylistByCourse
   * description: Get all courses
   */
  getPlaylistById: (id) => {
    return fetcher(`/api/st/playlists/${id}`).then((payload) => {
      console.log('PAYLOAD', payload);
      dispatch({ type: 'PLAYLIST', payload });
      return payload;
    });
  },
  getPlaylistByCourseId: (id) => {
    return fetcher(`/api/st/course/${id}/playlists`).then((payload) => {
      dispatch({ type: 'PLAYLIST', payload });
      return payload;
    });
  },
  changeSideBar: (value) => {
    dispatch({
      type: 'CHANGE_SIDE_BAR',
      payload: { success: true, value: value }
    });
  },
  /**
   * action: getSidebarData
   * description: request necessary data for sidebar component
   */
  getSidebarData: () => {
    const path = `/api/st`;
    // const path = `/api/st/${localStorage['student']}`;
    const fetchStudent = fetcher(path);
    const fetchSchoolData = fetcher(`${path}/schoolData`);

    Promise.all([fetchStudent, fetchSchoolData]).then((response) => {
      const [student, schoolData] = response;
      dispatch({
        type: 'SIDE_BAR',
        payload: {
          success: student.success && schoolData.success,
          data: { student, schoolData }
        }
      });
    });
  },
  /**
   * action: getSidebarData
   * description: request necessary data for sidebar component
   */
  getFinancialData: () => {
    fetcher(`/api/st/payments/`).then((payload) =>
      // fetcher(`/api/st/${localStorage['student']}/financial`).then((payload) =>
      dispatch({ type: 'FINANCIAL', payload })
    );
  },
  /**
   * action: editProfile
   * description: service to edit student profile
   */
  editProfile: (profileData) => {
    const { name, lastName, secondLastName, email, phoneNumber, birthDate, address, postalCode } =
      profileData;
    return fetcher(`/api/st/`, {
      method: 'PUT',
      body: JSON.stringify({
        name,
        lastName,
        secondLastName,
        email,
        phoneNumber,
        birthDate,
        address,
        postalCode
      })
    }).then((payload) => {
      dispatch({ type: 'EDIT_PROFILE', payload });
      return payload;
    });
  },
  /**
   * action: rate
   * description: service to set videos rate
   */
  setVideoRate: (idVideo, rate) => {
    fetcher(`/api/st/videos/${idVideo}/rate`, {
      method: 'POST',
      body: JSON.stringify({
        student: localStorage['student'],
        rate: rate
      })
    }).then((payload) => {
      fetcher(`/api/st/videos/${idVideo}`).then((payload) => dispatch({ type: 'VIDEO', payload }));
      //dispatch({ type: 'EDIT_PROFILE', payload });
      //return payload;
    });
  },
  /**
   * action: watch
   * description: service to set video as watched
   */
  setWatchVideo: (idVideo, rate) => {
    fetcher(`/api/st/videos/${idVideo}/watchVideo`, {
      method: 'POST',
      body: JSON.stringify({
        student: localStorage['student'],
        rate: rate
      })
    }).then((payload) => {
      if (payload.success && (payload.video !== null) & (payload.video !== undefined)) {
        dispatch({
          type: 'UPDATE_WATCH',
          payload: { success: payload.success, videoWatched: payload.video }
        });
      }
    });
  },
  /**
   * action: create comment video
   * description: service to comment a video
   */
  setVideoComment: (idVideo, comment, dateComment) => {
    fetcher(`/api/st/videos/${idVideo}/comment`, {
      method: 'POST',
      body: JSON.stringify({
        student: localStorage['student'],
        comment: comment,
        date: dateComment
      })
    }).then((payload) => {
      fetcher(`/api/st/videos/${idVideo}`).then((payload) => dispatch({ type: 'VIDEO', payload }));
    });
  },
  editVideoComment: (idVideo, comment, commentId) => {
    fetcher(`/api/st/videos/${idVideo}/comment`, {
      method: 'PUT',
      body: JSON.stringify({
        student: localStorage['student'],
        comment: comment,
        commentId: commentId
      })
    }).then((payload) => {
      fetcher(`/api/st/videos/${idVideo}`).then((payload) => dispatch({ type: 'VIDEO', payload }));
    });
  },

  deleteVideoComment: (idVideo, idComment) => {
    fetcher(`/api/st/videos/${idVideo}/comment/${idComment}`, {
      method: 'DELETE'
    }).then((payload) => {
      fetcher(`/api/st/videos/${idVideo}`).then((payload) => dispatch({ type: 'VIDEO', payload }));
    });
  },

  /**
   * action: reply a comment video
   * description: service to reply a video comment
   */
  setReplyVideoComment: (idVideo, comment, dateComment, idComment) => {
    fetcher(`/api/st/videos/comment/${idComment}/respond`, {
      method: 'POST',
      body: JSON.stringify({
        student: localStorage['student'],
        comment: comment,
        date: dateComment
      })
    }).then((payload) => {
      fetcher(`/api/st/videos/${idVideo}`).then((payload) => dispatch({ type: 'VIDEO', payload }));
    });
  },

  /**
   * action: enroll to a group by id
   * description: service to enroll a student into a group
   */
  enrollToGroup: (idGroup, body) => {
    return fetcher(`/api/st/enroll/${idGroup}`, {
      method: 'POST'
      // body: JSON.stringify({
      //   student: localStorage['student'],
      //   comment: comment,
      //   date: dateComment,
      // })
    }).then((payload) => {
      return payload;
    });
  },
  /**
   * action: set the info of the selected quiz
   */
  getQuizInfoById: (id) => {
    return fetcher(`/api/st/quiz/${id}`).then((payload) => {
      dispatch({ type: 'QUIZ', payload });
    });
  },

  setQuizInfo: (data) => {
    dispatch({ type: 'QUIZ', payload: { success: true, quiz: data } });
  },
  /**
   * action: set the info of the selected quiz
   */
  getStudentQuizAttemps: (id) => {
    //nos traemos todos los examenes del usuario
    return fetcher(`/api/st/quizAttempt/quiz/${id}`).then((payload) => {
      if (payload.success) {
        //guardamos todos los examenes
        dispatch({ type: 'QUIZATTEMPTS', payload });
        //validamos si tiene o no algun examen
        if (payload.quizAttempts.length > 0) {
          //validamos si existe algun examen en curso
          if (payload.quizAttempts[0].status === 'OPEN') {
            fetcher(`/api/st/quizAttempt/${payload.quizAttempts[0]._id}`).then((payload) => {
              dispatch({ type: 'LASTQUIZACTIVE', payload });
            });
          } else {
            //si no hay un examen activo
            dispatch({ type: 'LASTQUIZACTIVE', payload: { success: true, quizAttempt: null } });
          }
        } else {
          dispatch({ type: 'QUIZATTEMPTS', payload });
          // fetcher(`/api/st/quizAttempt/${id}`, { method: 'POST', }).
          //   then((payload) => {
          //     //dispatch({ type: 'VIDEO', payload })
          //   });
        }
      }
    });
  },

  getStudentQuizAttempById: (id) => {
    return fetcher(`/api/st/quizAttempt/${id}`).then((payload) => {
      dispatch({ type: 'LASTQUIZACTIVE', payload });
    });
  },
  getStudentQuizAttempByIdFreExamn: (id) => {
    return fetcher(`/api/unpr/freeTest/getFreeExam/${id}`).then((payload) => {
      dispatch({ type: 'LASTQUIZACTIVE', payload });
    });
  },
  /**
   * action: set the info of the selected quiz
   */
  saveAnswers: (idQuizQuestion, questionAnswers) => {
    return fetcher(`/api/st/quizAttempt/${state.latsQuizActive._id}/answer`, {
      method: 'POST',
      body: JSON.stringify({
        idQuizQuestion: idQuizQuestion,
        questionAnswers: questionAnswers
      })
    });
  },
  createAttemptQuiz: () => {
    return fetcher(`/api/st/quizAttempt/${state.quiz._id}`, { method: 'POST' }).then((payload) => {
      dispatch({ type: 'LASTQUIZACTIVE', payload });
      return payload;
    });
  },

  finishQuiz: () => {
    return fetcher(`/api/st/quizAttempt/${state.latsQuizActive._id}/finish`, {
      method: 'PUT'
    }).then((payload) => {
      dispatch({ type: 'LASTQUIZACTIVE', payload });
      return payload;
    });
  },

  getAnsweredExam: (idAttempt) => {
    return fetcher(`/api/st/quizAttempt/${idAttempt}`).then((payload) => {
      payload.quizAttempt.questionCollection.forEach((data, index) => {
        for (let a = 0; a < payload.quizAttempt.answerCollection.length; a++) {
          if (data._id === payload.quizAttempt.answerCollection[a].question) {
            payload.quizAttempt.questionCollection[index].arrayUserAnswers =
              payload.quizAttempt.answerCollection[a].answers;
          }
        }
      });
      dispatch({ type: 'ANSWERED_EXAM', payload });
      return payload;
    });
  },
  setFreeExamData: (quizAttempt) => {
    quizAttempt.questionCollection.forEach((data, index) => {
      for (let a = 0; a < quizAttempt.answerCollection.length; a++) {
        if (data._id === quizAttempt.answerCollection[a].question) {
          quizAttempt.questionCollection[index].arrayUserAnswers =
            quizAttempt.answerCollection[a].answers;
        }
      }
    });
    dispatch({ type: 'ANSWERED_EXAM', payload: { success: true, quizAttempt } });
    return quizAttempt;
  },
  getBookingCalendar: (idGroup) => {
    return fetcher(`/api/st/appoiments/${idGroup}`);
  },
  bookingAppoiment: (idGroup, date, hour, module) => {
    return fetcher(`/api/st/appoiments/${idGroup}`, {
      body: JSON.stringify({ date, hour, module }),
      method: 'POST'
    }).then((result) => {
      fetcher(`/api/st/schoolData`).then((payload) => dispatch({ type: 'SCHOOL_DATA', payload }));
      return result;
    });
  },

  paymentLesson: (card) => {
    return fetcher(`/api/st/payments/payLesson/`, {
      method: 'POST',
      body: JSON.stringify(card)
    }).then((payload) => {
      if (payload.success) {
        dispatch({ type: 'PAY_LESSON', payload });
      }
      return payload;
    });
  },

  paymentCard: (card) => {
    return fetcher('/api/st/payments', {
      method: 'POST',
      body: JSON.stringify(card)
    }).then((resp) => resp);
  },

  paymentExistCard: (card) => {
    return fetcher('/api/st/payments/pay', {
      method: 'POST',
      body: JSON.stringify(card)
    }).then((resp) => resp);
  },

  enrollPayment: (card, idGroup) => {
    return fetcher('/api/st/enrollPayment/' + idGroup, {
      method: 'POST',
      body: JSON.stringify(card)
    }).then((resp) => resp);
  },

  setQuizActiveQuestion: (value) => {
    dispatch({
      type: 'ACTIVE_QUESTION',
      payload: { success: true, quizAttemptActualQuestion: value }
    });
  },
  resetAnsweredExam: () => {
    dispatch({ type: 'ANSWERED_EXAM', payload: { success: true, quizAttempt: null } });
  },
  resetAllQuizParameters: () => {
    dispatch({ type: 'QUIZ', payload: { success: true, quiz: null } });
    dispatch({ type: 'ANSWERED_EXAM', payload: { success: true, quizAttempt: null } });
    dispatch({ type: 'LASTQUIZACTIVE', payload: { success: true, quizAttempt: null } });
    dispatch({ type: 'QUIZATTEMPTS', payload: { success: true, quizAttempts: null } });
    dispatch({ type: 'ACTIVE_QUESTION', payload: { success: true, quizAttemptActualQuestion: 0 } });
  },
  resetVideo: () => {
    dispatch({ type: 'VIDEO', payload: { success: true, video: null } });
  },
  resetPlayList: () => {
    dispatch({ type: 'PLAYLIST', payload: { success: true, playlist: null } });
  },
  getCourseInfo: (id) => {
    return fetcher(`/api/st/payments/debtByCourse/${id}`).then((payload) => {
      // return fetcher(`/api/st/courses/${id}`).then((payload) => {
      dispatch({ type: 'PAYMENT_COURSE_INFO', payload });
      return payload;
    });
  },
  resetGetCourseInfo: () => {
    dispatch({ type: 'PAYMENT_COURSE_INFO', payload: { success: true } });
  },
  getCards: () => {
    return fetcher(`/api/st/payments/cards`).then((payload) => {
      // dispatch({ type: 'PAYMENT_COURSE_INFO', payload });
      return payload;
    });
  },
  deleteCard: (idCard) => {
    return fetcher(`/api/st/payments/cards/${idCard}`, {
      method: 'DELETE'
    }).then((payload) => {
      return payload;
    });
  },
  editCard: (card) => {
    return fetcher(`/api/st/payments/cards`, {
      method: 'PUT',
      body: JSON.stringify(card)
    }).then((payload) => {
      return payload;
    });
  },
  createCard: (token) => {
    return fetcher('/api/st/payments/cards', {
      method: 'POST',
      body: JSON.stringify(token)
    }).then((resp) => {
      return resp;
    });
  }
});

export default actions;
