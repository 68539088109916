import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import ImageComponent from '../../components/imageVideos/ImageComponent';
import { context } from '../../context';
import styles from './videoPreview.module.sass';

const quizNoAnswer = `${process.env.PUBLIC_URL}/assets/quiz/quizIncomplete@2x.png`;
const quizOk = `${process.env.PUBLIC_URL}/assets/quiz/endQuiz2x.png`;
const file = `${process.env.PUBLIC_URL}/assets/group@2x.png`;

const VideoPreview = ({ data, actualVideoInfo, setLoading,
  isSelected, history, sidebarMobile }) => {

  const { actions } = useContext(context);

  const onClickFile = (id) => {
    setLoading(true);
    let result = actions.getPlaylistById(id)
    result.then(
      () => {
        setLoading(false)
        if (!sidebarMobile) {
          history.push({
            pathname: `/playlist/${id}`,
          })
        }
      }
    )

  }

  //obtine la informacion del video por id
  const setDataVideo = (data) => {
    history.push({
      pathname: `/playlist/${data.playlist}/video/${data._id}`,
    })
    actions.changeSideBar(false)
  }

  const getQuizInfo = (data) => {
    history.push({
      pathname: `/playlist/${data.parent}/quiz/${data._id}`,
    })
    actions.changeSideBar(false)
  }

  if (data.type === 1) {
    //return para los archivos
    let progres = data.progress ? data.progress : 0
    return (
      <div className={styles.container_item}>
        <div style={data.backgroundColor !== undefined ? { "background-color": data.backgroundColor } : {}} className={styles.file_box_shadow}>
          <Row className={styles.row_file} onClick={() => onClickFile(data._id)}>
            <Col xs="4" className={styles.file_image_contaner} >
              <img className={styles.image_file} src={data.image !== undefined ? data.image.url : file} alt="avatar" />
              {data.isLastItemHere ? <div className={styles.actual_item} /> : null}
            </Col>
            <Col xs="8" className={styles.file_data}>
              <span>{data.name}</span>
              {/*<p>Descripción: {data.description}</p>*/}
            </Col>
          </Row>
        </div>
        <div className={styles.overlays_file}>
          <div className={styles.overlay_progress + ' ' + (progres > 85 ? styles.green : progres > 24 ? styles.yellow : styles.red)} style={{ "width": `${progres}%` }} />
          <div className={styles.overlay_text_progress}>
            <span>{Number((progres).toFixed(2))}%</span>
          </div>
        </div>
      </div>
    )
  } else if (data.type === 2) {
    //return para los videos
    return (
      <div className={isSelected ? styles.space_item + " " + styles.container_video_side_bar_selected : styles.space_item}>
        <div className={styles.container_item_small}>
          <Row className={styles.row_file} onClick={() => setDataVideo(data)}>
            <Col xs="6" className={styles.container_video_side_bar}>
              <ImageComponent
                url={data.url}
                urlThumbnail={data.urlThumbnail}
                isViewed={data.viewed}
                isLastItem={data.lastItem} />
            </Col>
            <Col xs="6" className={styles.text_video_item}>
              <div>
                <span className={styles.video_title}>{data.title}</span>
              </div>
              <span className={styles.video_description}>{data.description}</span>
            </Col>
          </Row>
        </div>
      </div>
    )
  } else if (data.type === 3) {
    //QUIZZ
    return (
      <div className={isSelected ? styles.space_item + " " + styles.container_video_side_bar_selected : styles.space_item}>
        <div className={styles.container_item_small}>
          <div className={styles.container_video_side_bar} onClick={() => getQuizInfo(data)}>
            <Row className={styles.row_file}>
              <Col xs="4">
                {data.viewed ? <img src={quizOk} alt="avatar" className={styles.image_quiz} /> : <img src={quizNoAnswer} alt="avatar" className={styles.image_quiz} />}
                <div>
                  {data.lastItem !== undefined ? <div className={styles.actual_item} /> : null}
                </div>
              </Col>
              <Col xs="8">
                <div>
                  <span className={styles.video_title}>{data.name}</span>
                </div>
                <span className={styles.video_description}>{data.description}</span>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    )
  } else {
    return (<></>)
  }

};

export default VideoPreview;
