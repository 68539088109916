import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import CardForm from './cardForm';
import { Tooltip } from 'antd';
import styles from './card_form_payment.module.scss';
import 'react-credit-cards/lib/styles.scss';
import 'antd/lib/tooltip/style/index.css';


const instructions = <div>
  <ul>
    <li>Si no cuentas con tarjetas registradas previamente debes abrir el formulario y registrar los datos requeridos y presionar el botón “Continuar”.</li>
    <li>En el mismo formulario puedes elegir si deseas que conservemos tus datos para futuros pagos.</li>
    <li>Si ya cuentas con alguna tarjeta guardada, puedes seleccionarla o registrar una nueva y darle al botón "Continuar".</li>
  </ul>
</div>

const CardFormPayment = ({ setCardData, cardData, backPage, nextPage }) => {
  return (
    <Container className="px-0">
      <Row className={styles.row_title}>
        <Col xs="5" md="2" className="text-center">
          <Tooltip title={instructions} placement="bottom">
            <p>Instrucciones</p>
          </Tooltip>
        </Col>
      </Row>
      <Row className={styles.row_container} noGutters={true}>
        <div className={styles.container_register} >
            <CardForm setCardData={setCardData} cardData={cardData} backPage={backPage} nextPage={nextPage} />
        </div>
      </Row>
    </Container>
  )
}

export default CardFormPayment