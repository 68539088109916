import { Button, Form } from 'antd';
import styles from '../../../index.module.scss';

export const FormComent = ({
  stateComment,
  setStateComment,
  placeholder,
  textButton,
  submitting,
  saveFuntion,
  id = '',
  cancelFuntion
}) => {
  const handleChangeTextarea = (e) => {
    setStateComment(e.target.value);
  };
  return (
    <>
      <Form.Item>
        <textarea
          className={styles.text_area_styles}
          onChange={(e) => handleChangeTextarea(e)}
          value={stateComment}
          placeholder={placeholder}
        />
      </Form.Item>
      <div className={styles.formButtons}>
        <Form.Item>
          <Button
            htmlType="submit"
            loading={submitting}
            onClick={saveFuntion}
            type="primary"
            id={id}>
            {textButton}
          </Button>
        </Form.Item>
        {!!cancelFuntion && (
          <Form.Item>
            <Button
              htmlType="button"
              onClick={cancelFuntion}
              type="primary"
              danger
              id={id}>
              Cancel
            </Button>
          </Form.Item>
        )}
      </div>
    </>
  );
};
