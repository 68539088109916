/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import Icon from '../../components/icons';
import { context } from '../../context';
import styles from './index.module.sass';
import defaultAvatar from '../../assets/logo.png';

const Attendance = () => {
  const { state, actions } = useContext(context);
  const attendance = state.attendance || [];
  const [showAbsence, setShowAbsence] = useState(false);

  useEffect(() => {
    actions.getAttendance();
  }, []);

  const getIsAbsence = (day) => {
    return attendance.some(({ date }) => {
      return moment(date).format('DDMMYYYY') === moment(day).format('DDMMYYYY');
    });
  };

  const handleClickDay = (day) => {
    const isAbsence = getIsAbsence(day);
    setShowAbsence(isAbsence);
  };

  // FIXME: comments info requires a service (chat)
  const comments = [];

  return (
    <>
      <div className={styles.calendar_container}>
        <Calendar
          className={styles.calendar}
          view="month"
          minDetail="year"
          value={new Date()}
          showNavigation={true}
          onClickDay={handleClickDay}
          calendarType="Hebrew"
          prev2Label={null}
          next2Label={null}
          prevLabel={<Icon name="arrowleft" className={styles.arrow} />}
          nextLabel={<Icon name="arrowright" className={styles.arrow} />}
          tileContent={({ date, view }) => {
            const isAbsence = getIsAbsence(date);
            if (!isAbsence) return null;
            const absenceDetail = attendance.find(
              (absence) =>
                moment(date).format('DDMMYYYY') === moment(absence.date).format('DDMMYYYY')
            );
            const isLeft =
              (date.getDay() < 4 && styles.absence_tooltip) || styles.absence_tooltip_left;
            return (
              view === 'month' &&
              showAbsence &&
              isAbsence && (
                <div className={isLeft}>
                  <p>{absenceDetail.groupName || 'Sin grupo'}</p>
                  <p>{moment(date).format('DD MMMM YYYY - hh:mm')}</p>
                  <Icon
                    name={(date.getDay() < 4 && 'arrowleft') || 'arrowright'}
                    className={
                      (date.getDay() < 4 && styles.tooltip_arrow_left) || styles.tooltip_arrow_right
                    }
                  />
                </div>
              )
            );
          }}
          tileClassName={({ date, view }) => {
            const isAbsence = getIsAbsence(date);
            return (view === 'month' && isAbsence && styles.absence_day) || styles.day;
          }}
        />
      </div>
      {!!comments.length && (
        <div className={styles.comments}>
          <div className={styles.actived_courses}>
            <Icon name="remove" />
            <span>{comments.length} comentarios</span>
          </div>
          {comments.map(({ teacher, teacherAvatar, comment, date }) => {
            return (
              <div className={styles.comment_container} key={date}>
                <div className={styles.comment_title}>
                  <span className={styles.comment_img}>
                    <img src={teacherAvatar || defaultAvatar} alt={teacher} />
                  </span>
                  <span className={styles.comment_name}>{teacher}</span>
                  <span className={styles.comment_date}>{moment(date).format('DD/MM/YYYY')}</span>
                </div>
                <div className={styles.comment}>{comment}</div>
              </div>
            );
          }) || null}
        </div>
      )}
    </>
  );
};

export default Attendance;
