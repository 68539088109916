import React, { Fragment } from 'react';
import { PageHeader } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
// import { Divider } from 'antd';
import 'antd/es/page-header/style/index';
import 'antd/es/divider/style/index.css';
import 'antd/es/icon/style/index.css';
import styles from '../index.module.sass';

const Header = ({ name, questions, resultQuiz, onClickBack }) => {
  return (
    <Fragment>
      <PageHeader
        className="site-page-header"
        onBack={onClickBack}
        title={name ? 'Resultados de tu evaluación de ' + name : 'Resultados de tu evaluación'}>
        <h5>
          {resultQuiz} respuestas correctas de {questions}{' '}
        </h5>
        <div className={styles.text_answers_example}>
          <span className="mr-3">Respuesta correcta</span>
          <CheckCircleOutlined style={{ fontSize: '30px', color: '#52c41a' }} />
          <span className="ml-5 mr-3">Respuesta incorrecta</span>
          <CloseCircleOutlined style={{ fontSize: '30px', color: '#E74C3C' }} />
        </div>
      </PageHeader>
    </Fragment>
  );
};
export default Header;
