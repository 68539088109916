import { notification, message } from 'antd';
import './styles/index.scss'
import 'antd/lib/notification/style/index.css';
import 'antd/lib/message/style/index.css';
function showNotification(Type, MessageProp, DescriptionProp, DurationTime) {
    notification[Type]({
        message: MessageProp,
        description: DescriptionProp,
        duration: DurationTime
    })
}

function showMessageLoading(Message, DurationTime) {
    return message.loading(Message, DurationTime);
}
function showMessageInfo(Message, DurationTime) {
    return message.info(Message, DurationTime);
}
function showMessageSuccess(Message, DurationTime) {
    return message.success(Message, DurationTime);
}
function showMessageWarning(Message, DurationTime) {
    return message.warning(Message, DurationTime);
}
function showMessageError(Message, DurationTime) {
    return message.error(Message, DurationTime);
}
function showMessageCustom(Message, DurationTime) {
    return message.loading(Message, DurationTime);
}


export {
    showNotification,
    showMessageLoading,
    showMessageInfo,
    showMessageSuccess,
    showMessageWarning,
    showMessageError,
    showMessageCustom
}
