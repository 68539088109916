/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { showMessageSuccess, showMessageError, showMessageWarning, showNotification, showMessageLoading } from '../../components/MessageAndNotificationUtils';
import CardFormPayment from '../../components/cardFormPayment/index';
import PaymentsErrors from '../../utils/paymentErrors';
import CheckData from './components/checkData';
import EndPayment from './components/endPayment';
import { setTypeCard, setNumberCard } from '../../utils/funciones';
import { context } from '../../context';
import styles from './index.module.scss';



const initialState = {
  cardNumber: "",
  cardholderName: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  securityCode: "",
  paymentMethodId: "",
  transaction_amount: "",
  description: "",
}

let loading = ""

const Payments = ({ crud = false, match = "", courseId, isModalPayment = false }) => {

  const { state, actions } = useContext(context);

  const [isLoading, setIsLoading] = useState(false)
  const [rememberCard, setRememberCard] = useState(false)
  const [cardData, setCardData] = useState(initialState)
  const [selectedCard, setSelectedCard] = useState()
  const [showForm, setShowForm] = useState(false)
  const [page, setPage] = useState(1)
  const [successfulPayment, setSuccessfulPayment] = useState("")
  const [cards, setCards] = useState("")
  const [loadingCards, setLoadingCards] = useState(false)
  const [validationCardInfo, setValidationCardInfo] = useState("")

  useEffect(() => {
    setLoadingCards(true)
    if (!crud) {
      if (match !== "") {
        actions.getCourseInfo(match.params.courseId)
      }
      else {
        actions.getCourseInfo(courseId)
      }
    }
    let resultCards = actions.getCards()
    resultCards.then((response) => {
      if (response.success && response.cards.length > 0) {
        setCards(response.cards)
      }
      setLoadingCards(false)
    })
  }, [])


  //Here init the stat to prevent default and warnings in console 
  const selectedListCard = (card) => {
    if (card === undefined) {
      setSelectedCard(undefined)
    } else {
      const securityCode = {
        cardId: card.id,
        cardNumber: setNumberCard(card),
        cardholderName: card.cardholder,
        cardExpirationMonth: card.expiration_month,
        cardExpirationYear: card.expiration_year,
        payment_method_id: setTypeCard(card.payment_method_id),
        securityCode: "",
        transaction_amount: "",
        description: "",
        first_six_digits: card.first_six_digits
        //installments: 1
      }
      setSelectedCard(securityCode)
      //borramos los datos que pieda tener el formulario
    }
    setCardData(initialState)
  }

  const showStep = () => {
    switch (page) {
      case 1:
        return <CardFormPayment setCardData={setCardData} cardData={cardData} />
      case 2:
        return <CheckData buttonPay={buttonPay} courseInfo={state.paymentCourseInfo}
          backPage={() => setPage(1)} cardData={cardData} setCardData={setCardData} selectedCard={selectedCard} setSelectedCard={setSelectedCard}
          isLoading={isLoading} validationCardInfo={validationCardInfo} setValidationCardInfo={setValidationCardInfo} isModalPayment={isModalPayment} />
      case 3:
        return <EndPayment successfulPayment={successfulPayment} studentInfo={state.student} total={selectedCard ? selectedCard.transaction_amount : cardData.transaction_amount}
          courseInfo={state.paymentCourseInfo} backPage={() => setPage(2)} />
      default:
        break
    }
  }

  const buttonForm = async () => {
    let { cardholderName, cardExpirationMonth, cardExpirationYear, securityCode } = cardData
    if (selectedCard) {
      setPage(2)
    } else {
      if (cardholderName === "") {
        showMessageWarning("Atención! Debes ingresar tu nombre y apellido", 3)
      } else if (cardExpirationMonth === "") {
        showMessageWarning("Atención! Debes seleccionar un mes", 3)
      } else if (cardExpirationYear === "") {
        showMessageWarning("Atención! Debes seleccionar un año", 3)
      } else if (securityCode === "") {
        showMessageWarning("Atención! Debes ingresar el código de seguridad", 3)
      } else {
        if (state.paymentCourseInfo !== null) {
          setPage(2)
        } else {
          const courseInfo = await actions.getCourseInfo(match.params.courseId)
          if (courseInfo.success) {
            setCardData({
              ...cardData,
              description: courseInfo.course.name
            })
            setPage(2)
          } else {
            showMessageWarning("Atención! recarga la pagina o revisa tu conexión a internet", 3)
          }
        }
      }
    }
  }

  //valida el formulario de pago
  const buttonPay = () => {
    if (selectedCard !== undefined) {
      if (selectedCard.securityCode === "" || selectedCard.securityCode.length !== validationCardInfo.settings[0].security_code.length) {
        showMessageWarning("Atención! Debes ingresar tu código de seguridad", 3)
      } else if (selectedCard.transaction_amount.substring(0, 1) === "0" || selectedCard.transaction_amount === "" || selectedCard.transaction_amount < validationCardInfo.min_allowed_amount) {
        if (validationCardInfo !== "") {
          showNotification("error", "La cantidad a pagar no debe estar por debajo del pago mínimo que permite tu tarjeta",
            `Pago mínimo de $ ${validationCardInfo.min_allowed_amount} pesos`, 0)
        } else {
          showMessageWarning("Atención! Debes ingresar un monto a pagar mayor a cero", 3)
        }
      } else {
        loading = LoadingMessage('Validando pago....')
        setIsLoading(true)
        doPayExistCard()
      }
    } else {
      if (cardData.transaction_amount === "" || cardData.transaction_amount.substring(0, 1) === "0" || cardData.transaction_amount < validationCardInfo.min_allowed_amount) {
        if (validationCardInfo !== "") {
          showNotification("error", "La cantidad a pagar no debe estar por debajo del pago mínimo que permite tu tarjeta",
            `Pago mínimo de $ ${validationCardInfo.min_allowed_amount} pesos`, 0)
        } else {
          showMessageWarning("Atención! Debes ingresar un monto a pagar mayor a cero", 3)
        }
      } else {
        loading = LoadingMessage('Validando pago....')
        setIsLoading(true)
        doPay()
      }
    }
  }

  /////Generar el token de la tarjeta///////
  const doPay = () => {
    window.Mercadopago.createToken({ ...cardData }, sdkResponseHandler);
  }
  const doPayExistCard = () => {
    const data = {
      cardId: selectedCard.cardId,
      securityCode: selectedCard.securityCode
    }
    //console.log(data)
    window.Mercadopago.createToken(data, sdkResponseHandlerSelected);
  }


  const sdkResponseHandler = async (status, response) => {
    if (status !== 200 && status !== 201) {
      if (response.cause.length > 1) {
        let errorsString = ""
        for (let i = 0; i < response.cause.length; i++) {
          errorsString = errorsString + " " + PaymentsErrors(response.cause[i].code)
        }
        showNotification("error", errorsString, "", 0)
      } else {
        showNotification("error", PaymentsErrors(response.cause[0].code), "", 0)
      }
      window.Mercadopago.clearSession();
    } else {
      if (crud) {
        let saveCard = {
          token: response.id,
        }
        const addResult = await actions.createCard(saveCard)
        if (addResult.success) {
          const alreadyExistCard = cards.findIndex((obj => obj.id === addResult.card.id));
          if (alreadyExistCard === -1) {
            showMessageSuccess("Tarjeta guardada exitosamente", 4)
            setCards(cards => [...cards, addResult.card])
            setCardData(initialState)
            setShowForm(false)
          } else {
            showNotification("error", "La tarjeta que intentas guardar ya fue guardada previamente (ya existe).", "Revisa los datos ingresados", 0)
          }
        } else {
          showNotification("error", "No se pudo guardar tu tarjeta", "", 0)
        }
      } else {
        let sendCard = {
          paymentMethodId: cardData.paymentMethodId,
          token: response.id,
          transaction_amount: cardData.transaction_amount,
          courseId: state.paymentCourseInfo.course._id,
          rememberCard
        }
        const result = !isModalPayment ? await actions.paymentCard(sendCard) : await actions.paymentLesson(sendCard)
        setSuccessfulPayment(result)
        setPage(3)
      }
      window.Mercadopago.clearSession();
    }
    setIsLoading(false)
    loading()
  };

  const sdkResponseHandlerSelected = async (status, response) => {
    if (status !== 200 && status !== 201) {
      showNotification("error", "No pudimos validar tu tarjeta", "", 0)
      window.Mercadopago.clearSession();
    } else {
      let sendCard = {
        token: response.id,
        courseId: state.paymentCourseInfo.course._id,
        transaction_amount: selectedCard.transaction_amount,
      }
      console.log("state.paymentCourseInfo", state.paymentCourseInfo)
      const result = !isModalPayment ? await actions.paymentExistCard(sendCard) : await actions.paymentLesson(sendCard)
      setSuccessfulPayment(result)
      setPage(3)
      window.Mercadopago.clearSession();
    }
    setIsLoading(false)
    loading()
  };

  //////Operación de red para actualizar datos de tarjeta//////
  const editCardData = async () => {
    loading = LoadingMessage('Actualizando tarjeta....')
    setIsLoading(true)
    let body = {
      id: cardData.cardId,
      cardholderName: cardData.cardholderName,
      cardExpirationMonth: cardData.cardExpirationMonth,
      cardExpirationYear: cardData.cardExpirationYear,
    }

    const editResult = await actions.editCard(body)
    if (editResult.success) {
      showMessageSuccess(editResult.message, 5)
      let newArray = [...cards]
      newArray[cards.findIndex((obj => obj.id === editResult.card.id))] = editResult.card
      setCards(newArray)
      loading()
      setIsLoading(false)
      setShowForm(false)
      setSelectedCard(undefined)
    } else {
      loading()
      setIsLoading(false)
      showMessageError("No se pudo actualizar tu tarjeta", 5)
    }
  }

  //////Operación de red para eliminar una tarjeta//////
  const cardDelete = async (idCard) => {
    loading = LoadingMessage('Borrando tarjeta....')
    setLoadingCards(true)
    const deleteResult = await actions.deleteCard(idCard)
    if (deleteResult.success) {
      let newArrayCards = cards.filter(card => card.id !== idCard)
      setCards(newArrayCards)
      loading()
      showMessageSuccess(deleteResult.message, 5)
      setLoadingCards(false)
      setSelectedCard(undefined)
    } else {
      showMessageError("No se pudo borrar tu tarjeta", 5)
      loading()
      setLoadingCards(false)
    }
  }

  //////Operación de red para guardar una tarjeta//////
  const saveCard = () => {
    let { cardholderName, cardExpirationMonth, cardExpirationYear, securityCode } = cardData
    if (cardholderName === "") {
      showMessageWarning("Atención! Debes ingresar tu nombre y apellido", 3)
    } else if (cardExpirationMonth === "") {
      showMessageWarning("Atención! Debes seleccionar un mes", 3)
    } else if (cardExpirationYear === "") {
      showMessageWarning("Atención! Debes seleccionar un año", 3)
    } else if (securityCode === "") {
      showMessageWarning("Atención! Debes ingresar el código de seguridad", 3)
    } else {
      loading = LoadingMessage('Validando datos de la tarjeta....')
      setIsLoading(true)
      doPay()
    }
  }

  const LoadingMessage = (message) => {
    return showMessageLoading(message, 0);
  };

  const cancel = () => {
    setShowForm(false)
    setSelectedCard(undefined)
    setCardData(initialState)
    setValidationCardInfo("")
  }

  return crud ?
    <CardFormPayment showForm={showForm} setShowForm={setShowForm} selectedCard={selectedCard}
      setSelectedCard={selectedListCard} buttonForm={buttonForm}
      setCardData={setCardData} cardData={cardData} setRememberCard={setRememberCard} rememberCard={rememberCard}
      cards={cards} loadingCards={loadingCards} crud={crud} deleteCard={cardDelete} editCard={setCardData} cancel={cancel}
      editCardData={editCardData} isLoading={isLoading} saveCard={saveCard} setValidationCardInfo={setValidationCardInfo} validationCardInfo={validationCardInfo}
    />
    : <div className={styles.div_wizard}>
      <div className="wizard">
        <div className="wizard__steps">
          <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>Selecciona Metodo de pago</p></div>
          <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>Confirmar</p></div>
          <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}> <p>Finalizar</p></div>
        </div>
        <div className="wizard__form-wrapper">
          <div className={styles.payments_wizard_container}>
            {showStep()}
          </div>
        </div>
      </div>
    </div>
};
export default Payments;
