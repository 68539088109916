import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap'
import PaymentModule from '../../../payments'
import ButtonComponent from '../../../../components/button'
import { Card, CardBody } from 'reactstrap';
import styles from './styles.module.scss'

const icon = `${process.env.PUBLIC_URL}/assets/video_back_thumbnail.png`;

const PayLessons = ({ courseId }) => {
    const [isOpenModal, setisOpenModal] = useState(false)
    return (
        <>
            <div className={styles.container}>
                <div className={styles.container_row}>
                    <p class={styles.live}>Clase en vivo</p>
                    <img
                        className={styles.container_chat_iframe}
                        src={icon}
                        width="100%"
                        weight="100%"
                        alt="logo"
                    />
                </div>
                <div className={styles.message_payment}>
                    <Card className={styles.cardStyle}>
                        <CardBody className="text-center">
                            <p>Para continuar viendo las clases en vivo puedes pagar por completo el curso ó pagar por clase</p>
                            <Row>
                                <Col xs="12" sm="6">
                                    <Link to={"financial/payments/course/" + courseId}>
                                        <ButtonComponent className={styles.button_container} theme="light">
                                            Pagar curso
                                        </ButtonComponent>
                                    </Link>
                                </Col>
                                <Col xs="12" sm="6">
                                    <ButtonComponent className={styles.button_container} theme="light" onClick={() => setisOpenModal(true)}>
                                        Pagar clase
                            </ButtonComponent>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div>
            {isOpenModal ?
                <Modal
                    open={isOpenModal}
                    onClose={() => setisOpenModal(false)}
                    center={true}
                    closeOnEsc={false}
                    showCloseIcon={true}
                    closeOnOverlayClick={false}>
                    <PaymentModule courseId={courseId} isModalPayment/>
                </Modal> : null}
        </>
    )

}
export default PayLessons