import React, { useContext } from 'react';
import CountDown from '../CountDown'
import { Link } from 'react-router-dom';
import { context } from '../../context';
import Spinner from '../../components/spinner'
import Icon from '../icons';
import { Progress } from 'antd';
import 'antd/es/progress/style/index.css'
import styles from './sidebarPlaylist.module.scss';


const SideBarQuiz = ({ history, goBack }) => {

  const { state, actions } = useContext(context);

  const backChapter = () => {
    goBack()
    actions.resetAnsweredExam()
  }

  const showProgressbar = (questionNUmber) => {
    let percentage = 100 / state.quiz.questionCollection.length;
    return Math.floor(questionNUmber * percentage);
  }

  return (
    <>
      {state.latsQuizActive === undefined || state.latsQuizActive === null? <Spinner /> :
        <div className="sidebar__content">
          <aside className={styles.aside}>
            <div className={styles.button_container}>
              <div className={state.playlist !== null && (state.playlist.parent !== undefined || state.playlist.courseInfo !== undefined) ? "" : styles.hide_back}>
                <button href="#" className={styles.back_page}
                  onClick={() => {
                    if (state.playlist !== undefined && state.playlist !== null) {
                      if (state.quiz !== undefined && state.quiz !== null) {
                        history.push({
                          pathname: `/playlist/${state.playlist._id}/quiz/${state.quiz._id}`
                        })
                      } else {
                        history.push({
                          pathname: `/playlist/${state.playlist._id}`
                        })
                      }
                    } else {
                      backChapter()
                    }
                  }}
                >
                  <Icon name="left" className={styles.arrow_left} />
                  <span>Regresar</span>
                </button>
              </div>
              <div>
                <Link to="/">
                  <button className={styles.back_page}>
                    <Icon name="home" className={styles.arrow_left} />
                    <span className="ml-1">Inicio</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className={styles.info_container} xs="12">
              <p className={styles.course_name}>{state.quiz.name}</p>
              <p >Descripción: {state.quiz.description}</p>
            </div>
            <div className={styles.scroll_playlist}>
              {state.latsQuizActive !== null && state.latsQuizActive.status === "CLOSE" ?
                <div className={styles.container_progress}>
                  <div className={styles.vertical_center}>
                    <div className="text-center">
                      <h3>Resultado</h3>
                      <h3>{state.latsQuizActive.percentageResult.toFixed(0)} %</h3>
                      {
                        state.latsQuizActive.percentageResult >= 60 ?
                          <span className={styles.score_attempt_rigth}>{"Aprobado"}</span>
                          :
                          <span className={styles.score_attempt_wrong}>{"Reprobado"}</span>
                      }
                    </div>
                  </div>
                </div>
                :
                <div className={styles.container_progress}>
                  <div className={styles.vertical_center}>
                    <div className="text-center">
                      <h3>Pregunta</h3>
                      <h3>{state.quizAttemptActualQuestion + 1} de {state.quiz.questionCollection.length}</h3>
                    </div>
                    <Progress strokeColor="#fcab00" strokeWidth="18px"
                      percent={showProgressbar(state.quizAttemptActualQuestion + 1)} showInfo={false} />
                  </div>
                  {
                    state.latsQuizActive.availableTime ?
                      <div>
                        <CountDown limitTime={state.latsQuizActive.limitTime} time={state.latsQuizActive.availableTime} />
                      </div> : null
                  }
                </div>
              }
            </div>
          </aside>
        </div>
      }
    </>
  )
}
export default SideBarQuiz;
