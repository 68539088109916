/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Table, Container, Row, Col } from 'reactstrap'
import moment from 'moment';
import { context } from '../../context';
import Spinner from '../../components/spinner';
import dolar from '../../assets/dolar.png';
import creditCard from '../../assets/credit-card.png';
import { Link } from 'react-router-dom';
import ButtonComponent from '../../components/button';
import { Collapse } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import 'antd/es/collapse/style/index.css';
import 'antd/dist/antd.css';
import styles from './index.module.sass';

const { Panel } = Collapse;

const Financial = () => {
  const { state, actions } = useContext(context);

  useEffect(() => {
    actions.getFinancialData();
    actions.resetGetCourseInfo()
  }, []);

  const genExtra = (status, price = 0, totalPayments = 0) => {
    let quantity = 0
    quantity = price - totalPayments
    if (status) {
      return <><span className={styles.debt_div}>Adeuda $ {quantity} </span> <InfoCircleFilled style={{ fontSize: "24px", color: "#E74C3C" }} /></>
    }
  }

  const showTabs = () => {
    return state.financial.payments.map((obj, index) => {
      return (
        <Panel header={`${obj.name}`} key={index} extra={genExtra(obj.debt, obj.price, obj.totalPayments)}>
          {showContent(obj)}
        </Panel>
      )
    })
  }

  const showContent = (obj) => {
    return (
      <>
        <Container>
          <Row>
            <Col xs="12" md="3 offset-md-9" className="mb-3">
              {obj._id !== undefined ? <Link to={`/financial/payments/course/${obj._id}`}>
                {obj.debt ? <ButtonComponent className={styles.button_container} theme="light" block>
                  Pagar adeudo
                </ButtonComponent> : ""}
              </Link> : ""}
            </Col>
            <Col xs="12" md="3" className={styles.boxDataPayments}>
              Precio: $ {obj.price !== undefined ? obj.price : ""}
            </Col>
            <Col xs="12" md="3 offset-md-1" className={styles.boxDataPayments}>
              {obj.payments.length} pago(s)
                  </Col>
            <Col xs="12" md="4 offset-md-1" className={styles.boxDataPayments}>
              Total abonado $ {obj.totalPayments}
            </Col>
          </Row>
        </Container>
        <div className={styles.table_margin}>
          <Table borderless responsive size="lg" hover >
            <thead>
              <tr>
                <th>Concepto</th>
                <th>Monto</th>
                {/* <th className={styles.text_table}>Descuento</th> */}
                <th className={styles.text_table}>Fecha</th>
                <th className={styles.text_table}>Método</th>
              </tr>
            </thead>
            <tbody>
              {obj.payments.map((value, index) => {
                return (
                  <tr key={index}>
                    <td className={styles.concept_cont}>{value.concept}</td>
                    <td>${value.amount.toFixed(2)}</td>
                    {/* <td className={styles.text_table}>${value.discount.toFixed(2)}</td> */}
                    <td className={styles.text_table}>{moment(value.date).format('DD MMM YY')}</td>
                    <td className={styles.text_table}>
                      <img
                        src={(value.paymentType === 'cash' && dolar) || creditCard}
                        alt="Pago en efectivo"
                        className={styles.payment_icon}
                      />
                    </td>
                  </tr>)
              })}
            </tbody>
          </Table>
        </div>
      </>
    )
  }

  return state.financial !== null ?
    <>
      {/* <div>
        <Link to={`/financial/payments/method`}>
          <ButtonComponent className="float-right mt-2 mr-4" theme="light">
            Métodos de pago
          </ButtonComponent>
        </Link>
      </div> */}
      <div className={styles.collapse_div}>
        <Collapse ghost={true} expandIconPosition="left">
          {showTabs()}
        </Collapse>
      </div> </> : <Spinner />
};

export default Financial;
