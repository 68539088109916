import Icon from 'components/icons';
import styles from '../index.module.scss';

export const VideoHead = ({ video }) => {
  const { title, views, rate } = video;
  return (
    <div>
      <div className={styles.title}>{title}</div>
      <div className={styles.metrics}>
        <Icon name="play" className={styles.iconplay} />
        <span>{views || 0} veces</span>
        <Icon name="star" className={styles.iconstar} />
        <span>{rate || 0} / 5</span>
      </div>
    </div>
  );
};
