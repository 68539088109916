import React from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import ButtonComponent from '../../../components/button';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';
import { newSchedules } from '../../../utils/funciones'
import 'moment/locale/es';
import styles from './steps.module.scss';

const EndPayment = ({ successfulPayment, studentInfo, total, courseInfo, isEnrrollFlow = false, backPage, isFree = false, choosedGroup }) => {

  const { message = "Inscripción realizada correctamente.", status = "", idOperation = "", date_approved = "", success = false, error = {} } = successfulPayment
  const { name = "", lastName = "", secondLastName = "", phoneNumber = "", email = "" } = studentInfo

  return (
    <Container>
      {isEnrrollFlow && isFree ?
        <>
          <Col md={12} xl={12} sm={12} className="mt-2">
            <Card className={`${styles.pricing_card} ${styles.pricing_card_success}`} >
              <CardBody>
                <div className={styles.svg_container}>
                  <svg className={styles.ft_green_tick} xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                    <circle className={styles.circle} fill="#5bb543" cx="24" cy="24" r="22" />
                    <path className={styles.tick} fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" strokeLinejoin="round" stroke-strokemiterlimit="10" d="M14 27l5.917 4.917L34 17" />
                  </svg>
                </div>

                <h3 className="pricing-card__plan">Quedaste inscrito en:</h3>
                <h2 className="pricing-card__plan">Curso elegido: {courseInfo.course.name}</h2>
                <hr />
                <h4 className="mt-3">Grupo - <b>{choosedGroup.name}</b></h4>
                {/* <h4 className="mt-1">Salón - <b>{this.state.salon}</b></h4> */}
                <h4 className="mt-1">Inicia  - <b>{moment(choosedGroup.startDate).format('DD/MM/YYYY')}</b></h4>
                {/* <h4 className="mt-1">Sede  - <b>{this.state.sede}</b></h4> */}
                <h4 className="mt-1"><b>{choosedGroup.schedules !== undefined ? newSchedules(choosedGroup.schedules) : ""}</b></h4>

              </CardBody>
            </Card>
          </Col>
        </>
        : success ?
          <Row className={styles.row_end_payment}>
            <Col xs="12" md={{ size: 6, offset: 3 }}>
              <Card className={styles.end_payment}>
                <CardBody className="text-center">
                  <p>{message}</p>
                  <div className={styles.svg_container}>
                    <svg className={styles.ft_green_tick} height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                      <circle className={styles.circle} fill="#5bb543" cx="24" cy="24" r="22" />
                      <path className={styles.tick} fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" strokeLinejoin="round" stroke-strokemiterlimit="10" d="M14 27l5.917 4.917L34 17" />
                    </svg>
                  </div>
                  {status === 1 ? <>
                    <p> Metodo de pago: <span>Tarjeta</span> </p>
                    <p>Numero de operación: <span>{idOperation}</span> </p>
                    <p>Fecha de pago: <span>{date_approved !== "" ? moment(date_approved).format("DD/MM/YY HH:mm A") : ""}</span> </p>
                    <p>Pago total: <span>$ {total}</span> </p>
                    <p>Descripción: <span>{courseInfo.course.name}</span></p>
                    <p>Alumno:<span>{`${name} ${lastName} ${secondLastName}`}</span> </p>
                    <p>Correo: <span>{email}</span></p>
                    <p>Teléfono:<span>{phoneNumber}</span> </p></> :
                    ""}
                  {status === 2 ? <>
                    <p> Metodo de pago: <span>Tarjeta</span> </p>
                    <p>Numero de operación: <span>{idOperation}</span> </p>
                    <p>Status de operación: <span>Tu pago fue pre aprobado y estamos en espera de confirmación de tu banco, si es rechazada nos pondremos en contacto contigo.</span> </p>
                    <p>Pago total: <span>$ {total}</span> </p>
                    <p>Descripción: <span>{courseInfo.course.name}</span></p>
                    <p>Alumno:<span>{`${name} ${lastName} ${secondLastName}`}</span> </p>
                    <p>Correo: <span>{email}</span></p>
                    <p>Teléfono:<span>{phoneNumber}</span> </p></> :
                    ""}
                </CardBody>
              </Card>
            </Col>
          </Row> :
          <Row className={styles.row_end_payment}>
            <Col xs="12" md={{ size: 6, offset: 3 }}>
              <Card className={styles.end_payment_rejected}>
                <CardBody className="text-center">
                  <p>{message}</p>
                  <CloseCircleOutlined style={{ fontSize: "80px", color: "#ff5733" }} />
                  {isEnrrollFlow ? null : <p>Intenta con otra tarjeta o termina la operación.</p>}
                </CardBody>
              </Card>
            </Col>
          </Row>}


      {!success && !isFree ?
        <Row>
          <Col xs="12" md="3 offset-md-3">
            <ButtonComponent className="" theme="light" block
              onClick={() => backPage()}
            >
              Regresar
            </ButtonComponent>
          </Col>
          <Col xs="12" md="3">
            <Link to={`/financial`} className={styles.button_finish}>
              <ButtonComponent theme="light" block>
                Terminar
              </ButtonComponent>
            </Link>
          </Col>
        </Row> : ""}
      {success || isFree ? <div className={styles.container_button}>
        <Link to={`/financial`} className={styles.button_finish}>
          <ButtonComponent theme="light" block>
            Terminar
          </ButtonComponent>
        </Link>
      </div> : ""}


    </Container>
  )
}
export default EndPayment