import React from 'react';
import InputForm from '../../components/input-form';
import useFormInput from '../../hooks/useFormInput';
import styles from './index.module.sass';

const FinancialAddAccount = () => {
  const name = useFormInput('');
  const cardholder = useFormInput('');
  const date = useFormInput('');
  const cvv = useFormInput('');

  return (
    <>
      {/* <Header backPage="/financial/accounts" title="Añadir cuenta">
        <button className={styles.save_btn} onClick={() => saveAccount()}>
          Guardar
        </button>
      </Header> */}
      <p className={styles.required_label}>
        <span>*</span>Campos obligatorios
      </p>
      <div className={styles.container}>
        <InputForm
          type="text"
          label="Nombre del titular *"
          placeholder="Nombre del titular"
          {...name}
        />
        <InputForm
          type="text"
          label="Número de tarjeta *"
          placeholder="0000 0000 0000 0000"
          {...cardholder}
        />
        <InputForm type="text" label="Fecha de vencimiento *" placeholder="MM/AA" {...date} />
        <InputForm type="text" label="Código de seguridad (CVV) *" placeholder="123" {...cvv} />
      </div>
    </>
  );
};

export default FinancialAddAccount;
