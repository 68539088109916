/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import { context } from '../../context';
import { VideoHead } from './components/videoHead';
import { VideoBody } from './components/videoBody';
import { VideoFooter } from './components/videoFooter';
import VideoComents from './components/videoComents';
import 'antd/dist/antd.css';
import styles from './index.module.scss';

const VideoPlay = () => {
  const { state, actions } = useContext(context);
  const [ready, setReady] = useState(false);
  const player = useRef();
  const [flagFinish, setFlagFinish] = useState(false);

  useEffect(() => {
    player.current.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      false
    );

    return player.current.removeEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault();
      },
      false
    );
  }, []);

  useEffect(() => {
    setFlagFinish(false);
  }, state.video._id);

  const handleChange = (value) => {
    if (value > 0) actions.setVideoRate(state.video._id, value);
  };

  const onProgress = async (value) => {
    let percentage = value.played * 100;
    if (percentage > 85 && !flagFinish) {
      actions.setWatchVideo(state.video._id);
      setFlagFinish(true);
    }
  };

  return (
    <div className={styles.container}>
      <VideoHead video={state.video} />

      <VideoBody
        video={state.video}
        onProgress={onProgress}
        setReady={setReady}
        ready={ready}
        player={player}
      />

      <VideoFooter video={state.video} handleChange={handleChange} />

      <VideoComents video={state.video} actions={actions} />
    </div>
  );
};
export default VideoPlay;
