import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Modal } from 'antd';
import ReactImageZoom from 'react-image-zoom';
import 'antd/es/modal/style/index.css';
// import styles from '../index.module.sass';
const ModalImage = ({ modal, setModal, imgInfo }) => {

  const props = {
    width: 450, height: 350, zoomWidth: 600, offset: { horizontal: -600 },
    img: imgInfo.hasOwnProperty("questionImage") ? imgInfo.questionImage.url : imgInfo.answerImage.url
  };

  return (
    <Fragment>
      <Modal
        title="Pasa el cursor sobre la imagen para maximizar"
        centered
        visible={modal}
        footer={false}
        //onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
        width="80%"
      >
        <Container>
          <Row>
            <Col>
              <ReactImageZoom {...props} />
            </Col>
          </Row>
        </Container>

      </Modal>
    </Fragment>
  )
}
export default ModalImage
