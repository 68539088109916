import React from 'react';
import styles from './spinner.module.sass';

const Spinner = () => (
  <div className={styles.spinner_container}>
    <div className={styles.lds_ring}>
      <div />
      <div />
      <div />
    </div>
    <p>Cargando... Un momento más :)</p>
  </div>
);

export default Spinner;
