
const PaymentsErrors = (typeE) => {

  switch (typeE) {
    case "106":
      return "No puedes realizar pagos a otros países."
    case "109":
    case "204":
      return "Elige otra tarjeta u otro medio de pago."
    case "126":
    case "160":
      return "No pudimos procesar tu pago."
    case "129":
      return "No procesa pagos del monto seleccionado."
    case "145":
      return "Una de las partes con la que intentas hacer el pago es de prueba y la otra es usuario real."
    case "150":
    case "151":
      return "No puedes realizar pagos."
    case "205":
      return "Ingresa el número de tu tarjeta."
    case "E301":
      return "Ingresa un número de tarjeta válido."
    case "E302":
      return "Revisa el código de seguridad."
    case "316":
      return "Ingresa un nombre válido."
    case "322":
      return "El tipo de documento es inválido."
    case "323":
      return "Revisa tu documento."
    case "324":
      return "El documento es inválido."
    case "325":
      return "El mes es inválido."
    case "326":
      return "El año es inválido."
    default:
      return " Revisa los datos de tu tarjeta.";
  }
}
export default PaymentsErrors 