import { getCookie } from './cookies';
const urlBase = process.env.REACT_APP_HOST;

const fetcher = (endpoint = '', props = {}) => {
  const headers = {
    ...props.headers,
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  };

  if (!endpoint.includes('login') && !headers.Authorization) {
    headers['Authorization'] = getCookie('token');
  }

  return fetch(urlBase + endpoint, {
    ...props,
    headers,
    mode: 'cors'
  })
    .then((response) => response.json())
    .catch((error) => ({ success: false, error }));
};

export default fetcher;

export async function fetchAsync(_url, _body, _method, _header, _token) {
  let headerC = new Headers({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With'
    // "Content-Type": "Accept",
  });
  var data;

  if (_body === '') {
    let options = {
      method: _method,
      headers: headerC
    };
    data = await fetch(_url, options)
      .then((response) => response.json())
      .then((data) => data);
  } else {
    let options = {
      method: _method,
      headers: headerC,
      body: _body
    };
    data = await fetch(_url, options)
      .then((response) => response.json())
      .then((data) => data);
  }

  return data;
}
