import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Card as Card1 } from 'antd';
import course3 from '../../../assets/course_3.png';
import 'antd/es/card/style/index.css'
import styles from '../index.module.sass';

const { Meta } = Card1;

const SelectorCard = (props) => {
    return (
        <div className={styles.container_cards_selector}>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h3 className="page-title">Selecciona el nivel que deseas registrar</h3>
                        </div>
                        <Row>
                            {props.levels.map(item => {
                                return (
                                    <Col xs="12" sm="6" md="6" className={styles.cards_courses}>
                                        <Card1
                                            onClick={() => props.setLevel(item._id)}
                                            hoverable
                                            cover={<img alt="course" src={course3} />}>
                                            <Meta
                                                title={item.name}
                                                description={item.description} />
                                        </Card1>
                                    </Col>
                                )
                            })}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </div>
    )

}

export default SelectorCard;
