import React, { useState } from 'react';
// import { showMessageWarning, showNotification } from '../../components/MessageAndNotificationUtils';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Spinner } from 'reactstrap';
import ButtonComponent from '../button';
import styles from './card_form_payment.module.scss';
import 'react-credit-cards/lib/styles.scss';

const options = {
  iconStyle: 'solid',
  style: {
    base: {
      color: "#424770",
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: 18,
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: "#aab7c4",
      },
    },
    invalid: {
      iconColor: '#f23d4f',
      color: '#f23d4f',
    },
  }
}


const CardForm = ({ setCardData, backPage }) => {

  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  // const [disabled, setDisabled] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  // Listen for changes in the CardElement
  // and display any errors as the customer types their card details
  const handleChange = async (event) => {
    // setDisabled(event.empty);
    setErrorMessage(event.error ? event.error.message : "");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {},// Include any additional collected billing details
    });
    stripePaymentMethodHandler(result);
  };

  const stripePaymentMethodHandler = (result) => {
    console.log("BEFORE NETWORK REQUIEST", result)
    if (result.error) {
      handleServerResponse(result);
    } else {
      setCardData({
        card: result.paymentMethod.card,
        idOperation: result.paymentMethod.id,
        created: result.paymentMethod.created,
      })
    }
  }

  const handleServerResponse = (response) => {
    if (response.error) {
      setErrorMessage(response.error.message)
      // setDisabled(false)
      setProcessing(false)
      // Show error from server on payment form
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      stripe.handleCardAction(
        response.payment_intent_client_secret
      ).then(handleStripeJsResult);
    } else {
      // Show success message
      console.log(" Show success messageeeee")
    }
  }

  const handleStripeJsResult = (result) => {
    console.log("RESULT HANDLER CARD ", result);
    if (result.error) {
      // setDisabled(false)
      setProcessing(false)
      setErrorMessage(result.error.message)
    } else {
      setCardData({
        card: result.paymentMethod.card,
        idOperation: result.paymentMethod.id,
        created: result.paymentMethod.created,
      })
    }
  }

  return (
    <>
      <div className={styles.card_container}>
        <CardElement id="card-element" options={options} onChange={handleChange} />
        {/* Show any error that happens when processing the payment */}
        {errorMessage && (
          <div className={styles.card_errors_div}>
            <p><i className="fas fa-times-circle"></i> {errorMessage}</p>
          </div>
        )}
      </div>
      <div>
        <div className={styles.container_button + " pt-4 mb-2"}>
          <ButtonComponent className={styles.button_next} theme="light" block onClick={handleSubmit}>
            {processing ? (<Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />) : "Siguiente"}
          </ButtonComponent>
        </div>
        <div className={styles.container_button + " pt-4"}>
          <ButtonComponent className={styles.button_next} theme="light" block onClick={backPage}>
            Atras
          </ButtonComponent>
        </div>
      </div>
    </>
  )
}
export default CardForm