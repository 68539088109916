import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { context } from '../../context';
import Icon from '../../components/icons';
import { Tooltip } from 'antd';
import 'antd/es/tooltip/style/index.css'
import styles from './index.module.sass';
import QuizAttemps from './QuizAttempts'

const instructions = "El examen solo se puede realizar una vez, al final podras ver tu resultado y la calificación obtenida."
const QuizFace = ({ showAnsweredQuiz }) => {

  const { state, actions } = useContext(context);
  const quizStartSmall = `${process.env.PUBLIC_URL}/assets/quiz/quizStart.png`;
  const quizStart = `${process.env.PUBLIC_URL}/assets/quiz/quizStart3x.png`;

  const history = useHistory()

  const showExamResult = (id) => {
    let result = actions.getAnsweredExam(id)
    result.then(() => showAnsweredQuiz())
    // setShowQuiz(true)
  }

  const repeatQuiz = () => {
    let resultPromise = actions.createAttemptQuiz()
    resultPromise.then((result) => {
      history.push({
        pathname: `/quiz/${state.quiz._id}/${result.quizAttempt._id}`,
      })
    })
  }

  const pendingQuizz = () => {
    history.push({
      pathname: `/quiz/${state.quiz._id}/${state.latsQuizActive._id}`,
    })
  }

  return (
    <Container>
      {state.quizAttempts !== null && state.quizAttempts.length > 0 ?
        <Row>
          <h3 className={styles.title_attempts}>{state.quiz.name} resultados</h3>
          <Col xs="12">
            {
              state.quizAttempts.map((data, index) => {
                if (data.status === "CLOSE") {
                  return <QuizAttemps key={index} quizInfo={data}
                    showExamResult={showExamResult} />
                } else {
                  return ""
                }
              })
            }
          </Col>
          <Col xs="12">
            <div className={styles.div_attempts_new}>
              <Row>
                <Col xs="6">
                  <div className="text-center">
                    <img className={styles.start_quiz_small} src={quizStartSmall} alt="quizImage" />
                  </div>
                </Col>
                {state.latsQuizActive !== null ? <Col xs="6">
                  <div className="float-right mt-5">
                    <Button color="secondary" size="lg" className={styles.div_button_repeat_quiz}
                      onClick={pendingQuizz}
                    >
                      Continuar prueba
                    </Button>
                  </div>
                </Col>
                  :
                  <Col xs="6">
                    <div className="float-right mt-5">
                      <Button color="success" size="lg" className={styles.div_button_repeat_quiz}
                        onClick={repeatQuiz}
                      >
                        Repetir la prueba
                      </Button>
                    </div>
                  </Col>
                }
              </Row>
            </div>
          </Col>
        </Row>
        :
        <Row className="text-center">
          <Col xs="12">
            <h3>{state.quiz.name}</h3>
            <img src={quizStart} alt="quizImage" className={styles.image_quizFace_firstStart} />
            <p className={styles.number_questions}>
              No preguntas: {state.quiz.questionCollection.length}
              <Tooltip
                className={styles.tooltip_instructions}
                title={instructions}
              >
                <Icon
                  name="help"
                  className=""
                />
              </Tooltip>
            </p>
            <Row>
              <Col sm={{ size: 6, offset: 3 }}>
                <Button color="primary" size="lg" block
                  onClick={repeatQuiz}
                >
                  Comenzar
            </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    </Container>
  )
}
export default QuizFace