import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Steps, Spin } from 'antd';

import SteptForm from './steptForm';
import Quiz from '../../pages/quiz';
import QuizResults from '../quizInfo/quizResults';
import { context } from '../../context';
import { fetchAsync } from '../../utils/fetcher';
import useLocalStorage from '../../hooks/useLocalStorage';

import styles from './steps.module.scss';

const { Step } = Steps;
const urlBase = process.env.REACT_APP_HOST;
const url = urlBase + '/api/unpr/freeTest/getFreeExam/';
const urlGetResult = urlBase + '/api/unpr/freeTest/register';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const StepsFreeExamns = () => {
  let query = useQuery();
  let talk = query.get("idTalk");
  //return null when is not needed 
  let { idFreeExam } = useParams();
  const { state, actions } = useContext(context);
  const [setValueLocalStorage, getStorageValue] = useLocalStorage();

  const [current, setCurrent] = useState(0);
  const [freeLatsQuizActive, setFreeLatsQuizActive] = useState('');
  const [endQuiz, setEndQuiz] = useState('');
  const [quizResult, setQuizResult] = useState('');
  const [registeredUserData, setRegisteredUserData] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchAsync(url + idFreeExam, '', 'GET', '')
      .then((result) => {
        setLoading(false);
        if (result.success) {
          if (localStorage.getItem('fullQuiz') !== null) {
            const fullQuiz = getStorageValue('fullQuiz', '');
            fullQuiz.forEach((element, index) => {
              result.quizAttempt.questionCollection.map((data) => {
                if (data.question === element.question && element.arrayUserAnswers) {
                  data.arrayUserAnswers = element.arrayUserAnswers;
                }
              });
            });
            setFreeLatsQuizActive(result.quizAttempt);
          } else {
            setFreeLatsQuizActive(result.quizAttempt);
          }
        }
        return result;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  }, []);

  const next = () => {
    setCurrent(current + 1);
  };

  // const prev = () => {
  //   setCurrent(current - 1);
  // };

  const onFinishQuiz = (endedQuiz) => {
    next();
    setEndQuiz(endedQuiz);
    //delete the quiz in localstorage
    localStorage.removeItem('fullQuiz');
  };

  const onSaveFormGetResult = (studentData) => {
    setLoading(true);
    let quizAnswers = [];
    endQuiz.map((question, index) => {
      let obj = {
        idQuizQuestion: question._id,
        questionAnswers: question.arrayUserAnswers
      };
      quizAnswers.push(obj);
    });

    const { email, lastName, name, phoneNumber, phoneNumberTwo, secondLastName } = studentData;
    const studentInfo = {
      name,
      lastName,
      secondLastName,
      phoneNumber: phoneNumber.toString(),
      secondPhoneNumber: phoneNumberTwo.toString(),
      email
    };
    const payload = {
      testId: freeLatsQuizActive.quizParent._id,
      userTalk: studentInfo,
      quizAnswers
    };

    let urlComposed = ""
    if (talk !== null) {
      urlComposed = urlGetResult + "?idTalk=" + talk;
    } else {
      urlComposed = urlGetResult;
    }

    fetchAsync(urlComposed, JSON.stringify(payload), 'POST')
      .then((data) => {
        setLoading(false);
        if (data.success) {
          setQuizResult(data.quizAttempt);
          setRegisteredUserData(data.userTalk);
          actions.setFreeExamData(data.quizAttempt);
        }
        return data;
      })
      .catch((reason) => {
        console.log(reason.message);
      });
  };

  const steps = [
    {
      title: 'EXAMEN',
      content: loading ? (
        <Spin size="large" />
      ) : freeLatsQuizActive !== '' ? (
        <Quiz
          examType="freeExam"
          freeLatsQuizActive={freeLatsQuizActive}
          onFinishQuiz={onFinishQuiz}
        />
      ) : (
        <Spin size="large" />
      )
    },
    {
      title: 'RESULTADO',
      content: loading ? (
        <Spin size="large" />
      ) : state.answeredExam == null ? (
        <SteptForm onSaveFormGetResult={onSaveFormGetResult} />
      ) : (
        <QuizResults />
      )
    }
  ];

  return (
    <>
      <div>
        {state.answeredExam == null && (
          <>
            <h4>Instrucciones</h4>
            <p>
              {current === 1
                ? 'Registrate para obtener tus resultados'
                : 'Contesta las preguntas y obtén tus resultados'}
            </p>{' '}
          </>
        )}
      </div>
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className={`${styles.steps_content}`}>{steps[current].content}</div>
      {/* <section className={`${styles.steps_action}`}>
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Siguiente
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Salir
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
      </section> */}
    </>
  );
};

export default StepsFreeExamns;
