import React, { Fragment, useState, useRef } from 'react';
import { Modal } from 'antd';
import 'antd/es/modal/style/index.css';
import styles from '../index.module.sass';

const ModalVideo = ({ modal, setModal, videoInfo }) => {

  const player = useRef();
  const [ready, setReady] = useState(false);

  return (
    <Fragment>
      {console.log(videoInfo.url)}
      <Modal
        title={videoInfo.title}
        centered
        visible={modal}
        footer={false}
        //onOk={() => setModal(false)}
        onCancel={() => setModal(false)}
        width="70%"
      >
        <div className={styles.videocontainer}>
          <video
            ref={player}
            controls={ready}
            src={videoInfo.url}
            controlsList="nodownload"
            onLoadStart={() => setReady(true)}
          />
        </div>
        <div>
          <p className={styles.description_video}>Descripción: {videoInfo.description}</p>
        </div>
      </Modal>
    </Fragment>
  )
}
export default ModalVideo
