import ReactPlayer from 'react-player';
import styles from '../index.module.scss';

export const VideoBody = ({ video, setReady, onProgress, ready,player}) => {
  const { url } = video;
  return (
    <div className={styles.videoWrap}>
      <div className={styles.videocontainer} ref={player}>
        <ReactPlayer
          width="100%"
          height="100%"
          controls={ready}
          //  url={state.video.cheapUrl}
          url={url}
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          onLoadStart={() => setReady(true)}
          onProgress={(value) => onProgress(value)}
        />
      </div>
    </div>
  );
};
