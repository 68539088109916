/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import { context } from '../../../../context';
import { Button } from 'reactstrap';
// import { showMessageLoading, showMessageError, showMessageWarning, showNotification } from '../../../../components/MessageAndNotificationUtils';
import { newSchedules } from '../../../../utils/funciones'
import styles from './index.module.sass';

const RegisterCourseGroup = (props) => {
  const { state, actions } = useContext(context);
  
  useEffect(() => {
    actions.getGroupsByCourseId(props.courseId);
  }, []);

  const handleClick = () => {
    props.buttonGroups()
  }

  return <>
    <div className={styles.container}>
      <div className={styles.course_selector}>
        <span className={styles.courses}>Selecciona el grupo</span>
      </div>
      <div className={styles.course_list}>
        <div className={styles.list_element_center}>GRUPO</div>
        <div className={styles.list_element_center}>LUGARES</div>
      </div>
      {(state.availableGroups.map((group, index) => {
        if (group.activeLanding) {
          return (
            <div
              key={group._id}
              onClick={() => props.setchoosedGroup(group)}
              className={`${styles.course_list} ${(props.choosedGroup && props.choosedGroup._id === group._id && styles.active) || ""}`}>
              <div className={styles.list_element_left}>
                <p>{group.name || ''}</p>
                <span>{newSchedules(group.schedules)}</span>
                <br />
                <span>Inicio: {moment(group.startDate).format('DD/MM/YYYY')}</span>
                <br />
                <span>Sede: {group.classRoom && group.classRoom.location ? group.classRoom.location.name : "Sin asignar"}</span>
              </div>
              <div className={styles.list_element_center}></div>
              {/* {group.quota - group.stList.size} */}
            </div>
          );
        } else {
          return "";
        }
      }))}
      <div className={styles.container_button}>
        <Button disabled={!props.choosedGroup} className={styles.save_button} onClick={handleClick}>
          Inscribir
          </Button>
      </div>
    </div>
  </>
};

export default RegisterCourseGroup;
