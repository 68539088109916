import React from "react"
import { Empty } from 'antd';
import 'antd/lib/empty/style/index.css';

const EmptyComponent = (props) => {
    let { message,image } = props
    return (
        <Empty image={image?image:Empty.PRESENTED_IMAGE_SIMPLE} description={message} />
    )
}

export default EmptyComponent



