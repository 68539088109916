import React, { useContext, useEffect } from 'react';
import openSocket from 'socket.io-client';
import { Link } from 'react-router-dom';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import { showNotification } from '../../../components/MessageAndNotificationUtils';
import { context } from '../../../context'

const urlSocket = "https://api.preparacionipn.mx"

const Topbar = () => {
  const { state, actions } = useContext(context);

  useEffect(() => {
    // const socket = openSocket(urlSocket, { secure: true });
    // socket.emit('join', 'students_online');
    // socket.on('notification', updateSocketRecive)
    // socket.on('disconnect', function () { console.log('disconnected') });
    // // rejoin if there's a disconnect
    // socket.on('reconnect', () => {
    //   socket.emit('join', 'students_online')
    // })
    // //this will run on exit or unmount
    // return () => {
    //   socket.off('students_online')
    //   socket.disconnect()
    // };
  }, [])

  const updateSocketRecive = (data) => {
    if (data.time !== undefined && typeof data.time == 'number')
      showNotification("info", data.message, "", data.time)
    else
      showNotification("info", data.message, "")
  }

  const changeVisible = () => {
    actions.changeSideBar(!state.sideBarOpen)
  }


  return (
    <div className="topbar">
      <div className="topbar__wrapper">
        <div className="topbar__left">
          <div className={false ? "side_bar_space collapsed" : "side_bar_space"} />
          <TopbarSidebarButton
            changeMobileSidebarVisibility={changeVisible}
            changeSidebarVisibility={changeVisible}
            sidebarCollapse={false}
          />
        </div>
        <div className="topbar__center">
          <Link className="topbar__logo" to="/dashboard_default" />
        </div>
        <div className="topbar__right">
          <TopbarProfile />
        </div>
      </div>
    </div>
  );

}

export default Topbar;
