import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { context } from '../../context';
import Icon from '../icons';
import Logo from '../../assets/logo.png';
import styles from './header.sass';
import { Container, Row, Col } from 'reactstrap';

const Header = ({ backChapter, idParent = "", type = 'default', route = '' }) => {

  const { device } = useContext(context);

  if (type === "playlist" && device.screenSize !== 'desktop') {
    return (
      <header className={styles.home_header_mobile}>
        <Container>
          <Row>
            <Col xs="4">
              <Link to={route}>
                <Icon name="left" className={styles.arrow_left} />
              </Link>
            </Col>
            <Col xs="4">
              <Link to="/" className={styles.back_page}>
                <Icon name="home" className={styles.arrow_left} />
              </Link>
            </Col>
          </Row>
        </Container>
      </header>
    );
  }

  if (type === 'home' && device.screenSize !== 'desktop') {
    return (
      <header className={styles.home_header_mobile}>
        <p>
          Preparación <b>Politécnico</b>
        </p>
        <Link to={`/profile`} className={styles.account}>
          {(localStorage['fbPicture'] && (
            <img src={localStorage['fbPicture']} alt={localStorage['fbPicture']} />
          )) || <Icon name="account" />}
        </Link>
      </header>
    );
  }

  if (device.screenSize === 'desktop') {
    return (
      <header className={styles.header_desktop}>
        <img src={Logo} alt="Politécnico" />
        <p>
          <Link to="/home">
            Preparación <b>Politécnico</b>
          </Link>
        </p>
      </header>
    );
  }

  return (
    <header className={styles.home_header_mobile}>
      <Container>
        <Row>
          <Col xs="4" className={idParent !== "" ? "" : styles.hide_back}>
            <p className={styles.back_page}
              onClick={() => backChapter()}
            >
              <Icon name="left" className={styles.arrow_left} />
            </p>
          </Col>
          <Col xs="4">
            <Link to="/" className={styles.back_page}>
              <Icon name="home" className={styles.arrow_left} />
            </Link>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
