import React, { useContext } from 'react';
import { SwitchTransition, CSSTransition } from "react-transition-group";
import classNames from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SidebarContent from './SidebarContent';
import SideBarQuiz from '../../../components/sidebar-playlist/SideBarQuiz'
import SideBarPlayList from '../../../components/sidebar-playlist/index.jsx'
import { context } from '../../../context'

const Sidebar = (props) => {
  const { state, actions } = useContext(context);

  const history = useHistory()

  let matchPlaylist = useRouteMatch("/playlist/*");
  let matchCoursePlaylist = useRouteMatch("/coursePlaylist/:courseId");
  let matchQuiz = useRouteMatch("/quiz/*");
  let matchQuizResult = useRouteMatch("/quizResult/*");

  const sidebarClass = classNames({
    sidebar: true,
    'sidebar--show': state.sideBarOpen,
    'sidebar--collapse': false,
  });

  const changeVisible = () => {
    actions.changeSideBar(!state.sideBarOpen)
  }
  return (
    <div className={sidebarClass}>
      <button type="button" className="sidebar__back" onClick={changeVisible} />
      <div className="sidebar__wrapper sidebar__wrapper--mobile">
        <SwitchTransition mode={"out-in"}>
          <CSSTransition
            key={matchCoursePlaylist !== null || matchPlaylist !== null}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            timeout={{ enter: 500, exit: 500 }}
            classNames="fade">
            <div>
              {matchPlaylist || matchCoursePlaylist !== null ?
                <SideBarPlayList history={history} />
                :
                matchQuiz !== null || matchQuizResult !== null ? <SideBarQuiz history={history} goBack={props.goBack} />
                  : <SidebarContent
                    onClick={changeVisible}
                  />
              }
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default Sidebar;
