export const semanaDias = [];
semanaDias[0] = "Lunes";
semanaDias[1] = "Martes";
semanaDias[2] = "Miércoles";
semanaDias[3] = "Jueves";
semanaDias[4] = "Viernes";
semanaDias[5] = "Sábado";
semanaDias[6] = "Domingo";
//elimina los ids de los horarios
export const deleteIds = (array) => {
  array.forEach(element => {
    delete element._id
  });
  return array
}


//genera un nuevo array solo con las dos iniciales de cada dia en minuscula
export const twoLettersWeek = (schedules) => {

  schedules.forEach((horario) => {
    switch (horario.day.toString()) {
      case "0":
        horario.day = semanaDias[horario.day]
        break;

      case "1":
        horario.day = semanaDias[horario.day];
        break;

      case "2":
        horario.day = semanaDias[horario.day];
        break;

      case "3":
        horario.day = semanaDias[horario.day];
        break;

      case "4":
        horario.day = semanaDias[horario.day];
        break;
      case "5":

        horario.day = semanaDias[horario.day];
        break;

      case "6":
        horario.day = semanaDias[horario.day];
        break;
      default:
        break;
    }
  })

  return schedules

}

//agrega dos puntos a la hora 0930 =  09:30
const formatoHora = (hora) => {
  let uno = hora.slice(0, 2);
  let dos = hora.slice(2, 4);
  return uno + ":" + dos
}

//genera un formato para los schedules
export const newSchedules = (cell, row) => {
  let compiladoDias = "";

  let horarioFinal = []
  let horarios = twoLettersWeek(cell)

  //elimina los horarios duplicados
  const filteredArr = horarios.reduce((acc, current) => {
    const x = acc.find(item => item.startHour === current.startHour);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  //guarda los dias por horario
  filteredArr.forEach((dato) => {
    let grupoHorarios = []
    for (let i = 0; i < horarios.length; i++) {
      if (dato.startHour === horarios[i].startHour) {
        grupoHorarios.push(horarios[i])
      }
    }
    horarioFinal.push(grupoHorarios)
  })
  //genera un compilado de dias por horario
  horarioFinal.forEach((value) => {
    let horas = "Horario: " + formatoHora(value[0].startHour) + " a " + formatoHora(value[0].endHour)
    for (let i = 0; i < value.length; i++) {
      compiladoDias = compiladoDias + " " + value[i].day

    }
    compiladoDias = compiladoDias + " " + horas + "_"
  })
  let partes = compiladoDias.split('_')
  return partes.map((value) => value)
}

export const removeItemFromArray = (array, item) => {
  var index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
    return array
  }
  return array
}


export const isInArray = (array, item) => {
  var index = array.indexOf(item);
  if (index > -1) {
    return true
  }
  return false
}

//funciones pagos para pintar datos de  tarjetas//////
export const setTypeCard = (typeC) => {
  switch (typeC) {
    case "master":
      return "mastercard"
    case "visa":
      return "visa"
    case "amex":
      return "amex"
    default:
      return "";
  }
}

export const setNumberCard = (card) => {
  switch (card.payment_method_id) {
    case "master":
      return `************${card.last_four_digits}`
    case "visa":
      return `************${card.last_four_digits}`
    case "amex":
      return `***********${card.last_four_digits}`
    default:
      return "";
  }
}