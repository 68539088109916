import moment from 'moment';
import 'antd/dist/antd.css';

export const AuthorComent = ({commentData}) => {
  return (
    <p>
      {commentData.student !== undefined && commentData.student !== null
        ? `${commentData.student.name} ${commentData.student.lastName}`
        : 'Usuario no disponible'}
      <span className="ml-2">{moment(commentData.date).format('DD/MM/YYYY')}</span>
    </p>
  );
};
