import React from 'react';
import { Popconfirm } from 'antd';
import styles from './carousel_card.module.scss';

const CarouselCard = ({ name, type, progress, activeLiveStream, onClick, isSelected,isOpen,setIsOpen,isValid}) => {
    const condition =  isValid && isOpen
  return (
    <Popconfirm
      title={
        <div>
          <p>
            {name}
            <br />
            Este es el paquete básico, estas transmisiones 
            <br />
            están disponibles por tiempo limitado, si
            <br />
            quieres continuar viendo puedes cambiar al 
            <br />
            paquete premium por $1500, escríbenos.
          </p>
        </div>
      }
      description="Notification"
      showCancel={false}
      open={condition}
      onConfirm={()=>setIsOpen(false)}
      >

      <div className={styles.carousel_card} onClick={onClick}>
        {activeLiveStream ? <p class={styles.live}>Clase en vivo</p> : ''}
        <div className={`${styles.carousel_container} ${isSelected ? styles.selected : ''}`}>
          <div className={styles.course_info}>
            <p className={styles.title}>Curso</p>
            <p className={styles.text}>{name}</p>
            {progress !== undefined && 0 < progress < 100 ? (
              <div className={styles.progress_container}>
                <div className={styles.progress}></div>
                <span className={styles.progress_text}>Progreso</span>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </Popconfirm>
  );
};
export default CarouselCard;
