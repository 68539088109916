/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { context } from '../../context';
import useFormInput from '../../hooks/useFormInput';
import InputForm from '../../components/input-form';
import Logo from '../../assets/logo.png';
import { showNotification } from '../../components/MessageAndNotificationUtils';
import styles from './updatePassword.module.sass';

const UpdatePassword = (props) => {
  const query = new URLSearchParams(props.location.search)
  const { actions } = useContext(context);
  const email = useFormInput(query.get("email"));
  const provisionalPassword = useFormInput(query.get("provisionalPass"));
  const password = useFormInput('');
  const confimationPassword = useFormInput('');

  useEffect(() => {
    if(query.get("provisionalPass")==null || query.get("email")==null ){
      showNotification("warning","Hay un error con este enlace de recuperación","Este enlace presenta un error, intenta recuperar de nuevo tu contraseña para hacerte llegar a tu email un enlace nuevo",0)
    }
  }, [])

  // TODO: make form validations here!
  const changPassword = (e) => {
    e.preventDefault();
    if (
      !email.value.length ||
      !provisionalPassword.value.length ||
      !password.value.length ||
      !confimationPassword.value.length
    ) {
      return showNotification("warning", "¡Todos los campos son requeridos!", "", 0)

    }

    if (password.value !== confimationPassword.value)
      return showNotification("error", "Tus contraseñas no son iguales", "", 0)

    return actions
      .updatePassword({
        password: provisionalPassword.value.replace(/\s/g, ''),
        newPassword: password.value.replace(/\s/g, ''),
        email: email.value.replace(/\s/g, '')
      }).then((success) => {
        if (success) {
          return props.history.push('/home');
        } else {
          return showNotification("error", "Ocurrió un error al restaurar tu contraseña!", "", 0)
        }
      });
  };

  return (
    <section className={`${styles.phone || ''} ${styles.update}`}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={Logo} alt="logo principal" />
          <span>
            Preparación <b>Politécnico</b>
          </span>
        </div>
        <form className={styles.form} onSubmit={changPassword}>
          <InputForm disabled type="email" label="Correo electrónico" {...email} />
          <InputForm type="text" label="Nueva contraseña" {...password} />
          <InputForm type="text" label="Confirmar contraseña" {...confimationPassword} />
          <button className={styles.login_button}>Entrar</button>
          <Link className={styles.forgot_password} to="/login">
            Iniciar sesión
          </Link>
        </form>
      </div>
    </section>
  );
};

export default UpdatePassword;
