import React from 'react'
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Timeline } from 'antd';
import ButtonComponent from '../button'
import styles from './presential_course.module.scss'

const CourseCard = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Información del Curso:</h1>
            </div>
            <div className={styles.card}>
                <svg className={styles.icon} id="Guide" height="90" viewBox="0 0 512 512" width="90" xmlns="http://www.w3.org/2000/svg"><path d="m476 42.52h-440a26.029 26.029 0 0 0 -26 26v374.96a26.029 26.029 0 0 0 26 26h440a26.029 26.029 0 0 0 26-26v-374.96a26.029 26.029 0 0 0 -26-26zm14 26v176.137h-165.215v-190.137h151.215a14.015 14.015 0 0 1 14 14zm-325.431 176.137v-190.137h148.216v190.137zm-142.569 198.823v-374.96a14.015 14.015 0 0 1 14-14h116.569v402.96h-116.569a14.015 14.015 0 0 1 -14-14zm454 14h-311.431v-200.823h325.431v186.823a14.015 14.015 0 0 1 -14 14z" fill="rgb(0,0,0)" /></svg>
                <h2>{props.course.name}</h2>
            </div>
            <div className={styles.history}>
                <ul>
                    <li className={styles.title_li}>Información:</li>
                    <li>
                        Grupo: {props.course.group.name}
                    </li>
                    <li>
                        {props.course.group.schedules.map((date) => (
                            <span key={date._id}>
                                <br />
                                {props.days[date.day]}
                                {' de '}
                                {moment(date.startHour, 'HH:mm').format("hh:mm a")}
                                {' a '}
                                {moment(date.endHour, 'HH:mm').format("hh:mm a")}
                            </span>
                        ))}
                    </li>
                    <br />
                    <li className={styles.timeline}>
                        <Timeline>
                            <Timeline.Item color="green">Inicia: {props.course.group.startDate ? moment(props.course.group.startDate).format("ddd DD/MM/YYYY") : "No disponible"}</Timeline.Item>
                            <Timeline.Item color="red">Termina: {props.course.group.endDate ? moment(props.course.group.endDate).format("ddd DD/MM/YYYY") : "No disponible"}</Timeline.Item>
                        </Timeline>
                    </li>
                </ul>
                <ul>
                    <li className={styles.title_li}>Asistencia:</li>
                    <li>(BETA)</li>
                </ul>
            </div>
            {props.course.isActiveAppoiments ?
                <Link to={`/appoiments/${props.course.group._id}`}>
                    <ButtonComponent className={styles.button} theme="light">
                        Agendar asesoría
                    </ButtonComponent>
                </Link>
                : null}
        </div>

    )
}
export default CourseCard