import React from 'react';
import styles from './hamburgMenu.module.scss'

const HamburgMenu = () => {
  return (
    <div className={styles.button}>
      <span/>
      <span/>
      <span/>
    </div>
  );
};

export default HamburgMenu;
