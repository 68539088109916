import React, { useContext, useState } from 'react';
import { Row, Col } from 'reactstrap'
import moment from 'moment'
// import { Menu } from 'antd'
import Icon from '../../../../../components/icons'
import { context } from '../../../../../context';
import QRCode from 'qrcode.react'

import styles from './profileCard.module.scss';

const Ava = `${process.env.PUBLIC_URL}/assets/ava2.png`;

const ProfileCard = () => {
    const { state } = useContext(context);
    const [open, setOpen] = useState(false);
    // const closeDialog = () => {

    // }

    // const handleButtonClick = (e) => {
    //     console.log('click left button', e);
    // }

    // const menu = (
    //     <Menu onClick={handleButtonClick}>
    //         <Menu.Item key="1" onClick={() => closeDialog()}>
    //             Subir imagen
    //       </Menu.Item>
    //         <Menu.Item key="2" onClick={() => actions.closeSession()}>
    //             Cerrar sesión
    //       </Menu.Item>
    //     </Menu>
    // );

    return (
        <div className={styles.profile + " " + (open ? styles.open : "")}>
            <div className={styles.card}>
                <div className={styles.card_bio}>
                    <div className={styles.img_wrapper}>
                        <img src={Ava} alt="Person" />
                    </div>
                    <div className={styles.person_info}>
                        {state.student !== undefined && state.student.name !== undefined ? <h3>{`${state.student.name} ${state.student.lastName} ${state.student.secondLastName}`}</h3> : null}
                    </div>
                </div>
                <button className={styles.card_btn} onClick={() => setOpen(!open)}>
                    <div className={styles.container_content_button}>
                        <span className={styles.card_btn_more_info}>Más Información</span>

                    </div>
                    <div className={styles.container_content_button}>
                        <Icon name="arrowup" className={styles.icon_button} />
                    </div>

                </button>
            </div>
            <div className={styles.contentMoreInfo}>
                <div className={styles.label}>
                    <h4>Más Información</h4>
                </div>
                <Row className={styles.container_box_info}>
                    {/* <Col xs="6" className={styles.contentMoreInfo_info}>
                        <div className={styles.icon_wrapper}>
                            <Icon name="email" />
                        </div>
                        <div className={styles.contact_details}>
                            <p>{state.student.email}</p>
                        </div>
                    </Col>
                    <Col xs="6" className={styles.contentMoreInfo_info}>
                        <div className={styles.icon_wrapper}>
                            <Icon name="facebook" />
                        </div>
                        <div className={styles.contact_details}>
                            <p>Jane Brown</p>
                        </div>
                    </Col> */}
                    <Col xs="12" className={styles.contentMoreInfo_info}>
                        <div className={styles.profile_row}>
                            <Icon name="email" />
                            <label>{state.student.email}</label>
                        </div>
                        <div className={styles.profile_row}>
                            <Icon name="phone" />
                            <label>{state.student.phoneNumber}</label>
                        </div>
                        <div className={styles.profile_row}>
                            <Icon name="cake" />
                            <label>{moment(state.student.birthDate).format('L')}</label>
                        </div>
                        <div className={styles.profile_row}>
                            <Icon name="home" />
                            <label>{state.student.address || 'No disponible'}</label>
                        </div>
                        <div className={styles.profile_row}>
                            <Icon name="facebooklinear" />
                            <label>{state.student.fbUser || 'No disponible'}</label>
                        </div>
                    </Col>
                    <Col xs="12" className={styles.contentMoreInfo_info + " " + styles.container_qr}>
                        {state.student !== undefined && state.student._id !== undefined ? <QRCode value={state.student._id} level="Q" className={styles.qr} /> : null}
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export default ProfileCard