import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ImageComponent from '../../../components/imageVideos/ImageComponent';
import Icon from '../../icons'
import ModalVideo from './ModalVideo';
import ShowTypesQuestions from './Question';
import ShowAnswer from './answer';
import { Divider } from 'antd';
import styles from '../index.module.sass';
import 'antd/es/divider/style/index.css';
import 'antd/es/icon/style/index.css';

const QuestionRes = ({ questionInfo, questionNum }) => {

  const [modal, setModal] = useState(false);
  const [videoInfo, setVideoInfo] = useState({});

  //muestra las respuestas del usuario
  const userAnswers = () => {
    let arrayUserAnsw = []

    for (let a = 0; a < questionInfo.arrayUserAnswers.length; a++) {
      const selectedAnswer = questionInfo.answerCollection.find(x => x.idAnswer === questionInfo.arrayUserAnswers[a])
      arrayUserAnsw.push(selectedAnswer)
    }
    return arrayUserAnsw
  }

  const openModal = (dataVideo) => {
    setModal(true)
    setVideoInfo(dataVideo)
  }

  return (
    <Container>
      <Row>
        <Col xs="12">
          <div className={styles.question_text_result}>
            {ShowTypesQuestions(questionInfo, questionNum)}
          </div>
          <Row>
            <Col xs="6" className={styles.answers_container}>
              <Divider orientation="left">Respuestas</Divider>
              {
                questionInfo.answerCollection.map((answer, index) => {
                  return (
                    <ShowAnswer answer={answer} key={index}/>
                  )
                })
              }
            </Col>
            <Col xs="5">
              <Divider orientation="left">Tus respuestas</Divider>
              {
                userAnswers().map((answer, index) => {
                  return (
                    <ShowAnswer answer={answer}  key={index}/>
                  )
                })
              }
            </Col>
            <Col xs="12" className={styles.answers_container}>
              <Divider orientation="left">Explicación</Divider>
              {
                questionInfo.explanationVideo.map((data, index) => {
                  return (
                    <div id={data._id} key={index} className={styles.container_video}
                      onClick={() => openModal(data)}
                    >
                      <ImageComponent url={data.url} urlThumbnail={data.urlThumbnail} />
                      <div className={styles.middle}>
                        <Icon name="play" style={{ color: "white", fontSize: "40px" }} />
                      </div>
                    </div>)
                })
              }
            </Col>
          </Row>
        </Col>
      </Row>
      {modal ?
        <ModalVideo
          modal={modal}
          setModal={setModal}
          videoInfo={videoInfo} />
        : null
      }
    </Container>
  );
};
export default QuestionRes