/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import Scrollbar from 'react-smooth-scrollbar';
import { Link } from 'react-router-dom';
import Icon from '../../../components/icons';
import { context } from '../../../context';
import ProfileCard from './components/ProfileCard'
import styles from './sidebar.module.scss';


const SidebarContent = () => {

  const { state, actions } = useContext(context);
  //const [modal, setModal] = useState(false);
  // const [modalPdf, setModalPdf] = useState(false);

  useEffect(() => {
    actions.getSidebarData();
  }, []);


  const attendance =
    ((state.student.attendance.total - state.student.attendance.absences) /
      state.student.attendance.total) *
    100;


  // const closeShowMore = () => {
  //   setCompletedProfile(!completedProfile)
  //   setModalPdf(true)
  // }


  return (
    <div className="sidebar__content">
      <aside className={styles.aside}>
        <div style={{ height: "200px" }} />
        <Scrollbar className="sidebar__scroll scroll">
          <div className={styles.data}>
            <Link to="/home">
              <div className={styles.box_data}>
                <p><Icon name="home" className={styles.icon_pencil} /></p>
                <p>Inicio</p>
              </div>
            </Link>
            <p className={styles.title}>Escolares</p>
            <Link to="/school-data">
              <div className={styles.box_data}>
                <p>
                  <span>{state.schoolData.courses.length || 0}</span>
                </p>
                <p>
                  {(state.schoolData.courses.length === 1 && 'Curso inscrito') ||
                    'Cursos inscritos'}
                </p>
              </div>
            </Link>
            <p className={styles.title}>Financieros</p>
            <Link to="/financial">
              <div className={styles.box_data_financial}>
                {/* <div className={styles.box_data_block}>
                  <p>
                    <span>${state.student.financial.payments.total || 0}</span>
                  </p>
                  <p>Total</p>
                </div> */}
                {/* <div className={styles.box_data_block}>
                  <p>
                    <span>${state.student.financial.payments.discounts || 0}</span>
                  </p>
                  <p>Descuentos</p>
                </div> */}
                {state.student.financial.costs !== undefined && (state.student.financial.payments.total+state.student.financial.payments.discounts) < state.student.financial.costs.total && (
                  <div className={styles.box_data_financial_pending}>
                    <Icon name="warning" />
                    <span>
                      ${state.student.financial.costs.total - (state.student.financial.payments.total+state.student.financial.payments.discounts) || 0} de adeudo pendiente
                        </span>
                  </div>
                )}
              </div>
            </Link>
            <p className={styles.title}>Asistencia</p>
            <Link to="/attendance">
              <div className={styles.box_data}>
                <p>
                  <span>{state.student.attendance.absences || '-'}</span>
                </p>
                <p>Faltas</p>
              </div>
            </Link>
            <div className={styles.attendance}>
              <div className={styles.bar}>
                <div className={styles.progress} style={{ width: `${attendance || 0}%` }} />
              </div>
              <p>{`${Math.round(attendance || 0)} % asistencia`}</p>
            </div>
            {/* <ImgModal
        modal={modal}
        setModal={setModal}
        title={"Subir imagen de perfil"}
      /> */}
            {/* <PdfModal
        modal={modalPdf}
        setModal={setModalPdf}
        title={"Descargar pdf QR"}
      /> */}
          </div>

        </Scrollbar>
        <ProfileCard />
      </aside>
    </div>
  )
}

export default SidebarContent;
