/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import QuizResults from '../../components/quizInfo/quizResults';
import { Container, Row, Col, Button } from 'reactstrap';
import { CloseCircleOutlined } from '@ant-design/icons';
import Spinner from '../../components/spinner';
import { context } from '../../context';
import Confetti from 'react-dom-confetti';
import styles from './index.module.sass';

const config = {
  angle: '90',
  spread: '40',
  startVelocity: '70',
  elementCount: '80',
  dragFriction: '0.09',
  duration: '7100',
  stagger: '1',
  width: '15px',
  height: '15px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

const EndQuiz = (props) => {
  const { state, actions } = useContext(context);
  const [showResultsQuiz, setShowResultsQuiz] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confeti, setConfeti] = useState(false);
  const quizEnd = `${process.env.PUBLIC_URL}/assets/quiz/endQuiz2x.png`;
  const quizEndBackGround = `${process.env.PUBLIC_URL}/assets/quiz/backGroundend3x.png`;
  const examSucces = state.latsQuizActive.percentageResult >= 60;

  useEffect(() => {
    initScreen();
    if (examSucces) {
      setTimeout(function () {
        setConfeti(true);
        setTimeout(() => {
          setConfeti(false);
        }, 3000);
      }, 700);
    }
  }, [state.latsQuizActive]);

  const initScreen = async () => {
    if (state.quiz === undefined) {
      await actions.getQuizInfoById(props.match.params.idQuiz);
    }
    if (state.latsQuizActive === undefined) {
      await actions.getStudentQuizAttempById(props.match.params.idAttempt);
    } else {
      setLoading(false);
    }
  };

  const showResult = async () => {
    let result = actions.getAnsweredExam(state.latsQuizActive._id);
    result.then((result) => {
      setShowResultsQuiz(true);
    });
  };

  const getResultQuiz = () => {
    if (state.latsQuizActive === null || state.latsQuizActive.rightQuestions === undefined) {
      let result = 0;
      state.latsQuizActive.questionCollection.forEach((data, index) => {
        //sacamos el numero de respuestas correctas por pregunta
        let trueAnswersNum = multipleOptions(index);
        if (trueAnswersNum === 1) {
          for (let a = 0; a < data.answerCollection.length; a++) {
            if (data.answerCollection[a].idAnswer === data.arrayUserAnswers[0]) {
              if (data.answerCollection[a].validAnswer) {
                result = result + 1;
              }
            }
          }
        } else {
          for (let i = 0; i < data.arrayUserAnswers.length; i++) {
            let multipleTrue = true;
            data.answerCollection.forEach((answer) => {
              if (answer.idAnswer === data.arrayUserAnswers[i]) {
                multipleTrue = answer.validAnswer;
              }
            });
            if (multipleTrue && i === trueAnswersNum - 1) result = result + 1;
          }
        }
      });
      return result;
    } else {
      return state.latsQuizActive.rightQuestions;
    }
  };

  //buscamos en cada pregunta si esta tiene mas de una respuesta
  const multipleOptions = (index) => {
    let validAnswerNumber = 0;
    state.latsQuizActive.questionCollection[index].answerCollection.forEach((data) => {
      if (data.validAnswer) {
        validAnswerNumber = validAnswerNumber + 1;
      }
    });
    return validAnswerNumber;
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : showResultsQuiz ? (
        <QuizResults onClickBack={() => setShowResultsQuiz(false)} />
      ) : (
        <Container>
          <Row className="text-center mb-5">
            <Col style={{ backgroundImage: `url(${quizEndBackGround})` }}>
              <p className={styles.title_end_quiz}>Evaluación guardada exitosamente</p>
              <h3>{state.quiz.name}</h3>
              {examSucces ? (
                <img src={quizEnd} alt="quizImage" />
              ) : (
                <CloseCircleOutlined style={{ fontSize: '120px', color: 'red' }} />
              )}
              <Row>
                <Col xs="12" className={styles.style_text_result}>
                  <p className="mt-3">Resultado</p>
                  <p>
                    {getResultQuiz() !== 1
                      ? `${getResultQuiz()} respuestas correctas de ${
                          state.latsQuizActive.questionCollection.length
                        } `
                      : `${getResultQuiz()} respuesta correcta de ${
                          state.latsQuizActive.questionCollection.length
                        }`}
                  </p>
                </Col>
                <Col sm={{ size: 6, offset: 3 }}>
                  <Button color="primary" size="lg" block onClick={showResult}>
                    Repasar preguntas
                  </Button>
                </Col>
                <Col sm={{ offset: 6 }}>
                  <Confetti active={confeti} config={config} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
export default EndQuiz;
