import React, { useState, useContext } from 'react';
// import FacebookLogin from 'react-facebook-login';
import { context } from '../../context';
import fetcher from '../../utils/fetcher';
import useFormInput from '../../hooks/useFormInput';
import Logo from '../../assets/logo.png';
import Icon from '../../components/icons';
import InputForm from '../../components/input-form';
import { showNotification } from '../../components/MessageAndNotificationUtils';
import styles from './login.module.sass';

const textRecover = "Se ha enviado un correo con un link en el cual podrás recuperar tu contraseña, no olvides revisar tu papelera de reciclaje o el spam en caso de no encontrar el correo en tu bandeja de entrada."

const Login = () => {
  const { actions } = useContext(context);

  // const [error, setError] = useState('');
  const [authType, setAuthType] = useState('LOGIN');
  const [keepSession, setKeepSession] = useState(false);
  const [userRecovery, setUserRecovery] = useState(false);

  const email = useFormInput('');
  const password = useFormInput('');

  const handleAuthenticate = (e) => {
    e.preventDefault();
    actions
      .login({ keepSession, email: email.value.replace(/\s/g, ''), password: password.value.replace(/\s/g, '') })
      .then((response) => !response.success && showError(response.code));
    // .then((response) => !response.success && setError(response.error));
  };

  const showError = (code) => {
    console.log(code)
    if (code === 401) {
      let _401 = `Vuelve a intentarlo o haz clic en "Recuperar mi contraseña" para restablecerla.`
      return showNotification("error", "La contraseña es incorrecta.", _401, 0)
    } else if (code === 404) {
      let _404 = `Asegúrate de que tu correo este bien escrito`
      return showNotification("info", "Usuario no encontrado.", _404, 0)
    }
  }

  const requestProvisionalPassword = (e) => {
    e.preventDefault();
    fetcher('/api/st/pass/recover', {
      method: 'PUT',
      body: JSON.stringify({ email: email.value })
    }).then(({ success, error }) => {
      if (success) {
        showNotification("info", "Se ha enviado un correo con las instrucciones de recuperación.", textRecover, 0)
        setUserRecovery(!userRecovery)
        //history.push(`/update-password?email=${email.value}`);
      } else {
        //alert(error);
        showNotification("error", error, "", 0)
      }
    });
  };

  return (
    <section className={`${styles.login}`}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={Logo} alt="logo principal" />
          <span>
            Preparación <b>Politécnico</b>
          </span>
        </div>
        {/* <div className={styles.facebook}>
          <FacebookLogin
            size="medium"
            cookie={true}
            scope="public_profile"
            fields="name,email,picture"
            callback={actions.loginFacebook}
            textButton="INICIAR SESIÓN CON FACEBOOK"
            redirectUri={window.location.href}
            appId={process.env.REACT_APP_FB_APP_ID}
            icon={<Icon name="facebook" className={styles.facebook_icon} />}
          />
        </div> */}
        {(userRecovery && (
          <form className={styles.form} onSubmit={requestProvisionalPassword}>
            <InputForm type="email" label="Tu correo electrónico" {...email} />
            <button className={styles.login_button}>Recuperar</button>
          </form>
        )) || (
            <form className={styles.form} onSubmit={handleAuthenticate}>
              <div className={styles.login_type}>
                <span
                  onClick={() => setAuthType('LOGIN')}
                  className={(authType === 'LOGIN' && styles.active) || ''}>
                  Inicia Sesión
              </span>
                <span
                  onClick={() => setAuthType('REGISTER')}
                  className={(authType === 'REGISTER' && styles.active) || ''}>
                  Regístrate
              </span>
              </div>
              <InputForm type="email" label="Correo electrónico" {...email} />
              <InputForm type="password" label="Contraseña" {...password} />
              <div className={styles.keep_session} onClick={() => setKeepSession(!keepSession)}>
                <Icon name={(keepSession && 'checked') || 'unchecked'} />
                <label>Recordarme</label>
              </div>
              <button className={styles.login_button}>Entrar</button>
            </form>
          )}
        <span className={styles.forgot_password} onClick={() => setUserRecovery(!userRecovery)}>
          {(userRecovery && 'Regresar') || 'Olvide mi contraseña'}
        </span>
      </div>
    </section>
  );
};

export default Login;
