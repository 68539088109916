import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { context } from '../../context';
import useFormInput from '../../hooks/useFormInput';
import Logo from '../../assets/logo.png';
import InputForm from '../../components/input-form';
import Spinner from '../../components/spinner';
import { showMessageWarning, showNotification } from '../../components/MessageAndNotificationUtils';
import styles from './index.module.sass';
// const URL_TO_REDIRECT = "/playlist/60b67b1a5379e3505dc1db99/quiz/60b766e25379e3505dc228fc" //COMIPEMS EXAM
// const URL_TO_REDIRECT = "/playlist/60bfbe0d6b62995b7f512129/quiz/60bfbe390a88ce5bad6bb3bc" //COMIPEMS EXAM
// const URL_TO_REDIRECT = "playlist/60fb42949069fb5bafed48ce/quiz/60fb44deab6e6d5b86573ec8" //Superior Last
const URL_TO_REDIRECT = "playlist/610497b7d8e5755b7b47c016/quiz/6104985bc3d1975b858d971c" //Superior Last Last

const Login = ({ isInternalStudent = false }) => {
  const { actions } = useContext(context);

  const history = useHistory()

  const name = useFormInput('');
  const lastName = useFormInput('');
  const motherLastName = useFormInput('');
  const phoneNumber = useFormInput('');
  const email = useFormInput('');
  const password = useFormInput('');

  useEffect(() => {
    if (isInternalStudent) {
      console.log("Is internal")
      actions.verifyInternalStudent()
        .then((response) => {
          if (!response.success) {
            showError(response.code)
          } else {
            history.push(
              { pathname: URL_TO_REDIRECT }
            )
          }
        });
    }
  }, [])

  const handleAuthenticate = (e) => {
    e.preventDefault();
    console.log("NAME", name.value.length)
    if (name.value.length <= 0) {
      showMessageWarning("Debes ingresar un nombre", 4)
      return
    }
    if (lastName.value.length <= 0) {
      showMessageWarning("Debes ingresar un apellido paterno", 4)
      return
    } if (motherLastName.value.length <= 0) {
      showMessageWarning("Debes ingresar un apellido materno", 4)
      return
    } if (email.value.length <= 0) {
      showMessageWarning("Debes ingresar un email", 4)
      return
    } if (phoneNumber.value.length < 10) {
      showMessageWarning("El numero de telefono debe de ser de 10 digitos", 4)
      return
    } if (password.value.length <= 0) {
      showMessageWarning("Debes ingresar una contraseña", 4)
      return
    }
    const body = {
      name: name.value.replace(/\s/g, ''),
      lastName: lastName.value.replace(/\s/g, ''),
      secondLastName: motherLastName.value.replace(/\s/g, ''),
      phoneNumber: phoneNumber.value.replace(/\s/g, ''),
      email: email.value.replace(/\s/g, ''),
      password: password.value.replace(/\s/g, ''),
    }
    actions.registerFreeExam(body)
      .then((response) => {
        if (!response.success) {
          showError(response.code)
        } else {
          history.push(
            {
              pathname: URL_TO_REDIRECT,
            }
          )
        }
      });
  };

  const showError = (code) => {
    console.log(code)
    if (code === 400) {
      return showNotification("error", "Ya estás registrado con este correo, por favor inicia sesión con tu correo y contaseña ", "", 0)
    } else if (code === 404) {
      let _404 = `¡Asegurate de que los datos estén bien escritos!`
      return showNotification("info", "Error de validación", _404, 0)
    }
  }

  return (
    <>
      {isInternalStudent ? <Spinner /> :
        <section className={`${styles.login}`}>
          <div className={styles.container}>
            <div className={styles.logo}>
              <img src={Logo} alt="logo principal" />
              <span>
                Preparación <b>Politécnico</b>
              </span>
            </div>
            <div className={styles.form} >
              <h5>Si eres nuevo y NO has tomado ningún examen o NO estás inscrito en alguno de nuestros cursos por favor regístrate.</h5>
              <h5>En caso contrario selecciona iniciar sesión (si no recuerdas tu contraseña ahí la podrás recuperar)</h5>
            </div>
            <form className={styles.form} onSubmit={handleAuthenticate}>
              <InputForm type="text" label="Nombre(s)" {...name} />
              <InputForm type="text" label="Apellido paterno" {...lastName} />
              <InputForm type="text" label="Apellido materno" {...motherLastName} />
              <InputForm type="email" label="Correo electrónico" {...email} />
              <InputForm type="phone" label="Telefono de contacto" {...phoneNumber} />
              <InputForm type="password" label="Crea una contraseña" {...password} />
              {/* <p className={styles.error_form}>{(!!error.length && error) || ''}</p> */}
              <button className={styles.login_button}>Registrarme</button>
              <Link to="/login">
                <span className={styles.forgot_password}>
                  Iniciar Sesión
              </span>
              </Link>
            </form>
          </div>

        </section>
      }
    </>
  );
};

export default Login;
