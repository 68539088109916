import React, { useContext } from 'react';
// import FacebookLogin from 'react-facebook-login';
import { context } from '../../context';
// import fetcher from '../../utils/fetcher';
import useFormInput from '../../hooks/useFormInput';
import Logo from '../../assets/logo.png';
// import Icon from '../../components/icons';
import InputForm from '../../components/input-form';
import { showMessageWarning, showNotification } from '../../components/MessageAndNotificationUtils';
import styles from './index.module.sass';

const Login = ({ history,groupId }) => {
  const { actions } = useContext(context);

  const name = useFormInput('');
  const lastName = useFormInput('');
  const motherLastName = useFormInput('');
  const phoneNumber = useFormInput('');
  const email = useFormInput('');
  const password = useFormInput('');

  const handleAuthenticate = (e) => {
    e.preventDefault();
    console.log("NAME", name.value.length)
    if (name.value.length <= 0) {
      showMessageWarning("Debes ingresar un nombre", 4)
      return
    }
    if (lastName.value.length <= 0) {
      showMessageWarning("Debes ingresar un apellido paterno", 4)
      return
    } if (motherLastName.value.length <= 0) {
      showMessageWarning("Debes ingresar un apellido materno", 4)
      return
    } if (email.value.length <= 0) {
      showMessageWarning("Debes ingresar un email", 4)
      return
    } if (phoneNumber.value.length < 10) {
      showMessageWarning("El numero de telefono debe de ser de 10 digitos", 4)
      return
    } if (password.value.length <= 0) {
      showMessageWarning("Debes ingresar una contraseña", 4)
      return
    }
    const body = {
      name: name.value.replace(/\s/g, ''),
      lastName: lastName.value.replace(/\s/g, ''),
      secondLastName: motherLastName.value.replace(/\s/g, ''),
      phoneNumber: phoneNumber.value.replace(/\s/g, ''),
      email: email.value.replace(/\s/g, ''),
      password: password.value.replace(/\s/g, ''),
      allowed: false,
      groupId: groupId, // "comipems" o "superior"
    }
    actions.registerFreeLesson(body)
      .then((response) => {
        if (!response.success) {
          showError(response.code)
        }
      });
  };

  const showError = (code) => {
    console.log(code)
    if (code === 400) {
      return showNotification("error", "Ya estás registrado con este correo, por favor inicia sesión con tu correo y contaseña ", "", 0)
    } else if (code === 404) {
      let _404 = `¡Asegurate de que los datos estén bien escritos!`
      return showNotification("info", "Error de validación", _404, 0)
    }
  }

  return (
    <section className={`${styles.login}`}>
      <div className={styles.container}>
        <div className={styles.logo}>
          <img src={Logo} alt="logo principal" />
          <span>
            Preparación <b>Politécnico</b>
          </span>
        </div>
        <div className={styles.facebook}>
          <span>Registro para clase gratis</span>
        </div>
        <form className={styles.form} onSubmit={handleAuthenticate}>
          <InputForm type="text" label="Nombre(s)" {...name} />
          <InputForm type="text" label="Apellido paterno" {...lastName} />
          <InputForm type="text" label="Apellido materno" {...motherLastName} />
          <InputForm type="email" label="Correo electrónico" {...email} />
          <InputForm type="phone" label="Telefono de contacto" {...phoneNumber} />
          <InputForm type="password" label="Crea una contraseña" {...password} />
          {/* <p className={styles.error_form}>{(!!error.length && error) || ''}</p> */}
          <button className={styles.login_button}>Registrarme</button>
        </form>
      </div>
    </section>
  );
};

export default Login;
