import React, { useState } from 'react';
import { Col, Card, CardBody, Row, ButtonToolbar, Button, Container } from 'reactstrap';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import { Radio } from 'antd';
import 'antd/es/radio/style/index.css'
import styles from './index.module.sass'


const ModalDataConfirm = ({ value, data, close, onConfirm, disabled }) => {
    const [moduleInput, setModuleInput] = useState()

    const onCloseModal = () => {
        close(false)
    }

    return (
        <Modal
            open={value}
            onClose={onCloseModal}
            center={true}
            closeOnEsc={false}
            showCloseIcon={false}
            closeOnOverlayClick={false}>
            <Container>
                <Row className="d-flex flex-row">
                    <Col sm={12} md={6}>
                        <div className={styles.card__title}>
                            <h4 className="bold-text">Confirma tu selección</h4>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <ButtonToolbar>
                            <Button
                                onClick={onCloseModal} className={styles.btn_secondary}>
                                Corregir
                            </Button>
                            <Button color="primary" style={{ backgroundColor: "#4170cd" }}
                                disabled={disabled || (data.modules && data.modules.length > 0 && !moduleInput)}
                                onClick={() => onConfirm(moment(data.date).format('DD-MM-YYYY'), data.hour, moduleInput)}>
                                Continuar
                            </Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
                <Col md={12} xl={12} sm={12} className="mt-2">
                    <Card className={styles.pricing_card + ' ' + styles.pricing_card_warning}>
                        <CardBody className={styles.pricing_card__body}>
                            <h5 className="mt-3"><b>Asesoría</b></h5>
                            {data.modules && data.modules.length > 0 ?
                                <div>
                                    <p>Selecciona el módulo del que deseas tu asesoría</p>
                                    <Radio.Group onChange={e => setModuleInput(e.target.value)} value={moduleInput} buttonStyle='solid'>
                                        {data.modules.map(value =>
                                            <Radio.Button value={value._id}>
                                                {value.name}
                                            </Radio.Button>
                                        )}
                                    </Radio.Group>
                                </div> : null
                            }
                            <h5 className="mt-1">Fecha - <b>{moment(data.date).format('DD-MM-YYYY')}</b></h5>
                            <h5 className="mt-1">Horario - <b>{moment(data.hour, ["HH"]).format("hh A")}</b></h5>
                        </CardBody>
                    </Card>
                </Col>
            </Container>
        </Modal >
    )
}
export default ModalDataConfirm;
