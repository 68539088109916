import React from 'react';
import FormQuizMobile from './FormQuizMobile'

const Quiz = (props) => {
    return (
        <div>   
            <FormQuizMobile params={props.examType ? props :props.match.params} />
        </div>
    )
}
export default Quiz