import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MathfieldComponent } from 'react-mathlive';
// import ReactImageZoom from 'react-image-zoom';
import 'mathlive/dist/mathlive-static.css'
import styles from './mobile.module.scss';

const AnswerMobile = ({ answer, selectedAnswer, styleSelectedAnswer,
  multipleAnswers = 0, index }) => {

  const alphabet = [...'abcdefghijklmnopqrstuvwxyz']

  const showAnswer = () => {
    if (answer.hasOwnProperty("answerType")) {
      switch (answer.answerType) {
        case 2:
          return (
            <div className={styles.container_answer}>
              <div className={styleSelectedAnswer(answer.idAnswer) ?
                (multipleAnswers === 1) ? styles.answer_cont_selected_one_answer : styles.answer_cont_selected
                : styles.answer_cont}
                onClick={() => selectedAnswer(answer.idAnswer)}
              >
                <Container>
                  <Row>
                    <Col xs="1" className={styles.letter_col}>
                      <p>{alphabet[index].toUpperCase()}</p>
                    </Col>
                    <Col xs="10" className={styles.img_answer_box} >
                      <img className={styles.img_quiz_answers} src={answer.answerImage.url} alt="img" />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )
        case 3:
          return (
            <div className={styles.container_answer}>
              <div className={styleSelectedAnswer(answer.idAnswer) ?
                (multipleAnswers === 1) ? styles.answer_cont_selected_one_answer : styles.answer_cont_selected
                : styles.answer_cont}
                onClick={() => selectedAnswer(answer.idAnswer)}
              >
                <Container>
                  <Row>
                    <Col xs="1" className={styles.letter_col_text}>
                      <p>{alphabet[index].toUpperCase()}</p>
                    </Col>
                    <Col xs="10">
                      <div className={styles.question_formula_scroll}>
                        <div className={styles.wrapper_question_formula}>
                          <MathfieldComponent
                            latex={answer.answerFormula}
                            mathFieldConfig={{
                              readOnly: true
                            }} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )
        default:
          return "";
      }
    } else {
      return (
        <div className={styles.container_answer}>
          <div className={styleSelectedAnswer(answer.idAnswer) ?
            (multipleAnswers === 1) ? styles.answer_cont_selected_one_answer : styles.answer_cont_selected
            : styles.answer_cont}
            onClick={() => selectedAnswer(answer.idAnswer)}
          >
            <Container>
              <Row>
                <Col xs="1" className={styles.letter_col_text}>
                  <p>{alphabet[index].toUpperCase()}</p>
                </Col>
                <Col xs="10" className={styles.simple_text_answer}>
                  {answer.content}
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }

  return showAnswer()
};
export default AnswerMobile