import React, { useState, useContext, Fragment } from 'react';
import moment from 'moment'
import { Link } from 'react-router-dom';
import ButtonComponent from '../../components/button'
import Icon from '../../components/icons';
import { context } from '../../context';
import formatHour from '../../utils/formatHour';
import { Row, Col, Container } from 'reactstrap';
import styles from './index.module.sass';

//datos provisionales para pintar examanes recordar borrar
const examenes = [
  {
    name: "Evaluacion 0",
    date: "",
    score: ""
  }
]

const SchoolData = () => {
  const { state } = useContext(context);
  const [isOpen, setIsOpen] = useState("");
  const [status, setStatus] = useState(false);


  // useEffect(() => {
  //   actions.getSchoolData();
  // }, []);

  //activa o desactiva el collapse 
  const showExam = (e) => {

    if (e.currentTarget.id === isOpen) {
      setStatus(false)
      setIsOpen("")
    } else {
      setIsOpen(e.currentTarget.id)
      setStatus(true)
    }
  };

  const sortNumber = (a, b) => {
    return a - b;
  }

  //ordenamos y buscamos si los dias del horario son consecutivos
  const isSecuence = (schedule) => {
    let array = []
    schedule.map(data => array.push(data.day))
    let arrayOrder = [...array]
    arrayOrder = array.sort(sortNumber)
    let secuence = true
    arrayOrder.forEach((data, index) => {
      if (secuence) {
        let next = arrayOrder[index + 1]
        let result = next - data
        if (result > 1) {
          secuence = false
        } else {
          secuence = true
        }
      }
    })

    return secuence
  }

  //genera los dias y horarios de cada grupo
  const showSecuenceDays = (schedule) => {
    let array = []
    schedule.map(data => array.push(data.day))
    array = array.sort(sortNumber)

    if (isSecuence(schedule) && schedule.length > 1) {

      return (
        <Fragment>
          <p>Día(s): {`${state.days[array[0]]} a ${state.days[array[array.length - 1]]}`}</p>
          <p>Horario: {schedule[0] ? ` ${formatHour(schedule[0].startHour)} - ${formatHour(schedule[0].endHour)} hrs` : "No disponible"} </p>
        </Fragment>)

      //return `${state.days[array[0]]} a ${state.days[array[array.length - 1]]} 
      //de ${formatHour(schedule[0].startHour)} - ${formatHour(schedule[0].endHour)} `
    } else {
      let horarios = ''
      for (let i = 0; i < array.length; i++) {
        if (i === array.length - 1) {
          //horarios = horarios + `${state.days[array[i]]}  de ${formatHour(schedule[0].startHour)} - ${formatHour(schedule[0].endHour)}`
          horarios = horarios + `${state.days[array[i]]}`
        } else {
          horarios = horarios + state.days[array[i]] + ", "
        }
      }
      return (
        <Fragment>
          <p>Día(s): {horarios}</p>
          <p>Horario: {schedule[0] ? ` ${formatHour(schedule[0].startHour)} - ${formatHour(schedule[0].endHour)} hrs` : "No disponible"} </p>
        </Fragment>)
    }
  }


  return (
    <>
      <div className={styles.actived_courses}>
        <Icon name="remove" />
        <span>{state.schoolData.courses.length} cursos activos</span>
        <div className={styles.button_container}>
          <Link to="/register">
            <ButtonComponent className={styles.button_container} theme="light">
              Inscribir Curso o Taller
              </ButtonComponent>
          </Link>
        </div>
      </div>
      {(state.schoolData.courses || []).map((course, index) => {
        return (
          <div key={index} className={styles.course_container}>
            <p>{course.name}</p>
            <div className={isOpen === index && status ? styles.course_detail : styles.course_detail_drop}
              id={index}
              onClick={showExam}
            >
              <div className={styles.group_name}>
                <p>Grupo: {course.group.name}</p>
              </div>
              <p className="mt-4">
                <p>Inicia: {course.group.startDate ? moment(course.group.startDate).format('DD/MM/YYYY') : "No disponible"}</p>
                {showSecuenceDays(course.group.schedules)}
              </p>
              <p>
                <span>Sede: {course.group.locationInfo ? course.group.locationInfo.name : "No disponible"}</span>
                <br />
                <span>Salón: {course.group.classRoomInfo ? course.group.classRoomInfo.name : "No disponible"}</span>
              </p>
              {course.isActiveAppoiments ?
                <p>
                  <div className={styles.appoiment_container}>
                    <Link to={`/appoiments/${course.group._id}`}>
                      <ButtonComponent className={styles.button_container} theme="light">
                        Agendar asesoría
                    </ButtonComponent>
                    </Link>

                  </div>
                </p>
                : null}
            </div>
            <div className={isOpen === index && status ? styles.container_exams : styles.hide_exams}
            >
              {
                examenes && examenes.length >= 0 ?
                  <Container key={index}>
                    <Row className={styles.row_text}>
                      <Col className="mt-2">
                        Aún sin evaluaciones.
                      </Col>
                    </Row>
                  </Container>
                  : examenes.map((data, index) => {
                    return (
                      <Container key={index}>
                        <Row className={styles.row_text}>
                          <Col className="mt-2">
                            {data.name}
                          </Col>
                          <Col className="mt-2">
                            {data.date}
                          </Col>
                          <Col className="mt-2">
                            {data.score} pts
                        </Col>
                        </Row>
                      </Container>
                    )
                  })
              }
            </div>
          </div>
        );
      })}
      {state.schoolData.appoiments ? <>
        <div className={styles.actived_courses}>
          <Icon name="remove" />
          <span>{state.schoolData.appoiments.length} Asesorías agendadas</span>
        </div>
        {state.schoolData.appoiments.map((appoiment, index) => {
          return (
            <div key={index} className={styles.course_container}>
              <div className={styles.course_detail_drop} id={index}>
                <div className={styles.group_name}>
                  <p>Curso: {appoiment.group.course.name}</p>
                  <p>Grupo: {appoiment.group.name}</p>
                </div>
                <p className="mt-4">
                  <p>Fecha y Hora :</p>
                  <p>{moment(appoiment.date).format('DD/MM/YYYY HH:mm A')}</p>
                </p>
                <p>
                  <span>Sede: {"No disponible"}</span>
                  <br />
                  <span>Salón: {"No disponible"}</span>
                </p>
              </div>
            </div>
          );
        })}
      </> : null
      }

    </>
  );
};

export default SchoolData;
