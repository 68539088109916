/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect, useContext } from 'react';
import cloneDeep from 'lodash.clonedeep'
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { Container, Row, Col } from 'reactstrap';

import { context } from '../../context';
import useLocalStorage from '../../hooks/useLocalStorage'

import QuestionMobile from './QuestionMobile';
import AnswerMobile from './AnswerMobile';
import Spinner from '../../components/spinner'
import { showMessageWarning, showNotification } from '../../components/MessageAndNotificationUtils';
import { Progress } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styles from './mobile.module.scss';
import 'antd/es/progress/style/index.css'
import 'antd/dist/antd.css';
import 'antd/lib/tooltip/style/index.css';


const FormQuizMobile = ({ params }) => {

  const [setValueLocalStorage] = useLocalStorage();

  const { state, actions } = useContext(context);
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true);
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [actualPage, setActualPageok] = useState(0);
  const [questionCollectionState, setQuestionCollectionState] = useState([]);
  const [arrayUserAnswers, setArrayUserAnswers] = useState([]);

  useEffect(() => {
    if(params.idQuiz != undefined || params.idAttempt != undefined){
      console.log("useEffect_paymentQuizzz")
      initQuiz()
    }
  }, [state.latsQuizActive]);

  useEffect(() => {
    if(params.examType){
      console.log("useEffect_freeeeQuizz")
      setIsLoading(false);
      const copyArrayQuestionCollection = cloneDeep(params.freeLatsQuizActive.questionCollection);
      setQuestionCollectionState(copyArrayQuestionCollection)
      if (localStorage.getItem('fullQuiz') !== null) {
        setArrayUserAnswers(params.freeLatsQuizActive.questionCollection[0].arrayUserAnswers)
      }
    }
  }, [params.freeLatsQuizActive]);

  const setActualPage = (value) => {
    actions.setQuizActiveQuestion(value)
    setActualPageok(value)
  }

  const initQuiz = async () => {
    console.log("Entro_initQuiz")
    if (state.quiz === undefined || state.quiz === null) {
      await actions.getQuizInfoById(params.idQuiz)
    }
    if (state.latsQuizActive === undefined || state.latsQuizActive === null) {
      await actions.getStudentQuizAttempById(params.idAttempt)
    } else {
      const copyArrayQuestionCollection = cloneDeep(state.latsQuizActive.questionCollection);
      await state.latsQuizActive.answerCollection.forEach((data, index) => {
        for (let a = 0; a < copyArrayQuestionCollection.length; a++) {
          if (data.question === copyArrayQuestionCollection[a]._id) {
            copyArrayQuestionCollection[a].arrayUserAnswers = data.answers
          }
        }
      })
      if (copyArrayQuestionCollection[actualPage].hasOwnProperty("arrayUserAnswers") > 0 && arrayUserAnswers.length === 0) {
        setArrayUserAnswers(copyArrayQuestionCollection[actualPage].arrayUserAnswers)
      }
      setQuestionCollectionState(copyArrayQuestionCollection);
      if (state.latsQuizActive.lastAnswerIndex !== undefined && state.latsQuizActive.lastAnswerIndex >= 0) {
        setActualPage(state.latsQuizActive.lastAnswerIndex)
      }
      setIsLoading(false);
    }
  }

  const backPage = () => {
    if (actualPage > 0) {
      let newBackPage = actualPage - 1;
      setActualPage(newBackPage);
      if (questionCollectionState[newBackPage].hasOwnProperty("arrayUserAnswers")) {
        setArrayUserAnswers(questionCollectionState[newBackPage].arrayUserAnswers)
      } else {
        setArrayUserAnswers([])
      }
    }
  }

  const buttonNext = () => {
    if (actualPage + 1 === questionCollectionState.length) {
      if (arrayUserAnswers.length === 0) return showMessageWarning("Debes seleccionar una respuesta ", 3)
      params.examType ? saveQuizFreeExam() : saveQuiz()
    } else {
      params.examType ? nextPageFreeExamns() : nextPage()
    }
  }


  const nextPageFreeExamns =() => {
    
    if (arrayUserAnswers.length < 1) return showMessageWarning("No has selecionado ninguna respuesta(s)", 3);
    setLoadingAnswer(true)
    //agregamos el array con las respuestas a la pregunta
    questionCollectionState[actualPage].arrayUserAnswers = arrayUserAnswers;
    if (questionCollectionState[actualPage].hasOwnProperty("arrayUserAnswers")) {
      let newNextPage = actualPage + 1;
      //guardar en localStorage
      setValueLocalStorage("fullQuiz", questionCollectionState)
      setLoadingAnswer(false)
      setActualPage(newNextPage);
      if (questionCollectionState[newNextPage].hasOwnProperty("arrayUserAnswers")) {
        setArrayUserAnswers(questionCollectionState[newNextPage].arrayUserAnswers)
      } else {
        setArrayUserAnswers([])
      }
    }
  }

  const nextPage = () => {
    if (arrayUserAnswers.length < 1) return showMessageWarning("No has selecionado ninguna respuesta(s)", 3);
    setLoadingAnswer(true)
    //guardamos las respuetas en back y validamos que no hayan errores
    actions.saveAnswers(questionCollectionState[actualPage]._id, arrayUserAnswers)
      .then((showResult) => {
        if (!showResult.success) {
          setLoadingAnswer(false)
          return showNotification("error", "No se pudo guardar tu respuesta", showResult.error.message, 0)
        }
        //agregamos el array con las respuestas a la pregunta
        questionCollectionState[actualPage].arrayUserAnswers = arrayUserAnswers;
        if (questionCollectionState[actualPage].hasOwnProperty("arrayUserAnswers")) {
          let newNextPage = actualPage + 1;
          setLoadingAnswer(false)
          setActualPage(newNextPage);
          if (questionCollectionState[newNextPage].hasOwnProperty("arrayUserAnswers")) {
            setArrayUserAnswers(questionCollectionState[newNextPage].arrayUserAnswers)
          } else {
            setArrayUserAnswers([])
          }
        }
      })
      .catch((error) => {
        return showNotification("error", "Ocurrio un error al guardar tu respuesta", error.message, 0)
      })
  }

  const saveQuiz = () => {
    if (arrayUserAnswers.length === 0) return showMessageWarning("Debes seleccionar una respuesta ", 3)
    setLoadingAnswer(true)
    //guardamos las respuetas serivicio
    //finalizamos el quiz
    actions.saveAnswers(questionCollectionState[actualPage]._id, arrayUserAnswers)
      .then((resultAnswer) => {
        if (!resultAnswer.success) {
          setLoadingAnswer(false)
          return showNotification("error", "No se pudo guardar tu respuesta", resultAnswer.error.message, 0)
        }
        let resultExam = actions.finishQuiz()
        resultExam.then((resExa) => {
          if (resExa.success) {
            setLoadingAnswer(false)
            questionCollectionState[actualPage].arrayUserAnswers = arrayUserAnswers
            history.push({
              pathname: `/quizResult/${state.quiz._id}/${state.latsQuizActive._id}`,
            })
          }
        })
      })
  }

  const saveQuizFreeExam = () =>{
    questionCollectionState[actualPage].arrayUserAnswers = arrayUserAnswers;
    params.onFinishQuiz(questionCollectionState)
  }

  //buscamos en cada pregunta si esta tiene mas de una respuesta
  const multipleOptions = () => {
    let validAnswerNumber = 0;
    questionCollectionState[actualPage].answerCollection.forEach((data, index) => {
      if (data.validAnswer) {
        validAnswerNumber = validAnswerNumber + 1
      }
    });
    return validAnswerNumber
  }

  //agrega la respuesta seleccionada al array 
  const selectedAnswer = (value) => {
    //obtenemos el numero de respuestas correctas por pregunta
    let optionNumber = multipleOptions()
    //elimina una respuesta del array para poder seleccionar otra
    if (arrayUserAnswers.includes(value) && optionNumber > 1) {
      //si solo queda una respuesta en el array lo seteamos al estado inicial
      if (arrayUserAnswers.length < 2) return setArrayUserAnswers([])
      const filterArray = arrayUserAnswers.filter((id) => id !== value);
      setArrayUserAnswers(filterArray)
    }
    //si la pregunta solo tiene uan respuesta correcta
    else if (optionNumber === 1) {
      setArrayUserAnswers([value])
    }//si la pregunta tiene mas de uan respuesta correcta
    else if (optionNumber > arrayUserAnswers.length) {
      setArrayUserAnswers(arrayUserAnswers => [...arrayUserAnswers, value]);
    } //si ya se ha selecionado el numero max de respuestas correctas
    else if (optionNumber === arrayUserAnswers.length && !arrayUserAnswers.includes(value)) {
      showMessageWarning("Para seleccionar otra respuesta primero debes deseleccionar alguna de las respuestas elegidas", 4)
    }
  }

  //asigna el estilo al div a las respuestas seleccionadas
  const styleSelectedAnswer = (id) => {
    return arrayUserAnswers.includes(id)
  }

  const showProgressbar = (questionNUmber) => {
    let percentage = params.examType ? 100 / params.freeLatsQuizActive.questionCollection.length :  100 / state.quiz.questionCollection.length;
    return Math.floor(questionNUmber * percentage);
  }

  return (
    <Fragment>
      <Container className={styles.container_form}>
        {isLoading || questionCollectionState.length <= 0 ? <Spinner /> :
          <Row className={styles.row_form + " no-gutters"}>
            <Col xs="12" className={styles.col_container_progresbar}>
              <div className={styles.col_progresbar}>
                <span className={styles.number_question} >
                  {`${actualPage + 1}/${params.examType ? params.freeLatsQuizActive.questionCollection.length : state.latsQuizActive.questionCollection.length}`}
                </span>
                <div className={styles.div_progresbar}>
                  <Progress strokeColor="#fcab00" strokeWidth="10px" className="number_question"
                    percent={showProgressbar(actualPage + 1)} showInfo={false} />
                </div>
              </div>
            </Col>
            <Col xs="12" className={styles.col_answer_question}>
              <div className={styles.scroll_answers}>
                <div>
                  {
                    <QuestionMobile
                      questionInfo={questionCollectionState[actualPage]}
                      multipleAnswers={multipleOptions()}
                    />
                  }
                </div>
                <div>
                  {
                    questionCollectionState[actualPage].answerCollection.map((data, index) => {
                      return (
                        <AnswerMobile
                          key={data.idAnswer}
                          answer={data}
                          selectedAnswer={selectedAnswer}
                          styleSelectedAnswer={styleSelectedAnswer}
                          multipleAnswers={multipleOptions()}
                          index={index}
                        />
                      )
                    })
                  }
                </div>
              </div>
            </Col>
          </Row>
        }
      </Container>

        <footer className={params.examType ? styles.foother_quiz_free : styles.footer_quiz}>
          <div className={styles.col_arrows}>
            <Button className={actualPage > 0 ? styles.back_page : styles.back_page + " " + styles.button_disabled} onClick={backPage} size="large">
              <LeftOutlined type="left" className={styles.arrow_left + " " + styles.icon} />
              <span className={styles.text_button+" ml-1"}>Atras</span>
            </Button>

            <Button loading={loadingAnswer} className={styles.back_page} onClick={buttonNext} size="large">
              <span className={styles.text_button+" ml-1"}>{actualPage + 1 === questionCollectionState.length ? "Finalizar" : "Siguiente"}</span>
              <RightOutlined type="right" className={styles.arrow_left + " " + styles.icon} />
            </Button>
          </div>
        </footer>
    </Fragment>
  )
}
export default FormQuizMobile