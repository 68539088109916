import React, { useEffect } from 'react';
import BookingCalendar from '../../components/bookingCalendar'
import { Container } from 'reactstrap'
import { PageHeader } from 'antd';
// import { context } from '../../context';
import styles from './index.module.sass';
import 'antd/es/page-header/style/index.css'


const Appoiments = (props) => {
  // const { state, actions } = useContext(context);
  const idGroup = (props.match.params.idGroup)
  useEffect(() => {
    // actions.getAttendance();
  }, []);

  return (
    <section className={styles.container}>
      <PageHeader
        className="site-page-header"
        onBack={() => props.history.goBack()}
        title="Agendar Asesoría"
        subTitle="Agenda en esta sección tu asesoría"
      />
      <Container>
        <BookingCalendar
          history={props.history}
          idGroup={idGroup}
        />
      </Container>
    </section>
  );
};

export default Appoiments;
