import React from 'react';
import { Button } from 'reactstrap'
import styles from './button.module.scss';

const ButtonComponent = ({ children, className, theme, ...props }) => {
  return (
    <Button {...props} className={`${className} ${styles[theme]}`}>
      {children}
    </Button>
  );
};

export default ButtonComponent;
