import React, { useEffect } from 'react'

import { Replay } from 'vimond-replay';
import CompoundVideoStreamer from 'vimond-replay/video-streamer/compound';
import 'vimond-replay/index.css';
import styles from './styles.module.scss'

const replayOptions = {
    controls: {
        includeControls: [
            'playPauseButton',
            
            'timeDisplay',
            'bufferingIndicator',
            'fullscreenButton',
            'gotoLiveButton',
            'qualitySelector',
            'playbackMonitor'
        ],
        liveDisplayMode: "live-offset"

    }
};

const VimeoLiveContainer = React.memo(({ cdnUrl }) => {

    useEffect(() => {
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.container_row}>
                <p class={styles.live}>Clase en vivo</p>
                <Replay
                    source={{
                        streamUrl: cdnUrl !== undefined ? cdnUrl : "",
                        contentType: 'application/x-mpegurl',
                        isLive: true
                    }}
                    options={replayOptions}
                    initialPlaybackProps={{ isPaused: false, volume: 1, isMuted: false }}
                    
                    /* onStreamStateChange={message => console.log(message)} */
                >
                    <CompoundVideoStreamer />
                </Replay>
            </div>
        </div>
    )

})
export default VimeoLiveContainer