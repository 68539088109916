import React, { PureComponent } from 'react';
import style from './styles.module.scss'

const icon = `${process.env.PUBLIC_URL}/assets/video.png`;


class ImageComponent extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            urlImage: undefined
        }

    }
    componentDidMount() {
        if (this.props.isFile) {
            this.getVideoUrlThumbnailFile(this.props.file, this.callbackVideo)
        } else if (this.props.urlThumbnail && this.props.urlThumbnail.length > 0) {
            this.setState({
                urlImage: this.props.urlThumbnail
            })
        } else {
            this.getVideoImage(this.props.url, 1, this.callbackVideo)
        }
    }

    componentDidUpdate(prevProps, prepState) {
        //console.log("enter componentDidUpdate")
        if (!this.props.isFile && prevProps.url && prevProps.url !== this.props.url) {
            this.setState({
                urlImage: undefined
            })
            this.getVideoImage(this.props.url, 1, this.callbackVideo)
        }
    }


    callbackVideo = (url) => {
        this.setState({
            urlImage: url
        })
    }

    getVideoImage = (path, secs, callback) => {
        const video = document.createElement('video');
        video.onloadedmetadata = function () {
            if ('function' === typeof secs) {
                secs = secs(this.duration);
            }
            this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
        };
        video.onseeked = function (e) {
            var canvas = document.createElement('canvas');
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            callback(canvas.toDataURL());
        };
        video.onerror = function (e) {
            callback("");
        };
        video.crossOrigin = "Anonymous";
        video.src = path;
        try {
            video.remove();
        } catch (error) {
            console.error(error)
        }
    }


    getVideoUrlThumbnailFile = (file, callback) => {
        this.setState({
            urlImage: icon
        })
    }



    render() {
        let url = icon
        if (this.state.urlImage)
            url = this.state.urlImage
        return (
            <>
                <img className={style.image}
                    src={url}
                    alt="video_image"
                />
                <div id="overlays" className={style.container_overlay}>
                    {this.props.isViewed ?
                        <div className={style.status_bar} />
                        : null}
                    {this.props.duration !== undefined ?
                        <div className={style.label_time}>
                            <span class="time-render" aria-label="58 minutos">
                                58:26
                            </span>
                        </div>
                        : null}
                    {this.props.isLastItem ?
                        <div className={style.actual_item} />
                        : null}
                </div>
            </>
        )
    }
}
export default ImageComponent