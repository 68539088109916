import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../components/icons';
import styles from './index.module.sass';

const FinancialAccount = () => {
  return (
  <>
      <div className={styles.page}>
        <p className={styles.title}>
          <span> 2 Cuentas bancarias </span>
          <Link to="/financial/add-account">
            <Icon name="add" className={styles.add_account} />
          </Link>
        </p>
        <div className={styles.account}>
          <div className={styles.row}>
            <span>Cuenta Banamex tarjeta con terminación 3456</span>
            <span className={styles.edit_account}>editar</span>
          </div>
          <div className={styles.label_row}>
            <label>Titular:</label>
            <span>Jaqueline Bautista</span>
          </div>
          <div className={styles.label_row}>
            <label>Código:</label>
            <span>***</span>
          </div>
          <div className={styles.label_row}>
            <label>Vence:</label>
            <span>12/23</span>
          </div>
        </div>
        <div className={styles.account}>
          <div className={styles.row}>
            <span>Cuenta Banamex tarjeta con terminación 3456</span>
            <span className={styles.edit_account}>editar</span>
          </div>
          <div className={styles.label_row}>
            <label>Titular:</label>
            <span>Jaqueline Bautista</span>
          </div>
          <div className={styles.label_row}>
            <label>Código:</label>
            <span>***</span>
          </div>
          <div className={styles.label_row}>
            <label>Vence:</label>
            <span>12/23</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialAccount;
