/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Route, Redirect, withRouter, Switch, useRouteMatch } from 'react-router-dom';
import { context } from '../../context';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// pages
import Layout from '../Layout';
import Home from '../../pages/home';
import Login from '../../pages/login';
import Logout from '../../pages/logout';
import FreeLesson from '../../pages/register-free-lesson';
import FreeTest from '../../pages/register-free-exam';
import FreeExams from '../../pages/freeExams';
import Payments from '../../pages/payments';
import PaymentMethodCRUD from '../../pages/paymentMethodCRUD';
import Financial from '../../pages/financial';
import Attendance from '../../pages/attendance';
import SchoolData from '../../pages/school-data';
import EditProfile from '../../pages/edit-profile';
import Playlist from '../../pages/playlist';
import Appoiments from '../../pages/appoiments';
import UpdatePassword from '../../pages/update-password';
import FinancialAccount from '../../pages/financial-accounts';
import RegisterCourseLevel from '../../pages/register-course-level';
import FinancialAddAccount from '../../pages/financial-add-account';
import RegisterCourseGroup from '../../pages/register-course-group';
import ExamsFastRegister from '../../pages/ExamFastRegister';
// import RegisterCourseExternal from '../../pages/register-course-external';
//Mobile
import VideosMobile from '../../components/videosMobile';
import Quiz from '../../pages/quiz/index';
import QuizResult from '../../pages/quizResult/index';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const Router = (props) => {
  const { state, actions } = useContext(context);
  let matchPlaylist = useRouteMatch('/playlist/:playlistId');
  let matchCoursePlaylist = useRouteMatch('/coursePlaylist/:courseId');
  let matchFreeTest = useRouteMatch('/freeExams/:idFreeExam');
  //This will hide sideBar when OnClick Element
  useEffect(() => {
    if (!matchPlaylist && !matchCoursePlaylist) {
      actions.changeSideBar();
      window.scrollTo(0, 0);
    }
  }, [props.location.pathname]);

  return state && state.isLoggedIn ? (
    <div className={matchFreeTest ? '' : 'container_wrapper'}>
      {matchFreeTest ? '' : <Layout />}
      <Switch>
        <Route exact path="/videos/:idVideo" component={VideosMobile} />
        <Route exact path="/quiz/:idQuiz" component={Quiz} />
        <Route exact path="/quiz/:idQuiz/:idAttempt" component={Quiz} />
        <Route exact path="/quizResult/:idQuiz/:idAttempt" component={QuizResult} />
        <Route
          exact
          path="/register-course-group/:courseId"
          render={(props) => (
            <Elements stripe={stripePromise}>
              <RegisterCourseGroup {...props} />{' '}
            </Elements>
          )}
        />
        <Route exact path="/playlist/:playlistId" component={Playlist} />
        <Route exact path="/exams-fast-register/:courseId" component={ExamsFastRegister} />
        <Route exact path="/playlist/:playlistId/:typeSelected/:idSelected" component={Playlist} />
        <Route exact path="/coursePlaylist/:courseId" component={Playlist} />
        <Route exact path="/financial/add-account" component={FinancialAddAccount} />
        <Route exact path="/financial/accounts" component={FinancialAccount} />
        <Route exact path="/financial/payments/course/:courseId" component={Payments} />
        <Route exact path="/financial/payments/method" component={PaymentMethodCRUD} />
        <Route exact path="/edit-profile" component={EditProfile} />
        <Route exact path="/appoiments/:idGroup" component={Appoiments} />
        <Route exact path="/register/:idLevel?" component={RegisterCourseLevel} />
        <Route exact path="/examenGratis">
          <FreeTest isInternalStudent={true} />{' '}
        </Route>
        <Route exact path="/freeExams/:idFreeExam">
          <FreeExams />{' '}
        </Route>
        {/* <Route exact path="/register-course-external" component={RegisterCourseExternal} /> */}
        <Route exact path="/attendance" component={Attendance} />
        <Route exact path="/financial" component={Financial} />
        <Route exact path="/school-data" component={SchoolData} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/logout" component={Logout} />
        <Redirect from="*" to="/home" />
      </Switch>
    </div>
  ) : (
    <Switch>
      <Route exact path="/login" component={Login} />

      <Route
        exact
        path="/clasesgratiscomipems"
        render={(props) => <FreeLesson {...props} groupId="65e8cd731e1fa13a01a4c63b" />}
      />
      <Route
        exact
        path="/clasesgratis"
        render={(props) => <FreeLesson {...props} groupId="65e9dd8bdd6d693a522c23f0" />}
      />

      <Route exact path="/examenGratis" component={FreeTest} />
      <Route exact path="/freeExams/:idFreeExam">
        <FreeExams />{' '}
      </Route>
      <Route exact path="/update-password" component={UpdatePassword} />
      <Redirect from="*" to="/login" />
    </Switch>
  );
};

export default withRouter((props) => <Router {...props} />);
