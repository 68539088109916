import React, { useContext, useEffect } from 'react';
import { context } from '../../context';
import {  showNotification } from '../../components/MessageAndNotificationUtils';

const Logout = () => {
  const { actions } = useContext(context)

  useEffect(()=>{
    showNotification("error","Se ha cerrado la sesión");
    actions.closeSession();
  },[])

  return (
    <>
    </>
  );
};

export default Logout;
