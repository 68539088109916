import React from 'react';
import { Form, Input, Button, InputNumber } from 'antd';

import styles from './steps.module.scss';

const SteptForm = ({ onSaveFormGetResult }) => {

  const onFinish = (values) => {
    // console.log('Success:', values);
    onSaveFormGetResult(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <section className={styles.step_form_main}>
      <Form
        layout={'vertical'}
        name="basic"
        initialValues={{
          remember: true
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off">
        <Form.Item
          label="Nombre"
          name="name"
          rules={[
            {
              required: true,
              message: 'Ingresa tu nombre'
            }
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="A. Paterno"
          name="lastName"
          rules={[
            {
              required: true,
              message: 'Ingresa tu apellido paterno'
            }
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="A. Materno"
          name="secondLastName"
          rules={[
            {
              required: true,
              message: 'Ingresa tu apellido materno'
            }
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="email"
          label="E-mail"
          rules={[
            {
              type: 'email',
              message: 'No es un correo valido!'
            },
            {
              required: true,
              message: 'Ingresa tu correo'
            }
          ]}>
          <Input />
        </Form.Item>

        <Form.Item
          name="phoneNumber"
          label="Teléfono"
          rules={[
            {
              required: true,
              message: 'Ingresa tu Teléfono'
            }
          ]}>
          <InputNumber
            style={{
              width: '70%'
            }}
          />
        </Form.Item>

        <Form.Item
          name="phoneNumberTwo"
          label="Teléfono 2"
          rules={[
            {
              required: true,
              message: 'Ingresa tu Teléfono'
            }
          ]}>
          <InputNumber
            style={{
              width: '70%'
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

export default SteptForm;
