/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { context } from '../../context';
import ButtonComponent from '../../components/button';
import Spinner from '../../components/spinner';
import Flickity from 'react-flickity-component';
import CarouselCard from '../../components/CourseCard/carouselCard';
import CourseCard from '../../components/CourseCard';
import PresentialCourseCard from '../../components/CourseCard/presential_course';
import { Col } from 'reactstrap';
import PayLessons from './components/PayLesson';
import VimeoLiveContainer from './components/VimeoLiveContainer';
import ChatComponent from './components/chatComponent';
import { idsMessage } from 'data/MessageCourses/data-couses';
import styles from './home.module.sass';

import 'flickity/css/flickity.css';

const Home = (props) => {
  const { state, actions } = useContext(context);
  const [loadingMaterial, setLoadingMaterial] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    actions.getCourses().then((result) => {
      console.log(result);
    });
  }, []);

  /*
   * This will catch al events on SliderFunctions
   */
  const SliderFunctions = (FlickRef) => {
    if (FlickRef !== undefined && FlickRef !== null) {
      FlickRef.on('change', (index) => {
        /* console.log('Slide changed to ' + index); */
        const { _id, activeLiveStream } = state.courses[index];
        onClickCourse(_id);
        setIsOpen(activeLiveStream); // setIsOpen is the condition to check if the selected course is in liveStream
      });
      FlickRef.on('staticClick', (event, pointer, cellElement, cellIndex) => {
        /* console.log('Click in ' + cellIndex); */
        if (cellIndex !== undefined) {
          FlickRef.select(cellIndex);
        }
      });
    }
  };
  //TODO fix validations

  const isCompletePayment = (id) => {
    const indexCourse = state.courses.findIndex((item) => String(item._id) === String(id));
    if (indexCourse >= 0) {
      if (state.courses[indexCourse].price === 0) {
        return true;
      }
    }
    if (state.schoolData !== undefined && state.schoolData.payments !== undefined) {
      for (let i = 0; i < state.schoolData.payments.length; i++) {
        if (String(state.schoolData.payments[i].courseId) === String(id)) {
          if (state.schoolData.payments[i].coursePrice === 0) {
            let index = state.schoolData.courses.findIndex(
              (value) => String(id) === String(value._id)
            );
            if (index >= 0) {
              if (moment(state.schoolData.courses[index].group.startDate).isSame(moment(), 'day'))
                return true;
              else return false;
            } else {
              return false;
            }
          }
          let total =
            state.schoolData.payments[i].totalPayments +
            state.schoolData.payments[i].totalDiscounts;
          let percentage = (total * 100) / state.schoolData.payments[i].coursePrice;
          if (percentage >= 85) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return false;
  };

  const onClickCourse = async (_id) => {
    setLoadingMaterial(true);
    await actions.setCourse(_id);
    setLoadingMaterial(false);
  };

  //borramos los datos del ultimo video visto
  if (state.video !== null) {
    actions.resetVideo();
  }

  //borramos los datos del ultimo video visto
  if (state.quiz !== null) {
    actions.resetAllQuizParameters();
  }

  //borramos los datos de la ultima playlist guardada
  if (state.playlist !== null) {
    actions.resetPlayList();
  }
  const flickityOptions = {
    initialIndex: 0,
    pageDots: true,
    cellAlign: 'center',
    adaptiveHeight: true
  };

  return (
    <>
      <div className={styles.section}>
        {(!state.courses.length && <Spinner />) || (
          <>
            {
              <div className={styles.home_header}>
                <p className={styles.title}>{!!state.courses.length && 'Tus Cursos:'}</p>
                <Link to="/register">
                  <ButtonComponent className={styles.button_container} theme="light">
                    Inscribir Curso o Taller
                  </ButtonComponent>
                </Link>
              </div>
            }
            <Flickity
              elementType={'div'} // default 'div'
              options={flickityOptions} // takes flickity options {}
              disableImagesLoaded={false} // default false
              reloadOnUpdate={false} // default false
              static={false} // default false
              flickityRef={(c) => SliderFunctions(c)}>
              {state.courses.map(
                ({ _id, name, poster, activeLiveStream, liveStreamUrl }, index) => (
                  <CarouselCard
                    name={name}
                    activeLiveStream={activeLiveStream}
                    isSelected={_id === state.course._id}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    isValid={idsMessage.includes(_id)} // isValid is the condition to confirm whether the course is in the array of courses with message
                  />
                )
              )}
            </Flickity>

            <div className={styles.course_detail}>
              {state.course.activeLiveStream &&
              state.course.liveStreamUrl !== undefined &&
              !state.course.isFinished ? (
                state.course.hasArrangePayment || isCompletePayment(state.course._id) ? (
                  <>
                    <Col xs="12">
                      <VimeoLiveContainer cdnUrl={state.course.liveStreamChannelUrl} />
                    </Col>
                    <Col className={styles.chat_container}>
                      <ChatComponent url={state.course.liveStreamUrl} />
                    </Col>
                  </>
                ) : (
                  <Col xs="12">
                    <PayLessons courseId={state.course._id} />
                  </Col>
                )
              ) : null}
              <div className={styles.details}>
                {loadingMaterial ? (
                  <Spinner />
                ) : (
                  <>
                    {!!state.course.playlists.length > 0 && !state.course.isFinished ? (
                      <CourseCard
                        course={state.course}
                        hasArrangePayment={
                          state?.course ? isCompletePayment(state?.course?._id) : false
                        }
                      />
                    ) : (
                      ''
                    )}
                    <PresentialCourseCard
                      course={state.course}
                      days={state.days}></PresentialCourseCard>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Home;
