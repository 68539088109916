/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import ButtonComponent from '../../../components/button';
import Cards from 'react-credit-cards';
import { showMessageWarning } from '../../../components/MessageAndNotificationUtils';
import styles from './steps.module.scss';

const discountsCourses = [
  "5f12207e64710c5a7dbe53db",
  "5f12209f517c645a924ff525",
  "5f1220aea277445a737daf83",
]

const CheckData = ({ studentInfo, setCardData, cardData, courseInfo, backPage, buttonPay
  , isLoading, isEnrrollFlow = false, isModalPayment = false, quantityDiscounts = 0 }) => {

  useEffect(() => {
    if (isEnrrollFlow) {
      setCardData({
        ...cardData,
        transaction_amount: String(courseInfo.course.price)
      })
    } else if (isModalPayment) {
      setCardData({
        ...cardData,
        transaction_amount: String(courseInfo.course.priceByLesson)
      })
    }

  }, [])

  const isPresentialExam = () => {
    const index = discountsCourses.indexOf(item => {
      return courseInfo.course._id === item;
    })
    return index < 0;
  }

  return (
    <Container className={styles.container_center}>
      <Row className={styles.row_container}>
        <Col xs="12">
          <h3>{isModalPayment ? "Clase a pagar:" : "Curso a pagar:"} {courseInfo.course.name}</h3>
          <span className={styles.span_debt}>{isEnrrollFlow || isModalPayment ? "Precio" : "Adeudo"}: ${isEnrrollFlow ? courseInfo.course.price : isModalPayment ? courseInfo.course.priceByLesson : courseInfo.debtQuantity}</span>
          <br />
          {isEnrrollFlow && !isPresentialExam() ? <span className={styles.span_debt}>Descuentos: ${quantityDiscounts}</span> : ""}
          {isEnrrollFlow && (quantityDiscounts >= courseInfo.course.price) ? "" :
            <>
              <h4>Pagar con:</h4>
              <Cards
                expiry={`${cardData.card.exp_month < 10 ? "0" + cardData.card.exp_month : cardData.card.exp_month}${cardData.card.exp_year}`}
                //  expiry={`${cardData.exp_month}${cardData.exp_year}`}
                name={`${studentInfo.name} ${studentInfo.lastName} ${studentInfo.secondLastName}`}
                number={"************"+cardData.card.last4}
                issuer={cardData.card.brand}
                locale={{ valid: 'MM/AA' }}
                acceptedCards={['visa', 'mastercard', 'amex']}
                placeholders={{ name: 'Nombre y apellido' }}
                cvc={"***"}
                preview={true}
              />
            </>}
        </Col>
        <Col xs="12" md="4" className="mt-3">
          <ButtonComponent className="" theme="light" block
            onClick={() => backPage()} disabled={isLoading}
          >
            Regresar
          </ButtonComponent>
        </Col>
        <Col xs="12" md="4 offset-md-4" className="mt-3">
          <ButtonComponent className="" theme="light" block
            onClick={buttonPay} disabled={isLoading}
          >
            Confirmar
          </ButtonComponent>
        </Col>
      </Row>
    </Container>
  )
}
export default CheckData