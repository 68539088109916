import { useState } from 'react';
import { ActionsComent } from './actionsComent';
import { AuthorComent } from './authorComent';
import { AvatarComent } from './avatarComent';
import { Avatar, Comment } from 'antd';
import Icon from 'components/icons';
import moment from 'moment';
import { Collapse } from 'reactstrap';
import { FormComent } from './formComent';
import styles from '../../../index.module.scss';

export const MainComment = ({ commentData, _id, actions, setComment }) => {
  const [replay, setReplay] = useState('');
  //estados collapse//
  //estados para el collapse de los parent files
  const [isOpen, setIsOpen] = useState('');
  const [status, setStatus] = useState(false);
  //estados para el collapse de las respuestas
  const [isOpenSub, setIsOpenSub] = useState('');
  const [statusSub, setStatusSub] = useState(false);
  const [onEdit, setOnEdit] = useState(true);
  const [onEditReply, setOnEditReply] = useState(true);
  const [commentEdit, setCommentEdit] = useState('');
  const [submit, setSubmit] = useState(false);
  const [submitReplay, setSubmitReplay] = useState(false);

  const itCanChangen = commentData.student?._id === localStorage['student'];
  const video = commentData.video

  //activa o desactiva el collapse
  const collapseFile = (e) => {
    if (e.currentTarget.id === isOpen) {
      setStatus(false);
      setIsOpen('');
    } else {
      setIsOpen(e.currentTarget.id);
      setStatus(true);
    }
  };

  //activa o desactiva el collapse de las respuestas
  const collapseFileSub = (e) => {
    if (e.currentTarget.id === isOpenSub) {
      setStatusSub(false);
      setIsOpenSub('');
    } else {
      setIsOpenSub(e.currentTarget.id);
      setStatusSub(true);
    }
  };

  const saveReply = (e) => {
    setSubmitReplay(true);
    actions.setReplyVideoComment(_id, replay, moment(), e.currentTarget.id);
    setTimeout(() => {
      setSubmitReplay(false);
      setIsOpen(false);
      setIsOpenSub(commentData._id);
      setStatusSub(true);
      setComment('');
      setReplay('');
    }, 600);
  };

  const deleteComemet = (idVideo, idComment) => {
    actions.deleteVideoComment(idVideo, idComment);
  };

  const onEditComment = () => {
    setCommentEdit(commentData.comment);
    setOnEdit(false);
  };

  const onEditCommentReply = (comment) => {
    setCommentEdit(comment);
    setOnEditReply(false);
  };

  const saveEditComment = () => {
    setSubmit(true);
    actions.editVideoComment(_id, commentEdit, commentData._id);
    setTimeout(() => {
      setSubmit(false);
      setOnEdit(true);
    }, 600);
  };
  const saveEditCommentReply = (commentId) => {
    setSubmit(true);
    actions.editVideoComment(_id, commentEdit, commentId);
    setTimeout(() => {
      setSubmit(false);
      setOnEditReply(true);
    }, 600);
  };


  return (
    <>
      {onEdit ? (
        <Comment
          actions={ActionsComent(
            collapseFile,
            collapseFileSub,
            commentData,
            deleteComemet,
            onEditComment,
            itCanChangen
          )}
          author={<AuthorComent commentData={commentData} />}
          avatar={<AvatarComent commentData={commentData} />}
          content={<p>{commentData.comment}</p>}>
          {
            //respuestas a cometarios//
            <Collapse
              key={commentData._id}
              id={commentData._id}
              isOpen={isOpenSub === commentData._id && statusSub ? true : false}>
              {commentData.responses.map((commentData, index) => {
                return onEditReply ? (
                  <Comment
                    actions={ActionsComent(
                      collapseFile,
                      collapseFileSub,
                      commentData,
                      deleteComemet,
                      () => onEditCommentReply(commentData.comment),
                      itCanChangen,
                      video
                    ).slice(-2)}
                    //actions={[<span key="comment-nested-reply-to">Reply to</span>]}
                    author={<AuthorComent commentData={commentData} />}
                    avatar={<AvatarComent commentData={commentData} />}
                    content={<p>{commentData.comment}</p>}
                  />
                ) : (
                  <FormComent
                    stateComment={commentEdit}
                    setStateComment={setCommentEdit}
                    placeholder={'Escribe un comentario'}
                    textButton={'Editar el comentario'}
                    submitting={submit}
                    saveFuntion={() => saveEditCommentReply(commentData._id)}
                    id={commentData._id}
                    cancelFuntion={()=>setOnEditReply(true)}
                  />
                );
              })}
            </Collapse>
          }

          <Collapse
            id={commentData._id}
            isOpen={isOpen === commentData._id && status ? true : false}>
            <Comment
              avatar={
                localStorage['avatar'] ? (
                  <Avatar src={localStorage['avatar']} alt="Image Avatar Student" />
                ) : (
                  <Icon name="account" className={styles.profile_icon} />
                )
              }
              content={
                <FormComent
                  stateComment={replay}
                  setStateComment={setReplay}
                  placeholder={'Escribe una respuesta'}
                  textButton={'Agregar respuesta'}
                  submitting={submitReplay}
                  saveFuntion={saveReply}
                  id={commentData._id}
                  cancelFuntion={()=>setStatus(false)}
                />
              }
            />
          </Collapse>
        </Comment>
      ) : (
        <FormComent
          stateComment={commentEdit}
          setStateComment={setCommentEdit}
          placeholder={'Escribe un comentario'}
          textButton={'Editar el comentario'}
          submitting={submit}
          saveFuntion={saveEditComment}
          id={commentData._id}
          cancelFuntion={()=>setOnEdit(true)}
        />
      )}
    </>
  );
};
