import React, { useState, Fragment } from 'react';
import { MathfieldComponent } from 'react-mathlive';
import ModalImage from './ModalImage';
import styles from '../index.module.sass';

import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

const ShowTypesQuestions = (questionInfo, questionNumber) => {
  const [modalImg, setModalImg] = useState(false);
  const [imgInfo, setImgInfo] = useState({});

  const openModalImage = (dataImage) => {
    setModalImg(true);
    setImgInfo(dataImage);
  };

  //muestra si la pregunta esta bien respondida o no
  const setRighthWrong = () => {
    let correctAnswers = [];

    questionInfo.answerCollection.map((data) =>
      data.validAnswer ? correctAnswers.push(data.idAnswer) : ''
    );

    if (correctAnswers.length === 1) {
      if (questionInfo.arrayUserAnswers[0] === correctAnswers[0]) {
        return <span className={styles.color_score_rigth}> Correcto</span>;
      } else {
        return <span className={styles.color_score_wrong}> Incorrecto</span>;
      }
    } else {
      if (correctAnswers.length !== questionInfo.arrayUserAnswers.length) {
        return <span className={styles.color_score_wrong}> Incorrecto</span>;
      } else {
        let value = 0;
        for (let a = 0; a < correctAnswers.length; a++) {
          value = questionInfo.arrayUserAnswers.indexOf(correctAnswers[a]);

          // console.log("ScaraCorrectaINcorrecta")
          // console.log(correctAnswers)
          // console.log(questionInfo.arrayUserAnswers)
          // console.log(value)
          // console.log("ScaraCorrectaINcorrecta")
          if (value === -1) {
            return <span className={styles.color_score_wrong}> Incorrecto</span>;
          }
        }
        if (value !== -1) {
          return <span className={styles.color_score_rigth}> Correcto</span>;
        }
      }
    }
  };

  if (questionInfo.hasOwnProperty('questionType')) {
    if (questionInfo.questionType === 2) {
      return (
        <div>
          <span className={styles.text_image_resp}>
            {`${questionNumber + 1}.- ${questionInfo.question}`}
            <span className="ml-2">{setRighthWrong()}</span>
          </span>
          <div className={styles.img_question_box} onClick={() => openModalImage(questionInfo)}>
            <img
              className={styles.img_quiz_questions}
              src={questionInfo.questionImage.url}
              alt="img"
            />
          </div>
          {modalImg ? (
            <ModalImage modal={modalImg} setModal={setModalImg} imgInfo={imgInfo} />
          ) : null}
        </div>
      );
    } else if (questionInfo.questionType === 3) {
      return (
        <Fragment>
          {`${questionNumber + 1}.- ${questionInfo.question}`}
          <span className="ml-2">{setRighthWrong()}</span>
          <div className="text-center" style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <MathfieldComponent
              latex={questionInfo.questionFormula}
              mathFieldConfig={{
                defaultMode: 'math',
                readOnly: true,
                readonly: true,
                keyboardSound: undefined,
                plonkSound: undefined
              }}
            /> */}
            <MathfieldComponent
              latex={questionInfo.questionFormula}
              mathFieldConfig={{
                readOnly: true
              }}
            />

            {/* <BlockMath math={questionInfo.questionFormula}/> */}
          </div>
        </Fragment>
      );
    }
  } else {
    return (
      <Fragment>
        {`${questionNumber + 1}.- ${questionInfo.question}`}
        <span className="ml-2">{setRighthWrong()}</span>
      </Fragment>
    );
  }
};
export default ShowTypesQuestions;
