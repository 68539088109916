import { Avatar } from 'antd';
import Icon from 'components/icons';
import styles from '../../../index.module.scss';

export const AvatarComent = ({ commentData }) => {
  return (
    <div>
      {commentData && commentData.student && commentData.student.avatar ? (
        <Avatar src={commentData.student.avatar} alt="Image Avatar Student" />
      ) : (
        <Icon name="account" className={styles.profile_icon} />
      )}
    </div>
  );
};
