import React from 'react';
import styles from './inputForm.module.sass';

const ShortInput = ({ label, ...rest }) => {
  return (
    <div className={styles.input_short_form_container}>
      <label className={styles.label_input_form}>{label}</label>
      <input className={styles.input_form} {...rest} />
    </div>
  );
};

export default ShortInput;
