import { useContext, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { PageHeader } from 'antd';
import { showNotification } from '../../components/MessageAndNotificationUtils';
import moment from 'moment';
import { newSchedules } from '../../utils/funciones';
import { context } from '../../context';

import ShowResult from './components/showResult';

import styles from './exams.module.scss';

const ExamsFastRegister = ({ match, history }) => {
  const { state, actions } = useContext(context);

  const [selected, setSelected] = useState(null);
  const [successEnrollment, setSuccessEnrollment] = useState(false);

  useEffect(() => {
    actions.getGroupsByCourseId(match.params.courseId);
  }, []);

  const enrollStudent = async () => {
    if (selected) {
      const result = await actions.enrollToGroup(selected._id);

      if (result.success) {
        setSuccessEnrollment(true);
      } else {
        showNotification(
          'error',
          'Ocurrio un error al registrarte.',
          result.errorExt ? result.errorExt?.errorEs : '',
          0
        );
      }
    }
  };

  return (
    <div className={styles.container}>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={state.selectedCourse.name || ''}
        subTitle=""
      />
      {!successEnrollment ? (
        <>
          <h5>Selecciona el grupo</h5>
          {state.availableGroups.length > 0 ? (
            state.availableGroups.map((group, index) => {
              if (group.activeLanding) {
                return (
                  <div key={index} className={styles.group_list} onClick={() => setSelected(group)}>
                    <div className={`${selected?._id == group._id ? styles.selected : ''}`}>
                      <p className={`${selected?._id == group._id ? styles.selected : ''}`}>
                        {group.name || ''}
                      </p>
                      <span>{newSchedules(group.schedules)}</span>
                      <br />
                      <span>Inicio: {moment(group.startDate).format('DD/MM/YYYY')}</span>
                      <br />
                      <span>
                        Sede:
                        {group.classRoom && group.classRoom.location
                          ? group.classRoom.location.name
                          : 'Sin asignar'}
                      </span>
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <section className={styles.nogroups}>
              <h4>No hay grupos disponibles</h4>
            </section>
          )}
          <div className={styles.button_container}>
            <Button
              disabled={selected != null ? false : true}
              className={styles.register}
              onClick={enrollStudent}>
              Inscribir
            </Button>
          </div>
        </>
      ) : (
        <ShowResult selected={selected} />
      )}
    </div>
  );
};

export default ExamsFastRegister;
