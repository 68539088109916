/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styles from './index.module.scss'

const CountDown = ({ time, limitTime }) => {
    const [seconds, setSeconds] = useState(time);
    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            setSeconds('Terminó tu tiempo');
        }
    }, []);
    const choseColor = () => {
        let percentage = Math.max(0, (seconds * 100) / limitTime);
        if (percentage > 60) {
            return styles.color_full
        } else if (percentage > 20) {
            return styles.color_half
        } else {
            return styles.color_empty
        }
    }
    return (
        <>
            <input type="hidden" />
            <div className={styles.countdown}>
                <div className={styles.tiles + " " + choseColor()}>
                    <span>{seconds > 0 ? moment.utc(seconds * 1000).format('HH:mm:ss') : seconds}</span>
                </div>
                {seconds > 0 ? <div className={styles.countdown_label}>Tiempo Restante</div> : null}
            </div>
        </>
    )
}
export default CountDown