import React from 'react'
import { Col } from 'reactstrap'
import styles from './styles.module.scss'


const ChatComponent = ({ url, children, ...props }) => {
    //https://vimeo.com/live/interaction_tools/live_event/3623852?auth_room_unlisted_hash=39ce237d96
    // const cleanUrl = url.replace("https://vimeo.com/event/", "")
    // const section = cleanUrl.split("/");
    // let chatUrl = ""
    // if (section.length > 0) {
    //     chatUrl = `https://vimeo.com/live-chat/788753188/interaction/${section[1]}#extern`;
    // }
    // Extraer el ID del evento
    // Obtener el ID del evento de la URL original
    const eventId = url.match(/\/event\/(\d+)\//)[1];
    // Obtener el hash de la URL original
    const hash = url.split('/').pop();
    // Construir el nuevo enlace utilizando los componentes
    const newLink = `https://vimeo.com/live/interaction_tools/live_event/${eventId}?auth_room_unlisted_hash=${hash}`;
    let chatUrl = newLink
    return (
        <Col xs="12" sm="12" md="12" lg="8" xl="8" className={styles.container + " no-gutters"}>
            <div className={styles.container_row}>
                <iframe title="chatLivestream" src={chatUrl} frameborder="0" className={styles.container_chat_iframe} />
            </div>
        </Col>
    )
}
export default ChatComponent