import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { MathfieldComponent } from 'react-mathlive';
import ModalImage from './ModalImage';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import 'antd/es/icon/style/index.css';
import styles from '../index.module.sass';

import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

const ShowAnswer = ({ answer }) => {
  const [modalImg, setModalImg] = useState(false);
  const [imgInfo, setImgInfo] = useState({});

  const openModalImage = (dataImage) => {
    setModalImg(true);
    setImgInfo(dataImage);
  };

  if (answer.hasOwnProperty('answerType')) {
    switch (answer.answerType) {
      case 2:
        return answer.validAnswer ? (
          <Container>
            <Row>
              <Col xs="12">
                <div className={styles.con_img}>
                  <CheckCircleOutlined style={{ fontSize: '30px', color: '#52c41a' }} />
                  <span onClick={() => openModalImage(answer)}>
                    <img
                      className={styles.img_quiz_answers_resp}
                      src={answer.answerImage.url}
                      alt="img"
                    />
                  </span>
                </div>
              </Col>
            </Row>
            {modalImg ? (
              <ModalImage modal={modalImg} setModal={setModalImg} imgInfo={imgInfo} />
            ) : null}
          </Container>
        ) : (
          <Container>
            <Row>
              <Col xs="12">
                <div className={styles.con_img}>
                  <CloseCircleOutlined style={{ fontSize: '30px', color: '#E74C3C' }} />
                  <span onClick={() => openModalImage(answer)}>
                    <img
                      className={styles.img_quiz_answers_resp}
                      src={answer.answerImage.url}
                      alt="img"
                    />
                  </span>
                </div>
              </Col>
            </Row>
            {modalImg ? (
              <ModalImage modal={modalImg} setModal={setModalImg} imgInfo={imgInfo} />
            ) : null}
          </Container>
        );
      case 3:
        return answer.validAnswer ? (
          <Container>
            <Row className={styles.con_formula}>
              <Col xs="1">
                <CheckCircleOutlined
                  className={styles.div_formula}
                  style={{ fontSize: '30px', color: '#52c41a' }}
                />
              </Col>
              <Col xs="11">
                {/* <MathfieldComponent
                  latex={answer.answerFormula}
                  mathFieldConfig={{
                    defaultMode: 'math',
                    readOnly: true,
                    readonly: true,
                    keyboardSound: undefined,
                    plonkSound: undefined
                  }}
                /> */}
                <MathfieldComponent
                  latex={answer.answerFormula}
                  mathFieldConfig={{
                    readOnly: true
                  }}
                />
                {/* <BlockMath math={answer.answerFormula} /> */}
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row className={styles.con_formula}>
              <Col xs="1">
                <CloseCircleOutlined style={{ fontSize: '30px', color: '#E74C3C' }} />
              </Col>
              <Col xs="11">
                {/* <MathfieldComponent
                  latex={answer.answerFormula}
                  mathFieldConfig={{
                    defaultMode: 'math',
                    readOnly: true,
                    readonly: true,
                    keyboardSound: undefined,
                    plonkSound: undefined
                  }}
                /> */}
                <MathfieldComponent
                  latex={answer.answerFormula}
                  mathFieldConfig={{
                    readOnly: true
                  }}
                />
                {/*  <BlockMath math={answer.answerFormula} /> */}
              </Col>
            </Row>
          </Container>
        );
      default:
        return '';
    }
  } else {
    return answer.validAnswer ? (
      <Container className={styles.row_margin}>
        <Row>
          <Col xs="12">
            <div className={styles.answer_div}>
              <CheckCircleOutlined style={{ fontSize: '30px', color: '#52c41a' }} />
              <span className={styles.text_true_answer}>{answer.content}</span>
            </div>
          </Col>
        </Row>
      </Container>
    ) : (
      <Container>
        <Row>
          <Col xs="12">
            <div className={styles.answer_div}>
              <CloseCircleOutlined style={{ fontSize: '30px', color: '#E74C3C' }} />
              <span className="ml-3">{answer.content}</span>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};
export default ShowAnswer;
