import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import Payments from '../payments'

const PaymentMethodCRUD = () => {

  return (
    <Container>
      <Row>
        <Col>
          <Payments crud={true} />
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentMethodCRUD;
