/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { PageHeader } from 'antd';
import { showMessageSuccess, showMessageWarning, showNotification, showMessageLoading } from '../../components/MessageAndNotificationUtils';
import GroupSelector from './components/GroupSelector'
import CardFormPayment from '../../components/cardFormPayment/index';
// import PaymentsErrors from '../../utils/paymentErrors';
import CheckData from '../payments/components/checkData';
import EndPayment from '../payments/components/endPayment';
// import { setTypeCard, setNumberCard } from '../../utils/funciones';
import { context } from '../../context';
import styles from './index.module.scss';

const initialState = {

}
let loading = ""

const Payments = ({ crud = false, match = "", history }) => {
  const { state, actions } = useContext(context);

  const [choosedGroup, setchoosedGroup] = useState();

  const [isLoading, setIsLoading] = useState(false)
  const [cardData, setCardData] = useState(initialState)
  const [showForm, setShowForm] = useState(false)
  const [page, setPage] = useState(1)
  const [successfulPayment, setSuccessfulPayment] = useState("")
  const [quantityDiscounts, setQuantityDiscounts] = useState(0)

  const stripe = useStripe();

  useEffect(() => {
    // setLoadingCards(true)
    if (!crud) {
      actions.getCourseInfo(match.params.courseId)
    }
    // let resultCards = actions.getCards()
  }, [])

  const showStep = () => {
    switch (page) {
      case 1:
        return <GroupSelector courseId={match.params.courseId} choosedGroup={choosedGroup} setchoosedGroup={setchoosedGroup}
          buttonGroups={buttonGroups}
        />
      case 2:
        return <CardFormPayment showForm={showForm}
          backPage={() => setPage(1)}
          setCardData={onClickNextCard}
          cardData={cardData}
          nextPage={() => { }}
        />
      case 3:
        return <CheckData
          studentInfo={state.student}
          buttonPay={buttonPay}
          courseInfo={state.paymentCourseInfo}
          backPage={() => setPage(2)}
          cardData={cardData}
          setCardData={setCardData}
          isLoading={isLoading}
          isEnrrollFlow={true} quantityDiscounts={quantityDiscounts} />
      case 4:
        return <EndPayment
          successfulPayment={successfulPayment}
          studentInfo={state.student}
          total={state.paymentCourseInfo.price}
          courseInfo={state.paymentCourseInfo}
          isEnrrollFlow={true}
          backPage={() => setPage(2)}
          isFree={quantityDiscounts === state.selectedCourse.price}
          choosedGroup={choosedGroup} />
      default:
        break
    }
  }

  const buttonGroups = async () => {
    if (choosedGroup !== undefined) {
      const discounts = state.selectedCourse.discounts
      let quatityDiscounts = 0
      for (let i = 0; i < state.schoolData.courses.length; i++) {
        const indexDisc = discounts.findIndex(value => String(value.course) === String(state.schoolData.courses[i]._id))
        if (indexDisc >= 0) {
          console.log("HAS DISCOUNT")
          quatityDiscounts += Number(discounts[indexDisc].discount)
        }
      }
      setQuantityDiscounts(quatityDiscounts)
      if (state.selectedCourse.price > quatityDiscounts)
        setPage(2)
      else
        setPage(3)
    }
  }


  const enrollDiscount = async () => {
    setIsLoading(true)
    const resultActions = await actions.enrollToGroup(choosedGroup._id)
    if (resultActions.success) {
      showMessageSuccess("Registrado correctamente", 5)
      setSuccessfulPayment(resultActions)
      setPage(4)
    } else {
      showNotification("error", "Ocurrio un error al registrarte. Intenta de nuevo con otras opciones", resultActions.error ? resultActions.error : "", 0)
    }
    setIsLoading(false)
  }


  const buttonPay = (event) => {
    event.preventDefault();
    if (state.selectedCourse.price <= quantityDiscounts) {
      enrollDiscount()
    } else {
      if (cardData.transaction_amount === "") {
        showMessageWarning("Atención! Debes ingresar un monto a pagar mayor a cero", 3)
      } else {
        loading = LoadingMessage('Validando pago....')
        setIsLoading(true)
        handlerDoPay()
      }
    }
  }

  /////Generar el token de la tarjeta///////
  const handlerDoPay = async () => {
    actions.enrollPayment({ payment_method_id: cardData.idOperation }, choosedGroup._id)
      .then((res) => {
        console.log("Alert Payment Service", res)
        handleServerResponse(res)
      })
  }

  const handleServerResponse = (response) => {
    if (response.error) {
      setSuccessfulPayment({
        success: false,
        message: response.error.message
      });
      setPage(page + 1);
      setIsLoading(false);
      loading();
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      stripe.handleCardAction(
        response.payment_intent_client_secret
      ).then(handleStripeJsResult);
    } else {
      setSuccessfulPayment(response);
      setPage(page + 1);
      setIsLoading(false);
      loading();
    }
  }

  const handleStripeJsResult = (result) => {
    console.log("RESULT HANDLER CARD ", result);
    if (result.error) {
      setSuccessfulPayment({
        success: false,
        message: result.error.message
      });
      setPage(page + 1);
      setIsLoading(false);
      loading();
    } else {
      console.log("Else js")
    }
  }

  const LoadingMessage = (message) => {
    return showMessageLoading(message, 0);
  };

  const cancel = () => {
    setShowForm(false)
    setCardData(initialState)
  }

  const onClickNextCard = (cardData) => {
    console.log("CARDDATA", cardData)
    console.log("student", state.student)
    setCardData(cardData);
    setPage(page + 1);

  }
  //Remove the crud from mercadopago Provider.
  return (
    <>
      <PageHeader
        className="site-page-header"
        onBack={() => history.goBack()}
        title={state.selectedCourse.name || ""}
        subTitle=""
      />
      <div className={styles.div_wizard}>
        <div className="wizard">
          <div className="wizard__steps">
            <div className={`wizard__step${page === 1 ? ' wizard__step--active' : ''}`}><p>Selecciona el grupo</p></div>
            <div className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}`}><p>Ingresa información de pago</p></div>
            <div className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}`}><p>Confirmar</p></div>
            <div className={`wizard__step${page === 4 ? ' wizard__step--active' : ''}`}> <p>Finalizar</p></div>
          </div>
          <div className="wizard__form-wrapper">
            <div className={styles.payments_wizard_container}>
              {showStep()}
            </div>
          </div>
        </div>
      </div>
    </>
  )
};


export default Payments;
