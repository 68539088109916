import React, { Fragment } from 'react';
import Icon from '../../components/icons';
import { MathfieldComponent } from 'react-mathlive';
import { Tooltip } from 'antd';
import 'antd/es/tooltip/style/index.css';
import 'mathlive/dist/mathlive-static.css'
import styles from './mobile.module.scss';

const QuestionMobile = ({ questionInfo, multipleAnswers = 0 }) => {

  const showMultipleAnswers = () => {
    return (
      <div className={styles.blink} >
        <Tooltip
          className={styles.tooltip_multiple_answers}
          title={`La pregunta tiene ${multipleAnswers} respuestas`}
        >
          <Icon
            name="error"
          />
        </Tooltip>
      </div>
    )
  }

  const generateTextLine = (text) => {
    const lineText = text.split(/\r?\n/);
    if (lineText.length > 0) {
      return lineText.map(line => (
        <p>{line}</p>
      ))
    } else {
      return text;
    }
  }
  const showTypesQuestions = () => {
    console.log("HOLA IF")
    if (questionInfo.hasOwnProperty("questionType")) {
      console.log("HOLA IF1")
      if (questionInfo.questionType === 2) {
        console.log("HOLA IF12")
        return (
          <div>
            <div className={styles.question_text}>
              {generateTextLine(questionInfo.question)}
              <span>{multipleAnswers > 1 ? showMultipleAnswers() : ""}</span>
            </div>
            <div className={styles.img_question_box} >
              <img className={styles.img_quiz_questions} src={questionInfo.questionImage.url} alt="img" />
            </div>
          </div>
        )
      } else if (questionInfo.questionType === 3) {
        console.log("HOLA IF13")
        return (
          <>
            <div className={styles.question_text_formula}>
              {questionInfo.question} <span>{multipleAnswers > 1 ? showMultipleAnswers() : ""}</span>
            </div>
            <div className={styles.question_formula_scroll}>
              <div className={styles.wrapper_question_formula}>
                <MathfieldComponent
                  latex={questionInfo.questionFormula}
                  mathFieldConfig={{
                    readOnly: true
                  }} />
              </div>
            </div>
          </>
        )
      } else {
        return (
          <>
          </>
        )
      }
    } else {
      console.log("HOLA IF2")
      return (
        <div className={styles.question_text}>
          {generateTextLine(questionInfo.question)}
          <span>{multipleAnswers > 1 ? showMultipleAnswers() : ""}</span>
        </div>
      )
    }
  }

  return (
    <>
      { showTypesQuestions()}
    </>
  )
};
export default QuestionMobile;