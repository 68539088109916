import { useState } from 'react';
import { MainComment } from './components/mainComment';
import { Avatar, Button, Comment, Form } from 'antd';
import Icon from 'components/icons';
import moment from 'moment';
import styles from '../../index.module.scss';
import { FormComent } from './components/formComent';

export const Index = ({ video, actions }) => {
  const { videoComments, _id } = video;
  const [submitting, setSubmitting] = useState(false);
  const [comment, setComment] = useState('');


  //guarda un comentario
  const saveComment = () => {
    setSubmitting(true);
    actions.setVideoComment(_id, comment, moment());
    setTimeout(() => {
      setSubmitting(false);
      setComment('');
    }, 600);
  };

  return (
    <div className={styles.container_comments}>
      {(!videoComments || videoComments.length === 0) && <div>No hay comentarios disponibles.</div>}

      {
        //cometarios principales//
        videoComments.map((commentData) => (
          <MainComment
            key={commentData._id}
            commentData={commentData}
            submitting={submitting}
            setSubmitting={setSubmitting}
            actions={actions}
            _id={_id}
            setComment={setComment}
          />
        ))
      }
      <Comment
        avatar={
          localStorage['avatar'] ? (
            <Avatar src={localStorage['avatar']} alt="Image Avatar Student" />
          ) : (
            <Icon name="account" className={styles.profile_icon} />
          )
        }
        content={
          <FormComent
            stateComment={comment}
            setStateComment={setComment}
            placeholder={'Añade un cometario'}
            textButton={'Agregar comentario'}
            submitting={submitting}
            saveFuntion={saveComment}
          />
        }
      />
    </div>
  );
};

export default Index;
