import { Rate } from 'antd';
import styles from '../index.module.scss';

export const VideoFooter = ({ video, handleChange }) => {
  const { description, myRate } = video;
  return (
    <div>
      <div className={styles.description}>{description}</div>
      <div className={styles.score}>
        <p>Califica este video</p>
        <Rate
          onChange={handleChange}
          count={5}
          value={myRate ? myRate : 0}
          defaultValue={0}
          tooltips={['Muy Malo', 'Malo', 'Regular', 'Bueno', 'Excelente']}
        />
      </div>
    </div>
  );
};
