import React from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/es';
import styles from './index.module.sass';

const dateFormat = 'DD/MM/YYYY';

const QuizAttemps = ({ quizInfo, showExamResult }) => {

  return (
    <div className={styles.div_attempts}>
      <div className="text-center">
        <p className={styles.attempts_info}>
          {
            quizInfo.percentageResult >= 60 ?
              <span className={styles.score_attempt_rigth}>{"Aprobado"}</span>
              :
              <span className={styles.score_attempt_wrong}>{"Reprobado"}</span>
          }
        </p>
        <p className={styles.attempts_info}>
          <span className="ml-5">{quizInfo.percentageResult.toFixed(0)} % de preguntas correctas</span>
        </p>
        <p className={styles.attempts_info}>
          <span className="ml-5"> {moment.utc(quizInfo.date).local().format(dateFormat)}</span>
        </p>
      </div>
      <div className="float-right">
        <Button color="primary" size="sm" className="mt-2"
          onClick={() => showExamResult(quizInfo._id)}
        >
          Repasar preguntas
        </Button>
      </div>
    </div>
  )
}
export default QuizAttemps
