
const useLocalStorage = () => {
  const setValueLocalStorage = (key, dataObj) => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(key, JSON.stringify(dataObj));
    }
  };

  const getStorageValue = (key, defaultValue) => {
    if (typeof window !== 'undefined') {
      const saved = localStorage.getItem(key);
      const initial = saved !== null ? JSON.parse(saved) : defaultValue;
      return initial;
    }
  };

  return [setValueLocalStorage, getStorageValue];
};

export default useLocalStorage;
