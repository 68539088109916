import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Alert } from 'reactstrap';
import styles from './index.module.scss';

const CourseCard = ({ course, lastChapter, lastItem, id, hasArrangePayment }) => {
  return (
    <div className={styles.courses_container}>
      <div className={styles.course}>
        <div className={styles.course_preview}>
          <h6>Curso en línea</h6>
          <h2>{course.name}</h2>
          {/* <Link to={`/coursePlaylist/${course._id}`}>Ver todos los cápitulos</Link> */}
        </div>
        <div className={styles.course_info}>
          <div className={styles.progress_container}>
            <div className={styles.progress} style={{ '--v': course.totalProgress + '%' }}></div>
            <span className={styles.progress_text}>
              {Number(course.totalProgress).toFixed(3)}% Progreso
            </span>
          </div>
          <h6>{lastChapter}</h6>
          <h2>{lastItem ? lastItem.name : ''}</h2>
          {/* <Link to={`/playlist/${course.playlists._id}`}> */}
          {hasArrangePayment === false ? (
            <Link to={`/financial`}>
              <Alert className="mt-5" color="danger">
                Para poder ver el contenido del curso debes pagar por completo el curso.
              </Alert>
            </Link>
          ) : (
            <Link to={`/coursePlaylist/${course._id}`}>
              <Button className={styles.btn}>Ir a tu curso en línea </Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
export default CourseCard;
