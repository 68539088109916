import { setCookie, removeCookie } from '../utils/cookies';

const COOKIE_TIMEOUT = 10368000;

const reducers = {
  LOADING: (state, { loading }) => {
    return { ...state, loading };
  },
  ERROR: (state, { error, code }) => {
    console.error(error);
    return {
      ...state,
      error: { code, exist: true, message: error }
    };
  },
  LOGIN: (state, data) => {
    let { keepSession, token, student } = data;
    localStorage['student'] = student._id;
    setCookie('token', `Bearer ${token}`, (keepSession && Infinity) || COOKIE_TIMEOUT);

    return {
      ...state,
      error: null,
      isLoggedIn: true,
      studentId: student._id,
      student: { ...state.student, student }
    };
  },
  FACEBOOK_LOGIN: (state, response) => {
    (response.token || response.fbToken) &&
      setCookie(
        'token',
        `Bearer ${response.token || response.fbToken}`,
        response.expiresIn || COOKIE_TIMEOUT
      );

    if (response.picture.length) {
      localStorage['fbPicture'] = response.picture;
    }

    const student = {
      ...state.student,
      ...response.student,
      picture: response.picture
    };

    localStorage['student'] = student._id;
    if (student.avatar) {
      localStorage['avatar'] = student.avatar;
    }
    return { ...state, student, error: null, isLoggedIn: true };
  },
  UPDATE_PASSWORD: (state, { loginData, studentData }) => {
    const loginState = reducers['LOGIN'](state, loginData);
    return {
      ...state,
      ...loginState,
      student: { ...state.student, ...studentData.student }
    };
  },

  COURSES: (state, { courses = [] }) => {
    const course = state.course != null ? state.course : courses[0] ? (!!courses.length && courses[0]) || state.course : null
    return { ...state, error: null, courses, course };
  },

  COURSE: (state, data) => {
    let course = {};
    const courses = state.courses.map((courseState) => {
      if (courseState._id === data.course._id) {
        course = { ...courseState, ...data.course };
        state.loading = false
        return course;
      }
      state.loading = false
      return courseState;
    });
    state.loading = false
    return { ...state, courses, course };
  },
  STUDENT: (state, { profile }) => ({
    ...state,
    student: { ...state.student, ...profile }
  }),
  SCHOOL_DATA: (state, { schoolData }) => ({
    ...state,
    schoolData: { ...schoolData }
  }),
  ATTENDANCE: (state, { attendance }) => ({
    ...state,
    attendance: (Array.isArray(attendance) && attendance) || []
  }),
  LEVELS: (state, { courseLevels }) => ({
    ...state,
    levels: courseLevels
  }),
  WORKSHOPS: (state, data) => {
    const { courses = [] } = data;
    return {
      ...state,
      workshops: courses
    };
  },
  AVAILABLE_COURSES: (state, { courses }) => {
    const groupKey = {};
    courses.forEach(element => {
      if (groupKey[element.courseType._id] !== undefined) {

        groupKey[element.courseType._id].push(element)
      } else {
        groupKey[element.courseType._id] = [element]
      }
    });
    const keys = Object.keys(groupKey);
    const newArray = []
    keys.forEach(element => {
      const infoCourse = {
        ...groupKey[element][0].courseType,
        courses: groupKey[element]
      }
      newArray.push(infoCourse)
    })
    return {
      ...state,
      availableCourses: newArray
    }
  },
  AVAILABLE_GROUPS: (state, data) => {
    const { availableGroups = [], selectedCourse = {} } = data;
    return {
      ...state,
      selectedCourse,
      availableGroups
    };
  },
  VIDEO: (state, { video }) => ({
    ...state,
    video
  }),
  
  QUIZ: (state, { quiz }) => ({
    ...state,
    quiz
  }),
  QUIZATTEMPTS: (state, { quizAttempts }) => ({
    ...state,
    quizAttempts
  }),
  LASTQUIZACTIVE: (state, { quizAttempt }) => ({
    ...state,
    latsQuizActive: quizAttempt
  }),
  ANSWERED_EXAM: (state, { quizAttempt }) => ({
    ...state,
    answeredExam: quizAttempt
  }),
  ACTIVE_QUESTION: (state, { quizAttemptActualQuestion }) => ({
    ...state,
    quizAttemptActualQuestion: quizAttemptActualQuestion
  }),
  PLAYLIST: (state, { playlist = null }) => ({
    ...state,
    playlist
  }),

  UPDATE_WATCH: (state, { videoWatched }) => {
    let playlist = { ...state.playlist }
    let video = { ...state.video }
    let index = playlist.contentPlaylist.findIndex(value => String(value._id) === String(videoWatched._id))
    if (index >= 0) {
      playlist.contentPlaylist[index].views = videoWatched.views
      playlist.contentPlaylist[index].viewed = true
      video.views = video.views + 1
      if (playlist.contentPlaylist[index + 1] !== undefined) {
        playlist.contentPlaylist[index + 1].lastItem = true
      }
    }
    return { ...state, playlist, video }
  },
  EDIT_PROFILE: (state, { student }) => ({
    ...state,
    student: { ...state.student, ...student }
  }),
  SIDE_BAR: (state, { data }) => {
    return {
      ...state,
      student: { ...state.student, ...data.student.profile },
      schoolData: { ...data.schoolData.schoolData }
    };
  },
  CHANGE_SIDE_BAR: (state, { value }) => {
    return {
      ...state,
      sideBarOpen: value
    }
  },
  FINANCIAL: (state, { financial }) => {
    // let group = financial.payments.reduce((r, a) => {
    //   // console.log("a", a);
    //   // console.log('r', r);
    //   r[a.course] = [...r[a.course] || [], a];
    //   return r;
    // }, {});
    return {
      ...state,
      financial
      // financial: group
    };
  },
  CLOSE_SESSION: (state) => {
    delete localStorage['student'];
    removeCookie('token');
    return {
      ...state,
      token: '',
      isLoggedIn: false
    };
  },
  PAYMENT_COURSE_INFO: (state, payload) => {
    return {
      ...state,
      paymentCourseInfo: payload.hasOwnProperty("course") ? payload : null,
    };
  },
  PAY_LESSON: (state, payload) => {
    let course = { ...state.course }
    course.hasArrangePayment = true
    return {
      ...state,
      course: course
    };
  },
}
export default reducers;
