import React, { useContext } from 'react';
import { Row, Col } from 'reactstrap';
import { context } from '../../../context';
import QuestionRes from './QuestinRes';
import Header from './Header';
// import styles from '../index.module.sass';

function QuizResults({ onClickBack }) {

  const { state } = useContext(context);

  //buscamos en cada pregunta si esta tiene mas de una respuesta
  const multipleOptions = (index) => {
    let validAnswerNumber = 0;
    state.answeredExam.questionCollection[index].answerCollection.forEach((data) => {
      if (data.validAnswer) {
        validAnswerNumber = validAnswerNumber + 1
      }
    });
    return validAnswerNumber
  }

  const getResultQuiz = () => {
    let result = 0;
    state.answeredExam.questionCollection.forEach((data, index) => {
      //sacamos el numero de respuestas correctas por pregunta
      let trueAnswersNum = multipleOptions(index);
      if (trueAnswersNum === 1) {
        for (let a = 0; a < data.answerCollection.length; a++) {
          if (data.answerCollection[a].idAnswer === data.arrayUserAnswers[0]) {
            if (data.answerCollection[a].validAnswer) {
              result = result + 1;
            }
          }
        }
      } else {
        if (trueAnswersNum === data.arrayUserAnswers.length) {
          for (let i = 0; i < data.arrayUserAnswers.length; i++) {
            let multipleTrue = true

            data.answerCollection.forEach((answer) => {
              if (answer.idAnswer === data.arrayUserAnswers[i]) {
                multipleTrue = answer.validAnswer
              }
            })
            if (multipleTrue && i === trueAnswersNum - 1) result = result + 1;
          }
        }
      }
    })
    return result;
  }

  return (
    <div>
      <Row >
        <Col xs="12" className="text-center">
          <Header name={state.quiz?.name}
            questions={state.answeredExam?.questionCollection.length}
            resultQuiz={getResultQuiz()}
            onClickBack={onClickBack}
          />
        </Col>
        <Col xs="12 mt-4">
          {
            state.answeredExam.questionCollection.map((data, index) => {
              return <QuestionRes key={index} questionNum={index} questionInfo={data} />
            })
          }
        </Col>
      </Row>
    </div>
  )
}
export default QuizResults