/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, Fragment } from 'react';
import VideoPlay from '../../pages/video-detail/videoPlay';
import Header from '../../components/header';
import { context } from '../../context';


const VideosMobile = (props) => {

  const { state, actions } = useContext(context);


  useEffect(() => {
    actions.getVideoById(props.match.params.idVideo);
  }, [window.location.pathname]);


  return (
    <Fragment>
      <Header type="playlist" route={`/playlist/${state.playlist.parent}`} />
      {state.video !== null ? <VideoPlay /> : null}
    </Fragment>
  )
}
export default VideosMobile