import React from 'react';
import { Card, CardBody } from 'reactstrap';
import moment from 'moment';
import { newSchedules } from '../../../../utils/funciones';
import styles from './result.module.scss';

const ShowResult = ({ selected }) => {
  return (
    <Card>
      <CardBody>
        <div className={styles.container_card}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="100"
            width="100"
            viewBox="0 0 48 48"
            aria-hidden="true">
            <circle fill="#5bb543" cx="24" cy="24" r="22" />
            <path
              fill="none"
              stroke="#FFF"
              stroke-width="6"
              stroke-linecap="round"
              strokeLinejoin="round"
              stroke-strokemiterlimit="10"
              d="M14 27l5.917 4.917L34 17"
            />
          </svg>

          <h3 className="pricing-card__plan">Quedaste inscrito en: {selected.name}</h3>
          <hr />
          <h4 className="mt-1">Inicia - {moment(selected.startDate).format('DD/MM/YYYY')}</h4>
          <h4 className="mt-1">
            {selected.schedules !== undefined ? newSchedules(selected.schedules) : ''}
          </h4>
          <h4>
            Sede -
            {selected.classRoom && selected.classRoom?.location
              ? selected.classRoom?.location?.name
              : 'Sin asignar'}
          </h4>
        </div>
      </CardBody>
    </Card>
  );
};

ShowResult.propTypes = {};

export default ShowResult;
