/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment'
import { Card, CardBody, Row, Col, Button, Container } from 'reactstrap';
import Spinner from '../spinner'
import ModalDataConfirm from './ModalConfirm'
import { showMessageLoading, showMessageError } from '../MessageAndNotificationUtils';
import { context } from '../../context';
import styles from './index.module.sass';

const BookingCalendar = ({ history, idGroup }) => {
    const { state, actions } = useContext(context);
    const [selectHour, setSelectHour] = useState(0)
    const [showModalConfirm, setShowModalConfirm] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingRequest, setLoadingRequest] = useState(false)
    const [successOp, setSuccessOp] = useState(false)
    const [arrayDates, setArrayDates] = useState([])
    let loadingMessage
    useEffect(() => {
        actions.getBookingCalendar(idGroup)
            .then((result) => {
                setLoading(false)
                setArrayDates(result.dateArray)
            })
    }, [])

    const booking = (date, hour, module = undefined) => {
        loadingMessage = showMessageLoading("Agendando cita")
        setLoadingRequest(true)
        actions.bookingAppoiment(idGroup, date, hour, module)
            .then((result) => {
                if (result.success) {
                    setSuccessOp(true)
                    loadingMessage()
                    setLoadingRequest(false)
                }
                else {
                    showMessageError("Ocurrio un error, probablemente ya te encuentres inscrito a este horario de asesoría.", 3)
                    loadingMessage()
                    setLoadingRequest(false)
                }
            })
    }

    const redirectTo = () => {
        const path = `/school-data`
        history.push(`${path}`);
    }

    const onClickSelect = () => {
        setShowModalConfirm(true)
    }


    return !successOp ? loading ? <Spinner /> : (
        <div className={`${styles.booking_schedule} ${styles.schedule_widget}`}>
            <div className={styles.schedule_header}>
                <Row>
                    <Col>
                        <div className={styles.day_slot}>
                            <ul>
                                {arrayDates.map(value => {
                                    let date = new Date(value.day)
                                    return (
                                        <Col>
                                            <li>
                                                <span>{moment(date).format('ddd')}</span>
                                                <span className={styles.slot_date}>{moment(date).format('DD MMM')} <small className={styles.slot_year}>{moment(date).format('YYYY')}</small></span>
                                            </li>
                                        </Col>
                                    )
                                })}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={styles.schedule_cont}>
                <Row>
                    <Col>
                        <div className={styles.time_slot}>
                            <ul>
                                {arrayDates.map((valueDates, indexValue) => {
                                    return (
                                        <Col>
                                            <li>
                                                {valueDates.hours.map((value, indexHour) => {
                                                    return (
                                                        <p className={`${styles.timing} ${(selectHour.dateIndex === indexValue && selectHour.hourIndex === indexHour) ? styles.selected : ""}`} onClick={() => setSelectHour({ dateIndex: indexValue, hourIndex: indexHour })}>
                                                            <span>{moment(value, ["HH"]).format("hh A")}</span>
                                                        </p>
                                                    )
                                                }
                                                )}
                                            </li>
                                        </Col>
                                    )
                                }
                                )}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className={styles.container_button}>
                <Button disabled={!selectHour} className={styles.save_button} onClick={onClickSelect}>
                    Seleccionar
             </Button>
            </div>
            {showModalConfirm ?
                <ModalDataConfirm
                    value={showModalConfirm}
                    close={() => setShowModalConfirm(false)}
                    data={{
                        date: arrayDates[selectHour.dateIndex].day,
                        hour: arrayDates[selectHour.dateIndex].hours[selectHour.hourIndex],
                        modules: arrayDates[selectHour.dateIndex].modules
                    }}
                    onConfirm={booking}
                    disabled={loadingRequest}
                    course={state.selectedCourse} />
                : null}
        </div>
    ) : (
            <Container>
                <Col md={12} xl={12} sm={12} className="mt-2">
                    <Card className={`${styles.pricing_card} ${styles.pricing_card_success}`} >
                        <CardBody className="pricing-card__body">
                            <div className={styles.svg_container}>
                                <svg className={styles.ft_green_tick} xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
                                    <circle className={styles.circle} fill="#5bb543" cx="24" cy="24" r="22" />
                                    <path className={styles.tick} fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" strokeLinejoin="round" stroke-strokemiterlimit="10" d="M14 27l5.917 4.917L34 17" />
                                </svg>
                            </div>

                            <h4 className="mt-3">Curso de la asesoría - <b></b></h4>
                            <h4 className="mt-1">Fecha  - <b>{moment(arrayDates[selectHour.dateIndex].day).format('DD-MM-YYYY')}</b></h4>
                            <h4 className="mt-1">Horario seleccionado  - <b>{moment(arrayDates[selectHour.dateIndex].hours[selectHour.hourIndex], ["HH"]).format("hh A")}</b></h4>

                        </CardBody>
                    </Card>
                    <div className={styles.container_button}>
                        <Button className={styles.save_button} onClick={redirectTo}>
                            Regresar a mis cursos
                        </Button>
                    </div>
                </Col>
            </Container>
        )
}
export default BookingCalendar