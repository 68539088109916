/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import InputForm from '../../components/input-form';
import ShortInput from '../../components/input-form/shortInput';
import Spinner from '../../components/spinner';
import { context } from '../../context';
import { DatePicker, Popconfirm } from 'antd';
import 'antd/es/date-picker/style/index.css'
import locale from 'antd/es/date-picker/locale/es_ES';
import styles from './index.module.sass';
import { showMessageLoading, showMessageWarning, showNotification } from '../../components/MessageAndNotificationUtils';
import 'antd/lib/popover/style/index.css'
import '../../components/MessageAndNotificationUtils/styles/motion/motions.scss'
const dateFormat = 'DD/MM/YYYY';
let loading

const EditProfile = (props) => {
  const { actions } = useContext(context);
  const [editState, setEditState] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  // const history = useHistory()

  useEffect(() => {
    actions.getStudent(props.match.params.studentId)
      .then(({ success, profile }) => {
        if (!!success) {
          setEditState(profile)
          setIsLoading(false)
        }
      }
      )


  }, []);

  const changeField = (field, value) => {
    setEditState({ ...editState, [field]: value });
  };

  const handleEditProfile = (e) => {
    const { name, lastName, secondLastName, email, phoneNumber, postalCode, address } = editState
    e.preventDefault();
    e.stopPropagation();
    // TODO: validate form fields
    if (name === "" || lastName === "" || secondLastName === "") {
      showMessageWarning("Debes ingresar tu nombre y apellidos", 3)
    } else if (email === "" || phoneNumber === "") {
      showMessageWarning("Debes ingresar tu correo y numero de télefono", 3)
    } else if (postalCode === "" || postalCode === undefined
      || address === undefined || address === "") {
      showMessageWarning("Debes ingresar tu dirección", 3)
    } else {
      loading = showMessageLoading("Guardando cambios", 0)
      actions.editProfile(editState).then(({ success, data }) => {
        if (success) {
          loading()
          showNotification("success", "Exitoso ", "Cambios guardados correctamente", "2")
        } else {
          loading()
        }
      })
    }
  };


  const onChange = (date, dateString) => {
    setEditState({ ...editState, "birthDate": date })
  }

  return (
    <>
      <div className={styles.div_edit_title}>
        <p className={styles.title_edit}>Mi Perfíl</p>
        <p className={styles.required_fields}>
          <span>*</span> Campos obligatorios
        </p>
      </div>
      {isLoading ? <Spinner /> :
        <div className={styles.form_container}>
          <InputForm
            className="form-control"
            type="text"
            label={<span>Nombre<span className={styles.fiels_simbol_required}>*</span></span>}
            value={editState.name || ''}
            onChange={(e) => changeField('name', e.target.value)}
          />
          <InputForm
            className="form-control"
            type="text"
            label={<span>Apellido paterno<span className={styles.fiels_simbol_required}>*</span></span>}
            value={editState.lastName || ''}
            onChange={(e) => changeField('lastName', e.target.value)}
          />
          <InputForm
            className="form-control"
            type="text"
            label={<span>Apellido materno<span className={styles.fiels_simbol_required}>*</span></span>}
            value={editState.secondLastName || ''}
            onChange={(e) => changeField('secondLastName', e.target.value)}
          />
          <p className={styles.label_birthDate}>Fecha de nacimiento</p>
          <DatePicker
            className="form-control"
            defaultValue={moment(new Date(), dateFormat)}
            value={moment(editState.birthDate)}
            format={dateFormat}
            locale={locale}
            allowClear={false}
            onChange={onChange}
          />
          <InputForm
            disabled= {true}
            className="form-control"
            type="text"
            label={<span>Correo electrónico<span className={styles.fiels_simbol_required}>*</span></span>}
            value={editState.email || ''}
            onChange={(e) => changeField('email', e.target.value)}
          />
          <InputForm
            className="form-control"
            type="number"
            label={<span>Teléfono<span className={styles.fiels_simbol_required}>*</span></span>}
            value={editState.phoneNumber || ''}
            onChange={(e) => changeField('phoneNumber', e.target.value)}
          />
          <InputForm
            className="form-control"
            type="text"
            label={<span>Dirección<span className={styles.fiels_simbol_required}>*</span></span>}
            value={editState.address || ''}
            onChange={(e) => changeField('address', e.target.value)}
          />
          <ShortInput
            className="form-control"
            type="text"
            label={<span>Código postal<span className={styles.fiels_simbol_required}>*</span></span>}
            value={editState.postalCode || ''}
            onChange={(e) => changeField('postalCode', e.target.value)}
          />
          <div className={styles.row}>
            <Popconfirm title="¿Estás seguro de guardar los cambios?"
              okText="Si"
              cancelText="No"
              placement="topLeft"
              onConfirm={handleEditProfile}>
              <button className={styles.save_button}>
                Actualizar datos
            </button>
            </Popconfirm>
          </div>
        </div>
      }
    </>
  );
};

export default EditProfile;
