import React from 'react';
import StepsFreeExamns from '../../components/stepsFreeExamns';
import { Divider } from 'antd';

import styles from './index.module.scss';

const Index = () => {
  return (
    <div className="container">
      <div className={`row ${styles.main_conatiner_free_exams}`}>
        <div className="col-12 text-center mt-2">
          <Divider>
            <h3>Examen de prueba</h3>
          </Divider>
        </div>
        <div className="col-12">
          <StepsFreeExamns />
        </div>
      </div>
    </div>
  );
};

export default Index;
