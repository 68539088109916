import { Badge } from 'reactstrap';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import styles from '../../../index.module.scss';

export const ActionsComent = (
  collapseFile,
  collapseFileSub,
  commentData,
  deleteComemet,
  onEditComment,
  itCanChangen,
  video = commentData.video,
) => {
  return [
    <span id={commentData._id} key="comment-nested-reply-to" onClick={collapseFile}>
      Responder
    </span>,
    <div>
      <span id={commentData._id} onClick={collapseFileSub} className={styles.ver_respuestas}>
        Ver respuestas
      </span>{' '}
      <Badge id={commentData._id} onClick={collapseFileSub} color="primary">
        {commentData.responses.length}
      </Badge>
    </div>,
    <div
      className={styles.delete_and_edit}
      style={{ color: 'green' }}
      onClick={() => onEditComment()}>
      {itCanChangen ? (
        <>
          <span>Editar</span>
          <EditOutlined />
        </>
      ) : (
        <></>
      )}
    </div>,
    <div
      className={styles.delete_and_edit}
      style={{ color: 'red' }}
      onClick={() => deleteComemet(video, commentData._id)}>
      {itCanChangen ? (
        <>
          <span>Borrar</span>
          <DeleteOutlined />
        </>
      ) : (
        <></>
      )}
    </div>
  ];
};
