/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PageHeader } from 'antd';
// import Icon from '../../components/icons';
import SelectorCard from './components/SelectorCard';
import { context } from '../../context';
import styles from './index.module.sass';
import CourseRow from './components/CourseRow';

const { TabPane } = Tabs;

const RegisterCourseLevel = (props) => {
  const { state, actions } = useContext(context);
  const history = useHistory();
  const { idLevel } = useParams();
  const [selectedLevel, setSelectedLevel] = useState(idLevel);
  useEffect(() => {
    actions.getLevels();
    if (selectLevel !== undefined) {
      actions.getCoursesByLevelId(selectedLevel);
    }
  }, []);

  const selectLevel = (level) => {
    history.push(`/register/${level}`);
    setSelectedLevel(level);
    actions.getCoursesByLevelId(level);
  };

  return (
    <>
      <section className={styles.container}>
        <PageHeader
          className="site-page-header"
          onBack={() => {
            selectedLevel ? setSelectedLevel(undefined) : props.history.goBack();
          }}
          title={selectedLevel ? 'Listado de cursos' : 'Registrar nuevo curso'}
          subTitle={selectedLevel ? '' : 'Selecciona el nivel y el curso'}
        />
        {!selectedLevel ? (
          <SelectorCard
            selected={selectedLevel}
            levels={state.levels}
            setLevel={(value) => {
              selectLevel(value);
            }}
          />
        ) : undefined}
        {(!!state.availableCourses.length && !!selectedLevel && (
          <div className={styles.course_detail}>
            <div className={styles.title}>Listado de cursos:</div>
            <Tabs tabPosition={'left'}>
              {state.availableCourses.map((courseElement, index) => (
                <TabPane
                  tab={courseElement.name == 'Taller' ? 'Laboratorio' : courseElement.name}
                  key={index}>
                  {courseElement.courses.map((element) => (
                    <CourseRow key={element} element={element}/>
                  ))}
                </TabPane>
              ))}
            </Tabs>
          </div>
        )) ||
          null}
      </section>
    </>
  );
};

export default RegisterCourseLevel;
