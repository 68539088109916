import React from 'react';
import { BrowserRouter} from 'react-router-dom';
import Context from '../../context';
import { hot } from 'react-hot-loader';
import '../../scss/app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Router from './Router';
const App = () => {
  return (
    <Context>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
    </Context>

  )
}

export default hot(module)(App)
